// @flow

const SET_NOTIFICATION = 'SET_NOTIFICATION';

type Props = {
    message?: any,
    isError?: boolean,
    duration?: number,
    dispatchClass?:string,
    hasButton?: boolean,
    buttonAction?: Function,
    buttonLabel?: string,
    buttonVariant?: string,
    Avatar?: any,
    isWelcome?: boolean,
    userId?:string,
    userName?:string,
    photoUrl?:string
}

export function setFlashNotification({ message = '',userName,userId, isError = false, duration = 4000, dispatchClass = '', hasButton, buttonAction, buttonLabel, buttonVariant = 'primary', Avatar, isWelcome,photoUrl='' }: Props): any {
    return {
        type: SET_NOTIFICATION,
        message,
        isError,
        duration,
        dispatchClass,
        hasButton,
        buttonAction,
        buttonLabel,
        buttonVariant,
        Avatar,
        isWelcome,
        userId,
        userName,
        photoUrl
    };
}

const initialState = {
    message: '',
    isError: false,
    duration: 4000,
    dispatchClass: '',
    hasButton: false,
    buttonAction: () => {},
    buttonLabel: '',
    buttonVariant: 'primary',
    Avatar: undefined,
    isWelcome: false,
    userId:'',
    userName:'',
    photoUrl:''
};

export default function flashNotification(state: Object = initialState, action: Object): any {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                ...action
            };
        default:
            return state;
    }
}
