/* eslint-disable valid-jsdoc */
// @flow

/**
 * Capitalize a string
 * @param {String} str
 * @returns {String}
 */

import languages from "./languages.json";
import countryCodes from "./countryCodes.json";
import states from "./states.json";
import Storage from "./Storage";
import moment from "moment";
import meetingcodes from "./meetingcode.json";
import meetingError from "./meetingError.json";
import { get } from "lodash";
import {
    getAuth0Token,
    fetchUserInfoV1,
    fetchUserAddressByIdV1,
} from "../api/user";
import { storageKey } from "../config/constants";
import store from "./../redux/store";
import { updateUserToken } from "../redux/modules/authentication";
import { useDispatch } from "react-redux";
import { setFlashNotification } from "../redux/modules/flashNotification";
// svg image
import doingGreatIcon from "./../assets/icons/icon-doingGreat.svg";
import icon from "./../assets/icons/icon-information.svg";
import iconDark from "./../assets/icons/icon-information-dark.svg";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Logger } from "src/services";

// end of svg image
export const capitalize = (str: string): string => {
    return str.replace(
        /\w\S*/g,
        (txt) => txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase()
    );
};

export const camelToReadableText = (text: string): string =>
    capitalize(text.replace(/([A-Z])/g, " $1"));

export const isValidEmail = (str: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        str
    );

export const isValidPhoneNumber = (
    phoneNumber: string,
    length: number
): Boolean => {
    const isInValid: Boolean =
        phoneNumber?.length < length + 4 ||
        phoneNumber === "" ||
        phoneNumber?.length > length + 4;

    return !isInValid;
};

export const createOption = (data: Object): Array<{ value: string }> =>
    data.map((value) => {
        return {
            value: typeof value === "string" ? value.trim() : value,
            label: typeof value === "string" ? value.trim() : value,
        };
    });

export const getOptionValue = (
    data: Array<Object>
): Array<{ value: string }> => {
    return data.map((v) => {
        return v.value;
    });
};

export const languageOption = (): Array<{ value: string, label: string }> => {
    const options = [];

    Object.values(languages).forEach(({ name }) => {
        options.push({
            value: name,
            label: name,
        });
    });

    return options;
};

export const yearOption = (): Array => {
    const options = [];

    for (let i = 1990; i <= new Date().getFullYear(); i++) {
        options.push(i);
    }

    return createOption(options);
};

export const createOptionCountryCode = (data = countryCodes): Array => {
    return createOption(data.map((value) => value.dial_code));
};

export const paginationEllipsis = (c: number, m: number): Array => {
    const current = c;
    const last = m;
    const delta = 5;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
    }

    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push("...");
            }
        }

        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};

export const extractPhoneNumber = (phoneNumber: string) => {
    const countryCode = countryCodes.filter((v) =>
        phoneNumber.includes(v.dial_code)
    )[0];

    if (countryCode) {
        return {
            countryCode: countryCode.dial_code,
            phoneNumber: phoneNumber.replace(countryCode.dial_code, ""),
        };
    }

    return {
        countryCode: countryCodes[0].dial_code,
        phoneNumber,
    };
};

export const stateOption = () => {
    return createOption(states.map((s) => s.name));
};

export const getValidState = (str: string) => {
    const state = states.filter(
        (e) => e?.name?.toLowerCase() === str?.toLowerCase() || ""
    )[0];

    if (state) {
        return state.name;
    }

    return states[0].name;
};

export const getBooleanYesOrNo = (str: string) => {
    if (!str.trim()) return null;

    return str === "Yes";
};

export const smoothScroll = () => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener("click", function(e) {
            e.preventDefault();

            try {
                window.history.replaceState(null, null, " ");
                document
                    .querySelector(this.getAttribute("href"))
                    .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
            } catch (error) {}
        });
    });
};

export const upperCaseFirstLetter = (str: string) =>
    `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`;

export const toType = (obj: Object) => {
    return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        ?.toLowerCase();
};

export const getArrayDiff = (arr1: array, arr2: array) => {
    const a = [];
    const diff = [];

    for (let i = 0; i < arr1.length; i++) {
        a[arr1[i]] = true;
    }

    for (let i = 0; i < arr2.length; i++) {
        if (a[arr2[i]]) {
            delete a[arr2[i]];
        } else {
            a[arr2[i]] = true;
        }
    }

    for (const k in a) {
        diff.push(k);
    }

    return diff;
};

export const serviceProvided = () => {
    return createOption([
        "Family Therapy",
        "Couples Therapy",
        "Group Therapy",
        "Individual Therapy",
    ]);
};

// export const handleInactive = async (isAuthed:boolean, userPing: Function) => {
//     if (isAuthed) {
//         try {
//             await userPing();
//         } catch (error) {
//             if (!/meeting-room|waiting-room/.test(window.location.pathname)) {
//                 Storage.clear();
//                 window.location.reload();
//             }
//         }
//     }
// };

const interpolateColor = (
    color1: Array<number>,
    color2: Array<number>,
    factor: number
): string => {
    const result = color1.slice();

    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }

    return result.join(",");
};

export const interpolateColors = (
    color1: string,
    color2: string,
    steps: number,
    percent: number
) => {
    const stepFactor = 1 / (steps - 1);
    const interpolatedColorArray = [];

    for (let i = 0; i < steps; i++) {
        interpolatedColorArray.push(
            interpolateColor(
                color1.match(/\d+/g).map(Number),
                color2.match(/\d+/g).map(Number),
                stepFactor * i
            )
        );
    }

    return interpolatedColorArray[percent];
};

export const pinkGradient = (num: number, deg: number, isLine: boolean) => {
    const colors = {
        light: [
            num < 80 ? "rgb(177, 182, 255)" : "rgb(255, 211, 227)",
            "rgb(255, 211, 227)",
            "rgb(231, 231, 232)",
        ],
        blue: ["rgb(177, 182, 255)", "rgb(247 230 236)"],
        pink: ["rgb(255, 174, 203)", "rgb(255, 67, 133)", "rgb(255, 67, 133)"],
    };
    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 90 ? 90 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const pink = interpolateColors(
        colors.pink[0],
        colors.pink[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine && num > 0 ? `-${num / 2}` : 0}%, ${`rgb(${pink})`} 100%)`;
};

export const blueGradient = (num: number, deg: number, isLine: boolean) => {
    const colors = {
        light: [
            "rgb(201, 201, 255)",
            "rgb(110, 130, 253)",
            "rgb(231, 231, 232)",
        ],
        blue: ["rgb(177, 182, 255)", "rgb(0, 35, 255)", "rgb(0, 35, 255)"],
        pink: [
            num < 80 ? "rgb(255, 174, 203)" : "rgb(201, 201, 255)",
            "rgb(201, 201, 255)",
        ],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.pink[0],
        colors.pink[1],
        num < 80 ? 80 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const blue = interpolateColors(
        colors.blue[0],
        colors.blue[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : 0}%, ${`rgb(${blue})`} 100%)`;
};

export const redGradient = (num: number, deg: number, isLine: boolean) => {
    const colors = {
        light: [
            num < 80 ? "rgb(38, 236, 176)" : "rgb(255, 176, 176)",
            "rgb(255, 176, 176)",
            "rgb(231, 231, 232)",
        ],
        red: ["rgb(255, 176, 176)", "rgb(222, 89, 89)"],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 80 ? 80 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const red = interpolateColors(
        colors.red[0],
        colors.red[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : 0}%, ${`rgb(${red})`} 100%)`;
};

export const greenGradient = (num: number, deg: number, isLine: boolean) => {
    const colors = {
        light: [
            num < 80 ? "rgb(255, 176, 176)" : "rgb(139, 247, 214)",
            "rgb(139, 247, 214)",
            "rgb(231, 231, 232)",
        ],
        red: ["rgb(249, 141, 141)", "rgb(247 230 236)"],
        green: ["rgb(38, 236, 176)", "rgb(15, 204, 147)", "rgb(15, 204, 147)"],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 90 ? 90 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const green = interpolateColors(
        colors.green[0],
        colors.green[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine && num > 0 ? `-${num / 2}` : 0}%, ${`rgb(${green})`} 100%)`;
};

export const lightGreyGradient = (
    num: number,
    deg: number,
    isLine: boolean
) => {
    const colors = {
        light: [
            num < 80 ? "rgb(225, 225, 226)" : "rgb(255, 255, 255)",
            "rgb(255, 255, 255)",
            "rgb(231, 231, 232)",
        ],
        lightGrey: ["rgb(253, 253, 253)", "rgb(199, 199, 199)"],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 90 ? 90 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const lightGrey = interpolateColors(
        colors.lightGrey[0],
        colors.lightGrey[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine && num > 0 ? `-${num / 2}` : 0}%, ${`rgb(${lightGrey})`} 100%)`;
};

export const greyGradient = (num: number, deg: number, isLine: boolean) => {
    const colors = {
        light: [
            "rgb(253, 253, 253)",
            "rgb(234, 234, 234)",
            "rgb(231, 231, 232)",
        ],
        grey: [
            num < 80 ? "rgb(225, 225, 226)" : "rgb(199, 199, 199)",
            "rgb(98, 98, 98)",
        ],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 80 ? 80 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const grey = interpolateColors(
        colors.grey[0],
        colors.grey[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : 0}%, ${`rgb(${grey})`} 100%)`;
};

export const greenGreyGradient = (
    num: number,
    deg: number,
    isLine: boolean
) => {
    const colors = {
        light: [
            "rgb(254, 254, 254)",
            "rgb(70, 234, 184)",
            "rgb(231, 231, 232)",
        ],
        green: [
            num < 80 ? "rgb(15, 204, 147)" : "rgb(0, 176, 123)",
            "rgb(0, 176, 123)",
        ],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 80 ? 80 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const green = interpolateColors(
        colors.green[0],
        colors.green[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : 0}%, ${`rgb(${green})`} 100%)`;
};

export const greyGreenGradient = (
    num: number,
    deg: number,
    isLine: boolean
) => {
    const colors = {
        light: [
            "rgb(15, 204, 147)",
            "rgb(249, 249, 249)",
            "rgb(179, 231, 215)",
        ],
        grey: [
            num < 80 ? "rgb(254, 254, 254)" : "rgb(235, 235, 235)",
            "rgb(235, 235, 235)",
        ],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const grey = interpolateColors(
        colors.grey[0],
        colors.grey[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : "-80"}%, ${`rgb(${grey})`} 100%)`;
};

export const greenOrangeGradient = (
    num: number,
    deg: number,
    isLine: boolean
) => {
    const colors = {
        light: [
            "rgb(252, 238, 161)",
            "rgb(131, 242, 209)",
            "rgb(231, 231, 232)",
        ],
        green: [
            num < 80 ? "rgb(252, 238, 161)" : "rgb(8, 207, 146)",
            "rgb(8, 207, 146)",
        ],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        num < 80 ? 80 : maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const green = interpolateColors(
        colors.green[0],
        colors.green[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : 0}%, ${`rgb(${green})`} 100%)`;
};

export const orangeGreenGradient = (
    num: number,
    deg: number,
    isLine: boolean
) => {
    const colors = {
        light: [
            "rgb(252, 239, 161)",
            "rgb(255, 127, 127)",
            "rgb(231, 231, 232)",
        ],
        grey: ["rgb(252, 239, 161)", "rgb(230, 64, 64)"],
    };

    const maxPercent = 100;

    const light = interpolateColors(
        colors.light[0],
        colors.light[1],
        maxPercent,
        Math.floor(isLine ? 0 : num)
    );
    const grey = interpolateColors(
        colors.grey[0],
        colors.grey[1],
        maxPercent,
        Math.floor(num)
    );

    return `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        isLine ? colors.light[2] : `rgb(${light})`
    } ${!isLine ? `-${num / 2}` : 0}%, ${`rgb(${grey})`} 100%)`;
};

export const getSliderGradient = (
    type: string,
    num: number,
    deg: number,
    inLine: boolean
) => {
    switch (type?.toLowerCase()) {
        case "red":
            return redGradient(num, deg, inLine);
        case "green":
            return greenGradient(num, deg, inLine);
        case "lightgrey":
            return lightGreyGradient(num, deg, inLine);
        case "grey":
            return greyGradient(num, deg, inLine);
        case "pink":
            return pinkGradient(num, deg, inLine);
        case "blue":
            return blueGradient(num, deg, inLine);
        case "green-grey":
            return greenGreyGradient(num, deg, inLine);
        case "grey-green":
            return greyGreenGradient(num, deg, inLine);
        case "green-orange":
            return greenOrangeGradient(num, deg, inLine);
        case "orange-green":
            return orangeGreenGradient(num, deg, inLine);
        default:
            break;
    }
};

export const getCssValuePrefix = () => {
    let rtrnVal = "";
    const prefixes = ["-o-", "-ms-", "-moz-", "-webkit-"];

    let dom = document.createElement("div");

    for (let i = 0; i < prefixes.length; i++) {
        dom.style.background =
            prefixes[i] + "linear-gradient(#000000, #ffffff)";

        if (dom.style.background) {
            rtrnVal = prefixes[i];
        }
    }

    dom = null;

    return rtrnVal;
};

export const getFullNameInitials = (name: string): string => {
    if (!name) return "";

    const arrName = name?.trim().split(" ");

    if (name.trim().length === 0) return "";

    if (arrName.length === 1) return name[0];

    return `${arrName[0][0] || ""}${arrName[1][0] || ""}`.toUpperCase();
};

const alphabet: array<string> = "abcdefghijklmnopqrstuvwxyz".split("");

export const randomLinear = (deg: number, name: string): string => {
    const initialsIndex =
        alphabet?.indexOf(name?.toLowerCase()?.charAt(0)) || -1;

    const colors = [
        ["#50D1C7", "#82FECE"],
        ["#54EAED", "#2FC5CA"],
        ["#7EE8FA", "#94A0EE"],
        ["#9EADFF", "#6C7BEA"],
        ["#E99EF9", "#AE53E4"],
    ];

    const i =
        initialsIndex === -1
            ? Math.floor(Math.random() * colors.length)
            : (initialsIndex + name.length) % 4;

    const linear = `${getCssValuePrefix()}linear-gradient(${deg}deg, ${
        colors[i][0]
    } 0%, ${colors[i][1]} 100%)`;

    return linear;
};

export const chunkArray = (
    array: Array<Object>,
    size: number
): Array<Array<Object>> => {
    return [].concat.apply(
        [],
        array.map(function(elem, i) {
            return i % size ? [] : [array.slice(i, i + size)];
        })
    );
};

export const getArrayDepth = (arr) => {
    return Array.isArray(arr)
        ? 1 + Math.max(...arr.map(getArrayDepth))
        : typeof arr === "object"
        ? Math.max(...Object.values(arr).map(getArrayDepth))
        : 0;
};

export const onTimer = (timestamp) => {
    const currentTime = new Date().getTime();
    const diffTime = timestamp - currentTime;
    const duration = moment.duration(diffTime, "milliseconds");

    return moment.duration(duration - 1000, "milliseconds");
};

export const isValidPassword = (pass: string): boolean => {
    // eslint-disable-next-line
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\\[\\\](){}?\\\\|,.<>;:!~*_@#$%^&+=\-`"\/]).{8,}$/.test(
        pass
    );
};

export const isValidPasswordLength = (pass: string): boolean => {
    return pass.length <= 100;
};

export const onNumbersOnly = (evt: Object) => {
    ![
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        "Backspace",
        "Tab",
    ].includes(evt.key) && evt.preventDefault();
};

export const onGenderPronouns = (gender: string) => {
    const pronouns = {
        NONE_SELECTED: " ",
        HER: "(she/her)",
        HIM: "(he/him)",
        THEM: "(they/them)",
        HIR: "(ze/hir)",
        ZIR: "(ze/zir)",
        HE_THEY: "(he/they)",
        SHE_THEY: "(she/they)",
    };

    return pronouns[gender];
};

export const utcToLocal = (date: Object) => {
    return moment(moment.utc(date).toDate()).local();
};

export const getFormattedDateUSFormat = (date: Object): string => {
    const formatter = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
    });
    const formattedDate = formatter.format(date);

    return formattedDate || "";
};

export const getSessionMatched = (types: Array) => {
    let message = "";

    if (types.length > 2) {
        message = `YOUR ${types.slice(0, -1).join(", ")} AND ${
            types[types.length - 1]
        } THERAPIST`;
    } else if (types.length === 2) {
        message = `YOUR ${types[0]} AND ${types[1]} THERAPIST`;
    } else {
        message = `YOUR ${types[0]} THERAPIST`;
    }

    return message;
};

export const getAndroidVersion = (ua) => {
    ua = (ua || navigator.userAgent).toLowerCase();
    // eslint-disable-next-line
    const match = ua.match(/android\s([0-9\.]*)/i);

    return match ? parseFloat(match[1]) : undefined;
};

export const getMinutes = (start: Date, end: Date) => {
    return moment.duration(moment(start).diff(moment(end))).asMinutes();
};

export const getMeetingCodeDescription = (code: string) => {
    return meetingcodes[code];
};

export const getMeetingTime = (datetime) => {
    return moment.utc(datetime).format("hh:mm A");
};

export const getMeetingStatus = (name: string, attributes: Object) => {
    switch (name) {
        case "audioInputFailed":
            return `Failed to choose microphone: ${attributes.audioInputErrorMessage}`;
        case "videoInputFailed":
            return `Failed to choose camera: ${
                attributes.videoInputErrorMessage.includes("NotReadableError")
                    ? meetingError.NotReadableError
                    : attributes.videoInputErrorMessage.includes("TypeError")
                    ? meetingError.TypeError
                    : meetingError.NotAllowedError
            }`;
        case "meetingStartFailed":
            return `Failed to start a meeting: ${attributes.meetingErrorMessage}`;
        case "meetingFailed":
            return `Failed during a meeting: ${attributes.meetingErrorMessage}`;
        case "signalingDropped":
            return "Meeting closed."; // The WebSocket failed or closed with an error.';
        default:
            break;
    }
};

export const onGetStorage = (key: string, defaultValue: any) => {
    try {
        return JSON.parse(Storage.getItem(key)) !== null &&
            JSON.parse(Storage.getItem(key)) !== "null"
            ? JSON.parse(Storage.getItem(key))
            : defaultValue;
    } catch (error) {
        return defaultValue;
    }
};

export const getButtonVariant = (type: string) => {
    switch (type) {
        case "INDIVIDUAL":
            return "primary";
        case "GROUP":
            return "purple";
        case "FAMILY":
            return "info";
        case "COUPLES":
            return "teal";
        default:
            return "primary";
    }
};

export const getPhotoUrl = (photoUrl: string) => {
    return photoUrl?.split("=")[1]?.replace(", name", "") || "";
};

export const blobToBase64 = (blob) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

export const filterDevices = (devices: Array<Object>) => {
    try {
        const defaultDevice = devices?.filter(
            (d) => d.deviceId === "default" || d.label.includes("Default")
        );

        if (defaultDevice.length > 0) {
            const filtered = devices?.filter(
                (d) =>
                    !d.label.includes(
                        defaultDevice[0]?.label.replace("Default - ", "")
                    )
            );

            return filtered.length > 0 ? filtered : defaultDevice;
        } else {
            return devices;
        }
    } catch (error) {
        return devices;
    }
};

export const onGetCurrentPageHash = () => {
    return (
        parseInt(window.location.href?.split("#")[1]?.replace("page", "")) || 0
    );
};

export const isValidLastPage = (lastPage: number) => {
    if (lastPage === null || !onGetCurrentPageHash()) return false;

    return (
        onGetCurrentPageHash() - lastPage > 1 ||
        lastPage - onGetCurrentPageHash() > 1
    );
};

// refactor update
export const sequenceQuestionnaireArray = (data: Array) => {
    return data.sort((a, b) => (b.sequence < a.sequence ? 1 : -1));
};

export const sequenceQuestions = (questions: Array) => {
    questions = sequenceQuestionnaireArray(questions);
    questions = questions.map((question) => {
        question.hasChanged = false;
        question.metadata = stringToObject(question.metadata);
        question.choices = sequenceQuestionnaireArray(question.choices);
        question.choices = question.choices.map((choice) => {
            if (choice.metadata !== null && choice.metadata !== undefined) {
                choice.metadata = stringToObject(choice.metadata);
            }

            return choice;
        });

        return question;
    });

    return questions;
};

// this is to mark a category or page complete or last
// this is a very dengerous piece of code, and this logic should be handled in BE
export const formatMetadata = (metadata: object) => {
    metadata.questionnaire_group = metadata?.questionnaire_group && {
        ...metadata.questionnaire_group,
        ...{
            // category level formatting
            categories: metadata.questionnaire_group.categories.map((c, i) => {
                c.last =
                    i === metadata.questionnaire_group.categories.length - 1;
                c.pages = c.pages.map((p, j) => {
                    p.last = j === c.pages.length - 1;

                    return p;
                });
                // page level formatting
                const incompletePages = c.pages.filter(
                    (page) => !page.completed
                );

                if (incompletePages.length > 0) {
                    c.completed = false;
                } else {
                    c.completed = true;
                }

                return c;
            }),
        },
    };

    return metadata;
};

// sequence whole meta data - when fetching from API Call
export const sequenceQuestionnaireMetaData = (
    metadata: Object,
) => {
    const PageRoute = metadata?.questionnaire_group?.ui_origin_path;

    try {
        if (metadata.questionnaire_group) {
            metadata.questionnaire_group.categories =
                sequenceQuestionnaireArray(
                    metadata.questionnaire_group.categories
                );
            metadata.questionnaire_group.categories =
                metadata.questionnaire_group.categories.map((category) => {
                    category.pages = sequenceQuestionnaireArray(category.pages);

                    return category;
                });
        }
    } catch (e) {
        metadata = {};
        Logger.error(
            "helper.js -> sequenceQuestionnaireMetaData Fn",
            e,
            "metadata->",
            metadata
        );
    }

    // return loggedInUser.opt_out ? formatMetadata(metadata) : metadata; // if opted out then only format metadata
    return PageRoute ? metadata : formatMetadata(metadata);
};

export const setQuestionnaireData = (questionnaireObject: any) => {
    const questionnaireData = deepClone(questionnaireObject);
    const preparedQuestions =
        questionnaireData?.questionnaire_group?.categories?.[0]?.pages?.[0]
            ?.questions;

    const updatedState = {
        initialMetadata: questionnaireData,
        currentConfig: {
            loading: false,
            completed: false,
            isContinueButtonDisabled: true,
            header: {
                visible: true,
            },
            questionnaireSessionId:
                questionnaireData?.questionnaire_completion_session_id,
            currentCategory:
                questionnaireData.questionnaire_group?.categories?.[0],
            currentPage:
                questionnaireData.questionnaire_group?.categories?.[0]
                    ?.pages?.[0],
            currentQuestions: sequenceQuestions(preparedQuestions || []),
        },
    };

    return updatedState;
};

// current questionnaire configuration with or without category ID
export const updateQuestionnaireConfiguration = (
    questionnaireObject: Object,
    categoryId: string = null,
    direction: string = "forward",
    pageUniqueKey = null // with this you can define which page to land
) => {
    const questionnaireData = deepClone(questionnaireObject);
    let currentCategory = questionnaireData?.currentConfig?.currentCategory;
    let currentPage = questionnaireData?.currentConfig?.currentPage;

    if (pageUniqueKey) {
        const { page, category } = findPageAndCategoryByPageUniqueKey(
            questionnaireData,
            pageUniqueKey
        );

        currentCategory = category;
        currentPage = page;
    } else {
        const isCompleted =
            questionnaireData?.currentConfig?.isCompleted ||
            (isEmptyObject(questionnaireData.initialMetadata) &&
                window.location.pathname.includes("initquestions"));
        const PageRoute =
            questionnaireData?.initialMetadata?.questionnaire_group
                ?.ui_origin_path;

        if (isCompleted) {
            questionnaireData.currentConfig.isCompleted =
                questionnaireData.currentConfig.header.visible = true;

            return questionnaireData;
        }

        if (currentCategory && !isEmptyObject(currentCategory) && !categoryId) {
            currentCategory =
                questionnaireData?.initialMetadata?.questionnaire_group?.categories.filter(
                    (category) => category.id === currentCategory.id
                )[0] || {}; // cos it holds only one object inside the array after filter

            // check if current page is the last page
            if (direction === "forward") {
                const pageIndex = findPageIndex(currentCategory, currentPage);
                const getCategoryIndex = findCategoryIndex(
                    questionnaireData,
                    currentCategory
                );

                if (!isLastPage(currentPage)) {
                    currentPage = currentCategory?.pages[pageIndex + 1] || {};
                } else {
                    if (!isLastCategory(currentCategory)) {
                        const categoryIndex = findCategoryIndex(
                            questionnaireData,
                            currentCategory
                        );

                        questionnaireData.initialMetadata.questionnaire_group.categories[
                            categoryIndex
                        ].completed = true;

                        if (PageRoute && PageRoute === "settings") {
                            window.location.replace("/therapy/settings");
                        } else if (PageRoute && PageRoute === "dashboard") {
                            window.location.replace("/");
                        } else {
                            currentCategory =
                                questionnaireData?.initialMetadata
                                    ?.questionnaire_group?.categories[
                                    categoryIndex + 1
                                ] || {};
                            currentPage =
                                currentCategory?.pages?.length > 0
                                    ? currentCategory?.pages[0]
                                    : {}; // todo - simplify the logic
                        }
                    } else {
                        currentPage = {};
                        currentCategory = {};
                        questionnaireData.currentConfig.isCompleted = true;
                        questionnaireData.currentConfig.header.visible = true;
                        /** clear the questionnaire data state object **/
                    }
                }

                questionnaireData.initialMetadata.questionnaire_group.categories[
                    getCategoryIndex
                ].pages[pageIndex].completed = true;
            } else {
                if (!isFirstPage(currentPage, currentCategory)) {
                    const pageIndex = findPageIndex(
                        currentCategory,
                        currentPage
                    );

                    currentPage = currentCategory?.pages[pageIndex - 1] || {};
                } else {
                    if (!isFirstCategory(currentCategory, questionnaireData)) {
                        const categoryIndex = findCategoryIndex(
                            questionnaireData,
                            currentCategory
                        );

                        currentCategory =
                            questionnaireData?.initialMetadata
                                ?.questionnaire_group?.categories[
                                categoryIndex - 1
                            ] || {};
                        currentPage =
                            currentCategory?.pages[
                                currentCategory?.pages?.length - 1 || 0
                            ] || {};
                    } else {
                        currentCategory =
                            questionnaireData?.initialMetadata
                                ?.questionnaire_group?.categories[0] || {};
                        currentPage = currentCategory?.pages[0] || {};
                        questionnaireData.currentConfig.isCompleted = false;
                        questionnaireData.currentConfig.header.visible = true;
                        /** clear the questionnaire data state object **/
                    }
                }
            }
        } else {
            if (categoryId) {
                // state when a category is selected specifically
                const selectedCategory =
                    questionnaireData?.initialMetadata.questionnaire_group?.categories.filter(
                        (category) => category.id === categoryId
                    )[0] || {};
                const hasCompletePage =
                    selectedCategory?.pages?.filter((p) => p.completed === true)
                        .length > 0;

                // also check if also all the previous categories completed
                let hasCompletedPreviousCategories = true;
                const categories =
                    questionnaireData?.initialMetadata.questionnaire_group
                        ?.categories;

                for (let i = 0; i < categories.length; i++) {
                    const c = categories[i];

                    if (c.id !== selectedCategory.id && c.completed === false)
                        hasCompletedPreviousCategories = false;

                    if (
                        c.id === selectedCategory.id ||
                        hasCompletedPreviousCategories === false
                    ) {
                        break;
                    }
                }

                if (
                    (hasCompletePage || selectedCategory.completed === true) &&
                    hasCompletedPreviousCategories
                ) {
                    currentCategory = selectedCategory; // first object of categories array
                    currentPage =
                        selectedCategory.completed === false
                            ? currentCategory?.pages.filter(
                                  (p) => p.completed === false
                              )[0] || {}
                            : currentCategory?.pages[0];
                } else {
                    // if the previous category is complete user can navigate to next category first questions
                    const previousCategory = findPreviousCategory(
                        questionnaireData?.initialMetadata.questionnaire_group
                            ?.categories,
                        selectedCategory
                    );
                    const previousCategoryCompleted =
                        previousCategory.completed;

                    if (previousCategoryCompleted) {
                        currentCategory = selectedCategory;
                        currentPage = currentCategory?.pages[0] || {};
                    }
                }
            } else {
                // initial state when questionnaire starts
                currentCategory =
                    questionnaireData?.initialMetadata?.questionnaire_group?.categories.filter(
                        (c) => c.completed === false
                    )[0] || {}; // first object of categories array
                currentPage =
                    currentCategory?.pages?.filter(
                        (p) => p.completed === false
                    )[0] || {};
            }
        }
    }

    // // delete unnecessary keys from objects - todo
    const progress = getQuestionnairePagesCount(
        questionnaireData,
        currentCategory,
        currentPage
    );

    questionnaireData.currentConfig.progress = progress;
    questionnaireData.currentConfig.header =
        constructQuestionnaireCategoryHeader(
            questionnaireData,
            currentCategory
        );

    // toDo -  why this is required ?
    try {
        if (questionnaireData?.currentConfig?.currentPage.id !== currentPage.id)
            questionnaireData.currentConfig.currentQuestions = [];
    } catch (e) {
        Logger.log("current config do not have current page", e);
    }

    questionnaireData.currentConfig.currentCategory = currentCategory;
    questionnaireData.currentConfig.currentPage = currentPage;
    questionnaireData.currentConfig.questionnaireSessionId =
        questionnaireData.initialMetadata.questionnaire_completion_session_id;
    questionnaireData.currentConfig.direction = direction;

    return questionnaireData;
};

const constructQuestionnaireCategoryHeader = (
    questionnaireData: Object,
    currentCategory: Object
) => {
    let header = {};
    let content = [];

    questionnaireData?.initialMetadata?.questionnaire_group?.categories.forEach(
        (category) => {
            const head = {
                id: category.id,
                name: category.name,
                isSelected: false,
            };

            content.push(head);
        }
    );

    content = content.map((el) => {
        if (el.id === currentCategory.id) {
            el.isSelected = true;
        } else {
            el.isSelected = false;
        }

        return el;
    });

    header = {
        content:
            currentCategory && Object.keys(currentCategory).length > 0
                ? content
                : [],
        visible:
          questionnaireData?.initialMetadata?.questionnaire_group?.type ? 
            questionnaireData?.initialMetadata?.questionnaire_group?.type?.includes(
                "INTAKE"
            ) : true,
    };

    return header;
};

export const isEmptyObject = (obj: any) => {
    return !!obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const isLastCategory = (currentCategory: Object) => {
    return currentCategory.last;
};

const isLastPage = (currentPage: Object) => {
    return currentPage.last;
};

const isFirstPage = (currentPage: object, currentCategory: object) => {
    return (
        currentCategory.pages.findIndex((p) => p.id === currentPage.id) === 0
    );
};

const isFirstCategory = (
    currentCategory: object,
    questionnaireData: object
) => {
    return (
        questionnaireData.initialMetadata?.questionnaire_group.categories.findIndex(
            (c) => c.id === currentCategory.id
        ) === 0
    );
};

const findPageIndex = (currentCategory: Object, currentPage: Object) => {
    return currentCategory?.pages?.findIndex(
        (page) => page.id === currentPage.id
    );
};

const findPageAndCategoryByPageUniqueKey = (
    questionnaireData,
    pageUniqueKey
) => {
    const categories =
        questionnaireData.initialMetadata?.questionnaire_group.categories;
    let selectedCategory, selectedPage;

    categories.forEach((c) => {
        c.pages.forEach((p) => {
            if (p.unique_key === pageUniqueKey) {
                selectedPage = p;
                selectedCategory = c;
            }
        });
    });

    return {
        category: selectedCategory,
        page: selectedPage,
    };
};

const findCategoryIndex = (
    questionnaireData: Object,
    currentCategory: Object
) => {
    const categories =
        questionnaireData?.initialMetadata?.questionnaire_group?.categories;

    return categories.findIndex(
        (category) => category.id === currentCategory.id
    );
};

export const deepClone = (obj: Object) => {
    if (obj) {
        return JSON.parse(JSON.stringify(obj));
    }
};

export const getQuestionnairePagesCount = (
    questionnaireData: Object,
    currentCategory: Object,
    currentPage: Object
): any => {
    let count = 0;
    let currentPageNumber = 0;
    let found = false;

    questionnaireData?.initialMetadata?.questionnaire_group?.categories.forEach(
        (category) => {
            count += category.pages.length || 1;

            if (
                Object.keys(currentPage).length === 0 &&
                category.id === currentCategory.id
            ) {
                currentPageNumber++;
                found = true;
            } else {
                if (!found) {
                    if (category.pages.length !== 0) {
                        category.pages.forEach((page) => {
                            if (page.id === currentPage.id) {
                                found = true;
                                currentPageNumber++;
                            } else {
                                if (!found) currentPageNumber++;
                            }
                        });
                    } else {
                        currentPageNumber++;
                    }
                }
            }
        }
    );

    return {
        progress: (100 / count) * currentPageNumber,
        totalPages: count,
        currentPageNumber,
    };
};

// "metadata":"validation:{type:'text',charLength:25}"
export const stringToObject = (metadata: string): object => {
    const metadataObject = {};

    /**
     *
     * TODO : is this regarding the metadata objectifying then fine or we can use different function - @sumit bhai
     *
     * **/
    try {
        // edge case scenario for insurance card
        const updatedMetadata = metadata?.replace("https:", "https");

        if (updatedMetadata?.length > 0) {
            updatedMetadata &&
                updatedMetadata.split(";").forEach((data) => {
                    const key = data?.split(":")[0]?.trim();
                    let value = data?.split(":")[1]?.trim();

                    value =
                        value?.length > 15
                            ? value
                            : isNaN(parseInt(value))
                            ? value
                            : parseInt(value);
                    metadataObject[key] = value;

                    // edge case scenario for insurance card - not any more
                    if (key === "signed_url") {
                        metadataObject[key] = value.includes("https:")
                            ? value
                            : value.replace("https", "https:");
                    }
                });
        } else {
            metadata &&
                metadata.split(";").forEach((data) => {
                    const key = data?.split(":")[0]?.trim();
                    let value = data?.split(":")[1]?.trim();

                    value = isNaN(parseInt(value)) ? value : parseInt(value);
                    metadataObject[key] = value;
                });
        }
    } catch (e) {
        Logger.log(e);
    }

    return metadataObject;
};

export const sanitiseHTML = (html: string) => {
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

export const populateQuestionnaireImage = (questionnairePage: any) => {
    const pageEl: HTMLElement = questionnairePage.current;
    // sample : <span class='page-header'><br><br><br><br><img class='doing-great' src='doingGreat' alt='great work'/></span>
    const imageElements = pageEl.getElementsByTagName("img");

    for (const imageElement: HTMLImageElement of imageElements) {
        const className = imageElement.className;

        if (findCorrespondingImageSRC(className)) {
            imageElement.src = findCorrespondingImageSRC(className);
        }
    }
};

export const populateQuestionnaireTooltip = (questionnairePage: any) => {
    const content: HTMLElement = questionnairePage.current;
    // sample : <span class='page-sub-header'>Policy Holder Information <tooltip>This is a custom tooltip content</tooltip></span>
    const tooltipElements = content.getElementsByTagName("tooltip");

    for (const tooltipEl of tooltipElements) {
        const text = tooltipEl.innerText;
        const parentElement = document.createElement("div");

        parentElement.className = "custom-tooltip";
        const childElement = document.createElement("span");

        childElement.classList = "tooltiptext";
        childElement.innerText = text;

        const el = document.createElement("img");

        el.src = icon;
        el.addEventListener(
            "mouseenter",
            () => {
                el.src = iconDark;
            },
            false
        );
        el.addEventListener(
            "mouseleave",
            () => {
                el.src = icon;
            },
            false
        );

        parentElement.append(el);
        parentElement.append(childElement);
        const parent = tooltipEl?.parentElement;

        /**
         * a sample div like this:
         * 
         * <div class="tooltip">Hover over me
              <span class="tooltiptext">Tooltip text</span>
           </div>
         * 
         * **/

        parent?.replaceChild(parentElement, parent?.children[0]);
    }
};

const findCorrespondingImageSRC = (imageIdentity: string) => {
    switch (imageIdentity) {
        case "doing-great":
            return doingGreatIcon;

        default:
            return null;
    }
};

export const findQuestionAndUpdateValue = (
    questionsData: array,
    questionId: string,
    choiceId: string,
    value: any,
    isValid: boolean
) => {
    let questions = deepClone(questionsData);

    questions = questions.map((question) => {
        if (question.id === questionId) {
            question.hasChanged = true; // if any changes to a question - mark it true
            let choices = question.choices || [];

            if (Array.isArray(value)) {
                choices = value;
            } else {
                choices = choices.map((choice) => {
                    if (choice.id === choiceId) {
                        if (
                            typeof value === "string" ||
                            typeof value === "number" ||
                            value === null
                        ) {
                            choice.value = value;
                        } else {
                            choice = { ...choice, ...value };
                        }

                        choice.isValid = isValid; // choice level validity
                    }

                    return choice;
                });
            }

            question.choices = choices;
        }

        return question;
    });

    return questions;
};

// update choice level metadata
export const findChoiceAndUpdateMetadata = (
    questionsData: array,
    questionId: string,
    choiceId: string,
    metadata: any
) => {
    let questions = deepClone(questionsData);

    questions = questions.map((question) => {
        if (question.id === questionId) {
            let choices = question.choices || [];

            choices = choices.map((choice) => {
                if (choice.id === choiceId) {
                    choice.metadata = { ...choice.metadata, ...metadata }; // choice level validity
                }

                return choice;
            });

            question.choices = choices;
        }

        return question;
    });

    return questions;
};

/**
 * Generate Response Format.
 * @param {Array} questions The first number.
 * @returns {Array} The sum of the two numbers.
 */
export const prepareResponseData = (questions: array, updatedChoices?: any) => {
    const response = [];

    questions.forEach((question) => {
        const questionResponse = {};

        // checkbox
        if (
            question?.metadata?.question_type === "checkbox" ||
            question?.metadata?.question_type === "radio" ||
            question?.metadata?.question_type === "cardcheckbox" ||
            question?.metadata?.question_type === "cardradiobox"
        ) {
            questionResponse[question.unique_key] = question.choices
                .filter((choice) => choice.selected)
                .map((choice) => {
                    return {
                        unique_choice_key: choice.unique_key,
                        ...(choice.value && { value: choice.value }),
                    };
                });
        } else if (question?.metadata?.question_type === "nodecheckbox") {
            questionResponse[question.unique_key] = question.choices
                .filter((choice) => choice.selected)
                .map((choice) => {
                    let answerNodes: array;

                    if (
                        choice.selected &&
                        choice.nodes &&
                        choice.nodes.length !== 0
                    )
                        answerNodes = prepareNodeResponses(choice.nodes);

                    return {
                        unique_choice_key: choice.unique_key,
                        ...(choice.value && { value: choice.value }),
                        ...(answerNodes && { answer_nodes: answerNodes }),
                    };
                });
        } else if (
            question?.metadata?.question_type === "clinician_availability"
        ) {
            if (updatedChoices && question) {
                question.choices = updatedChoices;
            }

            if (question.choices?.length) {
                questionResponse[question.unique_key] = question.choices.map(
                    (choice) => {
                        const selectedValue = `requested_match_help:${choice.selected}`;
                        const concernText = `;requested_match_help_free_text:${choice.requested_match_help_free_text}`;
                        let valueToSave: string = selectedValue;

                        if (choice.selected) {
                            if (choice.requested_match_help_free_text) {
                                valueToSave = `${selectedValue}${concernText}`;
                            } else {
                                if (choice.value) {
                                    valueToSave = choice.value;
                                }
                            }
                        } else {
                            valueToSave = `requested_match_help:${false}`;
                        }

                        return {
                            unique_choice_key: choice.unique_key,
                            value: valueToSave,
                        };
                    }
                );
            }
        } else {
            questionResponse[question.unique_key] = question.choices
                .filter((choice) => choice.value || choice.value === 0)
                .map((choice) => {
                    return {
                        unique_choice_key: choice.unique_key,
                        value: choice.value,
                    };
                });
        }

        response.push(questionResponse);
    });

    return response;
};

const prepareNodeResponses = (nodes: array) => {
    nodes = nodes
        .filter((n) => n.selected === true)
        .map((n) => {
            let answerNodes: array;

            if (n.selected && n.nodes && n.nodes.length !== 0) {
                answerNodes = prepareNodeResponses(n.nodes);
            }

            return {
                unique_node_key: n.unique_key,
                ...(n.value && { value: n.value }),
                ...(answerNodes && { answer_nodes: answerNodes }),
            };
        });

    return nodes;
};

export const prepareValidationData = (questions: array) => {
    const response = [];

    questions.forEach((question) => {
        const questionState = {};

        // checkbox
        if (
            question?.metadata?.question_type === "checkbox" ||
            question?.metadata?.question_type === "radio" ||
            question?.metadata?.question_type === "nodecheckbox" ||
            question?.metadata?.question_type === "cardcheckbox" ||
            question?.metadata?.question_type === "cardradiobox"
        ) {
            questionState[question.unique_key] = question.choices
                ?.filter((choice) => choice.selected)
                .map((choice) => {
                    return {
                        unique_choice_key: choice.unique_key,
                        ...(choice.value && { value: choice.value }),
                        ...(choice.metadata && { metadata: choice.metadata }),
                        ...(choice.nodes && { nodes: choice.nodes }),
                    };
                });
        } else {
            questionState[question.unique_key] = question.choices
                ?.filter(
                    (choice) =>
                        choice.value ||
                        choice.value === 0 ||
                        choice.value === "" ||
                        choice?.metadata?.isRequired === "true"
                )
                .map((choice) => {
                    return {
                        unique_choice_key: choice.unique_key,
                        value: choice.value,
                        isRequired: choice?.metadata?.isRequired === "true",
                        isValid: choice.isValid, // if validation not available,mark validation as true
                    };
                });
        }

        const questionType = question?.metadata?.question_type;
        let isRequired = question.required;
        const questionElement = document.getElementById(question.id) || null;

        if (questionType !== "customslider") {
            if (questionElement && isRequired) {
                isRequired = true;
            } else {
                isRequired = false;
            }
        }

        questionState.isRequired = isRequired;
        response.push(questionState);
    });

    return response;
};

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
};

interface condetionalRender {
    questionID: string;
    needToAnswer: Array<string>;
}

export const handleQuestionRender = (
    currentQuestion: string,
    questions: array
) => {
    if (!isJsonString(currentQuestion?.visible_if)) {
        return shouldRenderQuestion(currentQuestion, questions);
    }

    const conditionsToRender: condetionalRender = JSON.parse(
        currentQuestion?.visible_if
    );

    const questionNeedToAnswer = questions?.filter(
        (question) => question.unique_key === conditionsToRender.questionID
    );

    const isRequiredChoiceAnswered =
        questionNeedToAnswer[0]?.choices?.filter(
            (choice) =>
                conditionsToRender.needToAnswer.includes(choice.unique_key) &&
                choice?.selected
        ).length > 0;

    return isRequiredChoiceAnswered;
};

export const shouldRenderQuestion = (
    currentQuestion: string,
    questions: array
) => {
    let shouldRender = false;
    const condition = currentQuestion?.visible_if;

    if (condition) {
        const parsedCondition = evalCondition(condition);

        const question = findQuestion(
            parseConditionalValue(parsedCondition.leftValue),
            currentQuestion,
            questions
        );

        // check if the dependent question is rendered or not
        /** TODO: Check the time and apce complexity **/
        const isDependentQuestionRendered = handleQuestionRender(
            question,
            questions
        );

        const questionValue = findQuestionValue(question);

        shouldRender =
            isDependentQuestionRendered &&
            evaluateCondition(
                questionValue,
                parsedCondition.condition,
                parsedCondition.rightValue
            );
    } else {
        shouldRender = true;
    }

    return shouldRender;
};

// eval condition string from the current question
export const evalCondition = (conditionString: string) => {
    let condition;
    const conditions = ["===", "!==", ">", "<"];

    for (const c of conditions) {
        const con = conditionString.split(c);

        if (con.length > 1) {
            conditionString = con;
            condition = c;
            break;
        }
    }

    return {
        leftValue: conditionString[0],
        condition,
        rightValue: conditionString[1],
    };
};

export const findQuestion = (
    questionUniqueKey: string,
    currentQuestion: object,
    questions: array
) => {
    let question: any;

    switch (questionUniqueKey) {
        case "previousQuestion": {
            // todo
            const currentQuestionIndex =
                questions.findIndex(
                    (q) => q.unique_key === currentQuestion.unique_key
                ) || {};

            question = questions[currentQuestionIndex - 1] || {}; // find previous question
            break;
        }

        default: {
            question =
                questions.filter(
                    (q) => q.unique_key === questionUniqueKey
                )[0] || {};
            break;
        }
    }

    return question;
};

// remove the { and } or anything else for later from the string -> sanitise the string
export const parseConditionalValue = (conditionValue: string) => {
    return conditionValue.replace("{", "").replace("}", "");
};

export const splitMultipleConditions = (conditionstring: string) => {
    return conditionstring.split(","); // return as an array of condition string for multiple conditions
};

// question value or user response -> it depends on type of question -> customslider ,radio
export const findQuestionValue = (question: any) => {
    let value: any = null;

    // for radio
    if (
        question?.metadata?.question_type === "radio" ||
        question?.metadata?.question_type === "dropdown"
    ) {
        const choices = question.choices;

        for (const choice of choices) {
            if (String(choice.selected) === "true") {
                value = choice.value;
                break;
            }
        }
    } else {
        // for custom slider
        value = question?.choices[0]?.value;
    }

    return value === undefined ? "" : value;
};

// evaluate condition
export const evaluateCondition = (
    leftValue: any,
    condition: string,
    rightValue: any
) => {
    if (rightValue === "empty") rightValue = "";

    if (leftValue === null) leftValue = "";

    if (condition.includes(">") || condition.includes("<")) {
        leftValue = parseInt(leftValue) || 0;
        rightValue = parseInt(rightValue) || 0;
    }

    // eslint-disable-next-line no-eval
    return eval("leftValue" + condition + "rightValue");
};

export const findNextQuestion = (currentQuestion: object, questions: array) => {
    const currentQuestionIndex =
        questions.findIndex(
            (q) => q.unique_key === currentQuestion.unique_key
        ) || {};

    return questions[currentQuestionIndex + 1] || {};
};

export const findPreviousCategory = (
    categories: Array,
    currentCategory: any
) => {
    const currentCategoryIndex =
        categories.findIndex(
            (c) => c.unique_key === currentCategory.unique_key
        ) || {};

    return categories[currentCategoryIndex - 1] || {};
};

/** Range Converted - Jay T **/

export const convertRange = (value, oldRange, newRange) => {
    return (
        ((value - oldRange.min) * (newRange.max - newRange.min)) /
            (oldRange.max - oldRange.min) +
        newRange.min
    );
};

export const shouldRenderChoice = (currentChoice: any, choices: array) => {
    let shouldRender = true;
    const needToAnsChoiceId =
        currentChoice?.metadata?.visible_if_choice_answered;

    const prevChoice = choices.filter(
        (choice) => choice.unique_key === needToAnsChoiceId
    )[0];
    const prevChoiceValue = prevChoice?.value || null;

    if (needToAnsChoiceId && !prevChoiceValue) {
        if (prevChoiceValue && prevChoiceValue?.length > 0) {
            return shouldRender;
        }

        shouldRender = false;
    }

    return shouldRender;
};

export const getImageKeyFromResponse = (response, code) => {
    const codePos = response.indexOf(code) + 2;

    return response.slice(codePos, response.indexOf(",", codePos));
};

export const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 11)}`;
};

export const convertUTCDateToLocalRegionDate = (date) => {
    const newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
};

// this function is return age from birtdate
export const renderAge = (title: object) => {
    const date = title;
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

// find the next page while is depending on the current page and should be updated if the current page is updated
// this is part of the edit profile for therapist
export const findDependentPage = (
    currentPage: any,
    pages: array,
    currentQuestions: array
) => {
    const responses = prepareResponseData(currentQuestions);

    const currentPageIndex = pages.findIndex((p) => p.id === currentPage.id);

    const nextPage =
        currentPageIndex < pages.length - 1 ? pages[currentPageIndex + 1] : {};

    const renderNextPage =
        nextPage?.questions?.filter(
            (q) => q.conditional_rendering || q.depends_on
        ).length !== 0 || nextPage?.depends_on;

    if (renderNextPage) {
        const condition = nextPage?.questions?.filter(
            (q) => q.conditional_rendering
        )?.[0]?.conditional_rendering;

        const dependsOn =
            nextPage?.depends_on ||
            nextPage?.questions?.filter((q) => q.depends_on)?.[0].depends_on;

        if (condition) {
            // getting unique choices keys.
            let uniqueChoicesKey = null;

            responses.forEach((response) => {
                Object.entries(response).forEach((choices) => {
                    uniqueChoicesKey = choices[1][0]?.unique_choice_key;
                });
            });

            if (condition && condition.includes(uniqueChoicesKey)) {
                return nextPage;
            }
        } else if (dependsOn) {
            const dependsOnQuestion = currentQuestions.filter(
                (q) =>
                    q.unique_key === dependsOn ||
                    dependsOn.includes(q.unique_key)
            )?.[0];

            if (dependsOnQuestion) {
                return nextPage;
            }
        }
    }

    return null;
};

export const hasCurrentPageConditionalRendering = (currentPage: any) => {
    return currentPage?.questions?.filter((q) => q.depends_on)?.length > 0;
};

// this has to be optimised - todo
export const evaluateParentResponseToShowHideCurrentSection = (
    currentPage: any,
    pages: array
) => {
    const previousPageKey = currentPage?.previous_page_key;

    const previousPage = pages.filter(
        (p) => p.unique_key === previousPageKey
    )[0];

    const question = currentPage.questions[0];

    const dependsOn = question?.depends_on;
    const condition = question?.conditional_rendering;

    if (dependsOn && condition) {
        const dependsOnQuestion = previousPage?.questions?.filter(
            (q) => q.unique_key === question.depends_on
        )[0];
        const conditionKeys = condition.split(":")[1].split(",");
        const choices = dependsOnQuestion?.choices || [];

        for (let i = 0; i < choices.length; i++) {
            const choice = choices[i];

            if (
                conditionKeys.includes(choice.unique_key) &&
                choice.selected === true
            ) {
                return true;
            }
        }

        return false;
    }
};

export const getRefreshToken = async () => {
    let isRefreshingToken = false;
    let accessToken: string = "";
    const token = JSON.parse(Storage.getItem(storageKey.accessToken) || "{}");
    const refreshToken = get(token, "refresh_token", "");

    try {
        if (isRefreshingToken === false) {
            isRefreshingToken = true;
            const tokenData = {
                grant_type: "refresh_token",
                refresh_token: refreshToken,
            };
            const response = await getAuth0Token(tokenData);

            if (response.access_token && response.refresh_token) {
                store.dispatch(updateUserToken(response));
                accessToken = response.access_token;
                // error.config.headers.Authorization = `Bearer ${response.access_token}`;
                isRefreshingToken = false;

                return accessToken && true; // axios(error.config);
            } else {
                Storage.clear();
                if (
                    !window.location.pathname.includes("login") &&
                    !window.location.pathname.includes("sign-up") &&
                    window.location.pathname !== "/"
                )
                    window.location.reload();
            }
        }
    } catch (e) {
        Storage.clear();
        if (
            !window.location.pathname.includes("login") &&
            !window.location.pathname.includes("sign-up") &&
            window.location.pathname !== "/"
        )
            window.location.reload();
    }
};

/**
 * setBrowserStorage
 * @param {*} storageType
 * @param {*} storageObj
 */
export const setBrowserStorage = (
    storageType: "localStorage" | "sessionStorage",
    storageObj: any
) => {
    switch (storageType) {
        case "localStorage":
            Object.keys(storageObj).map((key) =>
                localStorage.setItem(key, storageObj[key])
            );
            break;

        default:
            Object.keys(storageObj).map((key) =>
                sessionStorage.setItem(key, storageObj[key])
            );
            break;
    }
};

/**
 * fetchBrowserStorage
 * @param {*} storageType
 * @param {*} key
 * @returns
 */
export const fetchBrowserStorage = (
    storageType: "localStorage" | "sessionStorage",
    key: string
) => {
    return storageType === "localStorage"
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
};

/**
 * removeFromBrowserStorage
 * @param {*} storageType
 * @param {*} key
 * @returns
 */
export const removeFromBrowserStorage = (
    storageType: "localStorage" | "sessionStorage" | "all",
    keys?: Array<string>
) => {
    switch (storageType) {
        case "localStorage":
            keys.forEach((key) => localStorage.removeItem(key));
            break;

        case "sessionStorage":
            keys.forEach((key) => sessionStorage.removeItem(key));
            break;

        default:
            sessionStorage.clear();
            localStorage.clear();
            break;
    }
};

// for input and function call delay
export const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

// find role from array of user_type based on priority
// this is a way to get users highest role
export const findRole = (userTypes: array) => {
    const isPracticeAdmin = userTypes.includes("PRACTICE_ADMIN");
    const isScheduler = userTypes.includes("SCHEDULER") && !isPracticeAdmin;
    const isTherapist =
        userTypes.includes("THERAPIST") && !isScheduler && !isPracticeAdmin;
    const isClient = userTypes.includes("CLIENT");

    if (isPracticeAdmin) return "practice_admin";
    if (isScheduler) return "scheduler";
    if (isTherapist) return "therapist";
    if (isClient) return "client";
};

/* check mobile device or not */
export const isMobileDevice = () => {
    const deviceType = window.navigator.userAgent.toLowerCase();

    return deviceType?.includes("mobile");
};

/* format timestamp in month-day-year */
export const formatUTCDate = (timestamp) => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const second = date.getUTCSeconds();

    return `${year}_${month}_${day}_${String(hour).padStart(2, "0")}_${String(
        minute
    ).padStart(2, "0")}_${String(second).padStart(2, "0")}`;
};

export const getGenderPronoun = (key: string) => {
    switch (key) {
        case "NONE_SELECTED":
            return "None";

        case "HER":
            return "she/her/hers";

        case "HIM":
            return "he/him/his";

        case "THEM":
            return "they/them/theirs";

        case "HIR":
            return "ze/hir/hirs";

        case "ZIR":
            return "ze/zir/zirs";

        default:
            return null;
    }
};

// demo notification component
export const ShowNotification = (message: string) => {
    const dispatch = useDispatch();

    dispatch(
        setFlashNotification({
            message,
        })
    );

    return null;
};

export const validateInput = (regex: string, value: any) => {
    if (!regex || !value) return true;
    const match = value?.match(new RegExp(regex));

    return match ? match[0] === value : false;
};

export const fetchDataFromSource = async (source: string) => {
    switch (source) {
        case "userservice": {
            const user = await fetchUserInfoV1();
            const address = await fetchUserAddressByIdV1(user?.id);

            return { ...user, address };
        }

        default:
            break;
    }
};

export const isAndriodDevice = () => {
    const ua = navigator.userAgent.toLowerCase();

    return ua.indexOf("android") > -1;
};

export const getToDoButtonVariant = (type: string) => {
    switch (type) {
        case "CREATE_ACCOUNT_REMINDER":
        case "INCOMPLETE_PROFILE_REMINDER":
            return "info";
        case "matchResultTodo":
        case "requestMatchHelpTodo":
            return "primary";
        case "REQUESTED_REMATCH":
            return "danger";
        case "insuranceCardTodo":
            return "teal";

        case "amdUserConflictTodo":
            return "teal";
        default:
            return "info";
    }
};

export const getToDoButtonLabel = (todo: string) => {
    return (
        <Fragment>
            {todo.button_label}
            <FontAwesomeIcon icon={faArrowRight} className="ms-2 arrow-right" />
        </Fragment>
    );
};

export const formAddressString = (address: Object) => {
    let addressString = "";

    Object.keys(address)
        .sort()
        .forEach((k) => {
            addressString += " " + address[k];
        });

    return addressString;
};

export const choicesToRender = (choices, questions) => {
    choices = choices.filter((c) => {
        if (c.visible_if) {
            const parsedCondition = evalCondition(c.visible_if);

            const questionValue = findQuestionValue(
                findQuestion(
                    parseConditionalValue(parsedCondition.leftValue),
                    questions,
                    questions
                )
            );

            const shouldRender = questionValue
                ? evaluateCondition(
                      questionValue,
                      parsedCondition.condition,
                      parsedCondition.rightValue
                  )
                : true;

            c.visible = shouldRender;

            return c;
        } else {
            c.visible = true;

            return c;
        }
    });

    return choices;
};

export const getEllipses = (string: string, maxLength: number = 25) => {
    return string?.length > maxLength
        ? string.substr(0, maxLength) + "..."
        : string;
};

export const extractContentFromHTML = (s) => {
    const span = document.createElement("span");

    span.innerHTML = s;

    return span.textContent || span.innerText;
};

export const findGridType = (count) => {
    if (count <= 4) {
        return count;
    } else if (count === 5) {
        return 3;
    } else if (count === 8 || count === 6) {
        return count;
    } else {
        return 4;
    }
};

export const getSlotIcons = (slotReason) => {
    let slotIcons;
    const slotReasonString = slotReason.toString()?.toLowerCase();

    if (
        slotReasonString?.includes("online") &&
        !slotReasonString?.includes("online or in person")
    ) {
        slotIcons = (
            <>
                <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#24cc7d" }}
                    size="xs"
                />
            </>
        );
    }

    if (
        slotReasonString?.includes("in person") &&
        !slotReasonString?.includes("online or in person")
    ) {
        slotIcons = (
            <FontAwesomeIcon
                icon={faCircle}
                style={{ color: "#ae54e4" }}
                size="xs"
            />
        );
    }

    if (slotReasonString?.includes("online or in person")) {
        slotIcons = (
            <>
                <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#24cc7d" }}
                    size="xs"
                />
                <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                        color: "#be73eb",
                        position: "relative",
                        zIndex: 3,
                        right: "5px",
                    }}
                    size="xs"
                />
            </>
        );
    }

    return slotIcons;
};

export const addhoursToDate = (
    dateString,
    hours = 0,
    isRequireLocalDate = false,
    timeZone = "America/chicago"
) => {
    // Create a moment object from the input date string
    const inputDate = moment(dateString).tz(timeZone);

    // Add 16 hours to the input date
    const updatedDate = inputDate.add(hours, "hours");

    if (isRequireLocalDate === false) {
        // Return the updated date in ISO format
        return updatedDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    } else {
        // Format the date according to the specified options
        const formattedDate = updatedDate.format(
            "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
        );

        return formattedDate;
    }
};

export const convertToDay = (time) => {
    const timestamp = new Date(time);
    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const dayOfWeek = daysOfWeek[timestamp.getUTCDay()];

    return dayOfWeek;
};

export const convertToMonth = (time) => {
    const timestamp = new Date(time);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const monthName = months[timestamp.getUTCMonth()];

    return monthName;
};

export const convertTimezone = (time) => {
    const timestamp = new Date(time);

    // Extract day, month, year, hours, minutes, and seconds from the timestamp
    const hours = timestamp.getUTCHours();
    const minutes = timestamp.getUTCMinutes();
    const seconds = timestamp.getUTCSeconds();

    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const dayOfWeek = daysOfWeek[timestamp.getUTCDay()];

    const timezoneOffset = timestamp.getTimezoneOffset(); // Offset in minutes

    const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
    const timezoneOffsetMinutes = Math.abs(timezoneOffset) % 60;
    const timezoneSign = timezoneOffset < 0 ? "+" : "-";

    const timezone = `UTC${timezoneSign}${timezoneOffsetHours
        .toString()
        .padStart(2, "0")}:${timezoneOffsetMinutes
        .toString()
        .padStart(2, "0")}`;

    const formattedTimezone = `${dayOfWeek} - ${hours}:${minutes}:${seconds} (${timezone})`;

    return formattedTimezone;
};

export const convertToDate = (time) => {
    const timestamp = new Date(time);
    const day = timestamp.getUTCDate().toString().padStart(2, "0");

    const formattedDate = `${day}`;

    return formattedDate;
};

export const StringIsNumber = (value: string): boolean => {
    return value?.match(/^[0-9]+$/) != null;
};

export const groupOffice = (
    isAddressPage = false,
    currentChoices: any[],
    PracticeProfileMetadata:
        | ((choice: { metadata: string }) => SupportMetadata)
        | null = null
) => {
    if (isAddressPage) {
        const grouped = currentChoices.reduce((acc, choice) => {
            let metadata = {};

            if (PracticeProfileMetadata) {
                metadata = PracticeProfileMetadata(choice);
            } else {
                metadata = choice.metadata;
            }

            const region = metadata.regionName || "";

            if (!acc[region]) {
                acc[region] = [];
            }

            acc[region].push(choice);

            return acc;
        }, {});

        return grouped;
    } else {
        // Otherwise, display all choices without grouping
        return { "": currentChoices };
    }
};

export default groupOffice;


export const formatDateInMDY = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    const formattedDate = month + "/" + day + "/" + year;

    return formattedDate;
};
