// @flow
import React, { useEffect } from 'react';
import Alert from '../../base/Alert/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setFlashNotification } from '../../../redux/modules/flashNotification';
import logoNotif from '../../../assets/icons/logo-shape.svg';
import close from '../../../assets/icons/icon-close.svg';
import Typography from '../../base/Typography/Typography';
import './flash-notification.scss';
import Avatar from './../../base/Avatar/Avatar';

export default function FlashNotification(): React$Element<any> {
    const dispatch = useDispatch();
    const { photoUrl,message, isError, duration, dispatchClass,userName, hasButton, buttonAction, buttonLabel, buttonVariant, isWelcome } = useSelector(({ flashNotification }) => flashNotification);

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                dispatch(setFlashNotification({}));
            }, duration);
        }
    }, [message, duration]);

    const onClose = () => {
        const notif = document.querySelector('.flash-notification');

        if (notif) notif.classList.add('d-none');
    };

    return (
        <div className={`flash-notification ${hasButton ? 'flash-notification__with-button' : ''} ${!Avatar && hasButton ? 'flash-notification__without-avatar' : ''} ${typeof message === 'string' && message.length <= 30 ? 'flash-string' : ''} ${!message ? 'd-none' : 'is-visible'} ${dispatchClass}`} >
            <Alert variant={isError ? 'danger' : 'success'} className='flash-notification__alert animateOpen'
                hasButton={hasButton} buttonLabel={buttonLabel} buttonAction={buttonAction}
                buttonVariant={buttonVariant}>
                <div className='flash-notification__content'>
                    <span className='flash-notification__content-close cursor-pointer' onClick={() => onClose()}>
                        <img src={close} alt='close' />
                    </span>
                    {
                        photoUrl ? <Avatar className='logo-notif' fullName={userName || ''}
                            photoUrl={photoUrl} /> : <img src={logoNotif} alt='logo notification' className='logo-notif' />
                    }
                    <div className={`flash-notification__content-wrapper ${isWelcome ? 'flash-notification__content-wrapper-welcome' : ''}`}>
                        {typeof message === 'string' ? <Typography className='flash-notification__content-wrapper__text ms-2' color='color-27' variant='size-16'
                            weight='regular'>
                            {message}
                        </Typography> : message}
                    </div>
                </div>
            </Alert>
        </div>
    );
}
