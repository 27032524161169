import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import * as rootReducer from './reducers';
import rootSaga from './middlewares';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

function configureAppStore(preloadedState) {
    const store = configureStore({
        reducer: { ...rootReducer },
        middleware: [thunk, routerMiddleware(history), ...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleware],
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    });

    sagaMiddleware.run(rootSaga);

    return store;
}

export default configureAppStore();
