// @flow

import { SET_CREATE_TODO, SET_REMOVE_TODO } from "../actions/todoActions";

/** todo **/
const initialState = {
    createTodo: {},
    removeTodo: {},
};

export default function todoStore(
    state: Object = initialState,
    action: any
): any {
    switch (action.type) {
        case SET_CREATE_TODO: {
            const createTodo = action.todoDetails;

            return { ...state, ...{ createTodo } };
        }

        case SET_REMOVE_TODO: {
            const removeTodo = action.todoDetails;

            return { ...state, ...{ removeTodo } };
        }

        default:
            return state;
    }
}
