import { all, put, select, takeEvery } from "redux-saga/effects";
import {
    getQuestionnaireMetadata,
    fetchQuestionsOfCurrentPage,
    saveQuestionResponsesOfCurrentPage,
    getUserProfileMetadata,
    getClientUserProfileMetadata,
} from "../services/questionnaireServices";
import {
    FETCH_PAGE_QUESTIONS,
    FETCH_QUESTIONNAIRE_MEATDATA,
    SAVE_PAGE_RESPONSES,
    SET_PAGE_QUESTIONS,
    SET_QUESTIONNAIRE_METADATA,
    START_QUESTIONNAIRE_LOADING,
    STOP_QUESTIONNAIRE_LOADING,
    UPDATE_QUESTIONNAIRE_CONFIG,
    SET_USERPROFILE_METADATA,
    FETCH_USERPROFILE_METADATA,
    FETCH_THERAPIST_CLIENTPROFILE_METADATA,
    SET_CLIENT_USER_PROFILE,
    FETCH_THERAPIST_CLIENT_INFO,
} from "../actions/questionnaireActions";
import {
    sequenceQuestionnaireMetaData,
    sequenceQuestions,
} from "../../utils/helpers";
import { fetchUserInformationByIdV1 } from "../../api/user";
import { userServiceSection } from "../../config/constants";
import { Logger } from "src/services";

export const getLoggedInUser = (state) => state.authentication.loggedInUser;

interface saveResponsesType {
    sessionId: string;
    categoryId: string;
    pageId: string;
    responses: Array;
    hasChangedResponses: boolean;
}

function* fetchQuestionnaireMetaData(action: object) {
    try {
        const questionnaireData = yield getQuestionnaireMetadata();
        const loggedInUser = yield select(getLoggedInUser);

        yield put({ type: START_QUESTIONNAIRE_LOADING });

        if (action.isSetToRedux) {
            const _questionnaireData = sequenceQuestionnaireMetaData(
                questionnaireData,
                loggedInUser // logged in user is required to decide if formatting metadata us required or not
            );

            yield put({
                type: SET_QUESTIONNAIRE_METADATA,
                questionnaireData: _questionnaireData,
            });
            yield put({
                type: UPDATE_QUESTIONNAIRE_CONFIG,
                ...{ 
                    initialMetadata: _questionnaireData,
                    resetCurrentPage: action.resetCurrentPage,
                    pageUniqueKey: action.pageUniqueKey
                },
            });
        }

        yield put({ type: STOP_QUESTIONNAIRE_LOADING });
    } catch (e) {
        Logger.log(e.message);
    }
}

function* fetchQuestionnaireMetaDataSaga() {
    yield takeEvery(FETCH_QUESTIONNAIRE_MEATDATA, fetchQuestionnaireMetaData);
}

export function* fetchQuestions({ pageId, sessionId, direction }) {
    try {
        yield put({ type: START_QUESTIONNAIRE_LOADING });
        const pageQuestions = yield fetchQuestionsOfCurrentPage(
            pageId,
            sessionId
        );
        const questions = sequenceQuestions(pageQuestions?.questions);

        if (questions.length === 0)
            yield put({ type: UPDATE_QUESTIONNAIRE_CONFIG, direction }); // skip a page if not question
        yield put({ type: SET_PAGE_QUESTIONS, questions });
        yield put({ type: STOP_QUESTIONNAIRE_LOADING });
    } catch (e) {
        Logger.log(e);
    }
}

function* fetchPageQuestions() {
    yield takeEvery(FETCH_PAGE_QUESTIONS, fetchQuestions);
}

export function* saveResponses(session) {
    const {
        sessionId,
        categoryId,
        pageId,
        responses,
        hasChangedResponses,
        responseCallback,
        updateConfig = true,
    }: saveResponsesType = session.response;

    try {
        yield put({ type: START_QUESTIONNAIRE_LOADING });
        if (hasChangedResponses)
            yield saveQuestionResponsesOfCurrentPage(
                sessionId,
                categoryId,
                pageId,
                responses,
                responseCallback
            );

        yield put({ type: STOP_QUESTIONNAIRE_LOADING });
        /** Update question configuration after answers were saved successfully **/
        if (updateConfig) yield put({ type: UPDATE_QUESTIONNAIRE_CONFIG });
    } catch (e) {
        yield put({ type: STOP_QUESTIONNAIRE_LOADING });
        Logger.log("error", e);
    }
}

function* savePageResponse() {
    yield takeEvery(SAVE_PAGE_RESPONSES, saveResponses);
}

function* fetchUserProfileMetaData(action: object) {
    try {
        yield put({ type: START_QUESTIONNAIRE_LOADING });
        const userProfileData = yield getUserProfileMetadata(
            action.responseCallback,
            action.id
        );

        if (action.isSetToRedux) {
            yield put({
                type: SET_USERPROFILE_METADATA,
                currentUserProfileMetaData:
                    userProfileData?.questionnaire_group?.categories,
                initialMetadata: userProfileData,
            });
        }
    } catch (e) {
        Logger.log(e.message);
    }
}

function* fetchUserProfileMetaDataSaga() {
    yield takeEvery(FETCH_USERPROFILE_METADATA, fetchUserProfileMetaData);
}

// fetch therapist client profile metadata
function* fetchTherapistClientProfileMetaData(action: object) {
    try {
        yield put({ type: START_QUESTIONNAIRE_LOADING });
        const userProfileData = yield getClientUserProfileMetadata(action?.id);

        if (action.isSetToRedux) {
            yield put({
                type: SET_USERPROFILE_METADATA,
                currentUserProfileMetaData:
                    userProfileData?.questionnaire_group?.categories,
            });
        }

        yield put({ type: STOP_QUESTIONNAIRE_LOADING });
    } catch (e) {
        Logger.log(e.message);
    }
}

function* fetchTherapistClientProfileMetaDataSaga() {
    yield takeEvery(
        FETCH_THERAPIST_CLIENTPROFILE_METADATA,
        fetchTherapistClientProfileMetaData
    );
}

// therapist client profile data email & bithdate
function* fetchTherapistClientMetaData(action: object) {
    try {
        yield put({ type: START_QUESTIONNAIRE_LOADING });
        // TODO R:Remove After Testing
        const userProfileData = yield fetchUserInformationByIdV1(
            action?.id,
            userServiceSection.profile_display
        );

        if (action.isSetToRedux) {
            yield put({
                type: SET_CLIENT_USER_PROFILE,
                clientMetaData: userProfileData,
            });
        }

        // yield put({ type: STOP_QUESTIONNAIRE_LOADING });
    } catch (e) {
        Logger.log(e.message);
    }
}

function* fetchTherapistClientMetaDataSaga() {
    yield takeEvery(FETCH_THERAPIST_CLIENT_INFO, fetchTherapistClientMetaData);
}

// root
export default function* questionnaireSaga() {
    yield all([
        fetchQuestionnaireMetaDataSaga(),
        fetchPageQuestions(),
        savePageResponse(),
        fetchUserProfileMetaDataSaga(),
        fetchTherapistClientProfileMetaDataSaga(),
        fetchTherapistClientMetaDataSaga(),
    ]);
}
