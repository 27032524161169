import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import Tooltip from "src/components/base/Tooltip/Tooltip";
import Typography from "src/components/base/Typography/Typography";
import MiniCheckBox from "src/components/modules/MiniCheckBox/MiniCheckBox";
import Button from "src/components/base/Button/Button";
import { Logger } from "src/services";
import {
    cancelOnboarding,
    changeTodoStatusV1,
    getPracticeAdminUsersV1,
} from "src/api/user";
import { addClusterMember } from "src/redux/services/questionnaireServices";
import { setFlashNotification } from "src/redux/modules/flashNotification";
import { clearModalContent } from "src/redux/modules/modalEvent";
import { updateIfollowedUpTodo } from "src/redux/services/loggingServices";
import { TherapistSelectionCriteriaInfo } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

type Props = {
    todo?: any;
    getUpdatedAuditLogs: Function;
};

export function MatchRequestedModal({ todo, getUpdatedAuditLogs }: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const [note, setnote] = useState<string>("");
    const [therapists, setTherapists] = useState([]);
    const [selectedTherapist, setSelectedTherapist] = useState<any>();
    const [foundMatchIntake, setFoundMatchIntake] = useState<string>("");
    const [todoMatchAction, setTodoMatchAction] = useState<string>("");
    const dispatch = useDispatch();

    const editNoteContent = (e: any) => {
        setnote(e.target.value);
    };

    useEffect(() => {
        fetchTherapists();
    }, []);

    const foundMatchIntakeRadio = (source: string) => {
        switch (source) {
            case "found-match-intake":
                return setFoundMatchIntake("found-match-intake");
            case "not-found-match-intake":
                return setFoundMatchIntake("not-found-match-intake");
            case "move-todo-pending":
                return setTodoMatchAction("move-todo-pending");
            case "cancel-todo-request":
                return setTodoMatchAction("cancel-todo-request");
            default:
                return "";
        }
    };

    const fetchTherapists = async (value?: string) => {
        const requestPayload = {
            limit: 10,
            offset: 0,
            user_type: "THERAPIST",
            keyword: value || null,
        };

        setLoading(true);
        const { users } = await getPracticeAdminUsersV1(requestPayload);

        setTherapists(users || []);
        setLoading(false);
    };

    const handleSubmit = async () => {
        if (!todo) {
            return;
        }

        try {
            setLoading(true);

            if (foundMatchIntake === "found-match-intake") {
                if (!selectedTherapist?.id) {
                    return;
                }

                handleTherapistSelection();
            } else if (todoMatchAction === "move-todo-pending") {
                markTODOPending();
            } else if (todoMatchAction === "cancel-todo-request") {
                canceTODO();
            }
        } catch (error) {
            dispatch(
                setFlashNotification({
                    message: "Unable to match client, please try again later.",
                    isError: true,
                })
            );
            Logger.error(error);
        } finally {
            setLoading(false);
        }
    };

    async function handleTherapistSelection(): Promise<void> {
        await addClusterMember(
            todo.user_id,
            selectedTherapist.id,
            todo?.metadata?.service_type?.toUpperCase(),
            false
        );

        const followUpObject = {
            follow_up_by: ["SELF"],
            note: note,
        };

        await updateIfollowedUpTodo(todo?.id, followUpObject);
        dispatch(clearModalContent());
        getUpdatedAuditLogs();
    }

    async function markTODOPending(): Promise<void> {
        await changeTodoStatusV1(todo.id, false, note);
        dispatch(clearModalContent());
        getUpdatedAuditLogs();
    }

    async function canceTODO(): Promise<void> {
        await cancelOnboarding(todo.user_id);
        dispatch(clearModalContent());
        getUpdatedAuditLogs();
    }

    return (
        <div className="add-note-text-area d-flex flex-column justify-content-center follow-up-modal">
            <div className="mb-4 d-flex flex-column">
                <div className="mb-4">
                    {todo.user_full_name} has requested{" "}
                    <i>
                        <strong>help finding a match.</strong>
                    </i>
                </div>

                <TherapistSelectionCriteriaInfo
                    insurancePlans={todo?.metadata?.all_insurance_plans}
                    serviceType={todo?.metadata?.service_type}
                    meetingPreference={todo?.metadata?.meeting_preference}
                    worksWithAges={todo?.metadata?.works_with_ages}
                    allOfficeLocations={todo?.metadata?.all_office_locations}
                    clientMessage={todo?.metadata?.client_message}
                />

                <div className="mb-4">
                    <div className="mb-4">
                        <Typography>
                            Were you able to find a match and schedule an
                            intake?
                        </Typography>
                    </div>
                    <div className="d-flex flex-row m-auto checkbox-container justify-content-between">
                        <div className="d-flex flex-column justify-content-start">
                            <div className="mb-1 ">
                                <MiniCheckBox
                                    text="Yes"
                                    isCheckFirst={true}
                                    isActive={
                                        foundMatchIntake ===
                                        "found-match-intake"
                                    }
                                    onClick={() =>
                                        foundMatchIntakeRadio(
                                            "found-match-intake"
                                        )
                                    }
                                    value=""
                                />
                            </div>
                        </div>
                        <div className="d-flex  flex-column justify-content-start mr-5">
                            <div className="mb-1">
                                <MiniCheckBox
                                    text="No"
                                    isCheckFirst={true}
                                    isActive={
                                        foundMatchIntake ===
                                        "not-found-match-intake"
                                    }
                                    onClick={() =>
                                        foundMatchIntakeRadio(
                                            "not-found-match-intake"
                                        )
                                    }
                                    value=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {(foundMatchIntake === "found-match-intake" ||
                    !foundMatchIntake) && (
                    <>
                        <div className="mb-4">
                            <div className="mb-4">
                                <Typography>
                                    The patient has been matched with:
                                </Typography>
                            </div>
                            <Autocomplete
                                disablePortal={true}
                                options={therapists}
                                loading={loading}
                                onChange={(e, value) =>
                                    setSelectedTherapist(value)
                                }
                                onInputChange={(event, newInputValue) =>
                                    fetchTherapists(newInputValue)
                                }
                                getOptionLabel={(option: any) =>
                                    option.full_name || option.email
                                }
                                renderOption={(props, option: any) => {
                                    return (
                                        <li {...props}>
                                            <Grid
                                                container={true}
                                                justifyContent={"flex-start"}
                                            >
                                                <Box component="span">
                                                    {option.full_name ||
                                                        option.email}
                                                </Box>
                                            </Grid>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        className="select-therapist-input"
                                        placeholder="Select therapist"
                                    />
                                )}
                            />
                        </div>
                    </>
                )}

                {foundMatchIntake === "not-found-match-intake" && (
                    <div className="mb-4">
                        <div className="mb-4">
                            <Typography>I would like to:</Typography>
                        </div>
                        <div className="d-flex flex-row m-auto justify-content-between">
                            <div className="d-flex flex-column justify-content-start">
                                <div
                                    className="mb-1"
                                    style={{
                                        display: "flex",
                                        width: "200px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <MiniCheckBox
                                        text="Move TODO to pending"
                                        isCheckFirst={true}
                                        isActive={
                                            todoMatchAction ===
                                            "move-todo-pending"
                                        }
                                        onClick={() =>
                                            foundMatchIntakeRadio(
                                                "move-todo-pending"
                                            )
                                        }
                                        value=""
                                    />
                                    <Tooltip
                                        text={
                                            "This TODO will be moved to Pending temporarily.  Use this option if you are waiting to hear back from a patient or therapist, or if the patient is on a waiting list."
                                        }
                                        placement="right"
                                        className="custom-tooltip-smaller-width "
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="d-flex  flex-column justify-content-start mr-5">
                                <div
                                    className="mb-1"
                                    style={{
                                        display: "flex",
                                        width: "190px",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <MiniCheckBox
                                        text="Cancel match request"
                                        isCheckFirst={true}
                                        isActive={
                                            todoMatchAction ===
                                            "cancel-todo-request"
                                        }
                                        onClick={() =>
                                            foundMatchIntakeRadio(
                                                "cancel-todo-request"
                                            )
                                        }
                                        value=""
                                    />
                                    <Tooltip
                                        text={
                                            "Cancelling will clear the TODO and pause the patient's onboarding process.  Use this if this patient is no longer interested in services, or has been referred out."
                                        }
                                        placement="right"
                                        className="custom-tooltip-smaller-width "
                                    >
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="mt-4">
                    <div className="d-flex justify-content-center">
                        <Typography className="mr-2">
                            Add Contact Note
                        </Typography>
                        <Typography color="color-28">(optional)</Typography>
                    </div>
                    <textarea
                        rows={4}
                        maxLength={500}
                        placeholder="Add Note"
                        value={note}
                        onChange={editNoteContent}
                    />
                    <Button
                        disabled={
                            loading ||
                            !foundMatchIntake ||
                            (foundMatchIntake === "found-match-intake" &&
                                !selectedTherapist) ||
                            (foundMatchIntake === "not-found-match-intake" &&
                                !todoMatchAction)
                        }
                        label="Save"
                        variant="primary"
                        textWeight="bold"
                        textSize="size-14"
                        isProcessing={loading}
                        className="m-3 modal-btn confirmation-modal__no-button"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}
