// @flow
import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Typography from '../Typography/Typography';
import type { TypographyVariant, Weight, ButtonVariant } from '../../../types';
import Badge from '../Badge/Badge';
import './button.scss';

type Props = {
    variant?: ButtonVariant,
    label: any,
    className?: string,
    onClick?: Function,
    textSize?: TypographyVariant,
    textWeight?: Weight,
    badgeVariant?: string,
    badgeLabel?: string,
    children?: any,
    props?: Object,
    disabled?:Boolean,
    isProcessing?: boolean,
    style?:any,
    id?:string,
    type?: string
};

export default function AppButton({
    variant,
    label = '',
    className = '',
    textSize,
    textWeight,
    onClick,
    badgeLabel,
    badgeVariant,
    children,
    disabled,
    isProcessing=false,
    style,
    id,
    type,
    ...props
}: Props): React$Element<any> {
    return (
        <Button
            {...props}
            type={type}
            style={style}
            variant={variant || 'primary'}
            className={`button cursor-pointer ${className}`}
            disabled={disabled || isProcessing}
            onClick={onClick}
            id={id}
            >
            <div className='btn-div'>
                {isProcessing && (
                    <Spinner
                        animation='border'
                        role='status'
                        variant={/light|link/.test(variant || '') ? 'dark' : 'light'}
                        className='button__spinner'/>
                )}
                <Typography
                    variant={textSize || 'size-14'}
                    // used css for outline text color
                    color={/light|link/.test(variant || '') ? 'color-2' : 'color-3'}
                    weight={textWeight || 'black'}>
                    {label}
                    {children}
                </Typography>
            </div>
            {badgeLabel && (
                <div className='button__badge'>
                    <Badge variant={badgeVariant}>4</Badge>
                </div>
            )}
        </Button>
    );
}
