/* eslint-disable camelcase */

// CONSTANTS
export const SET_QUESTIONNAIRE_METADATA = "SET_QUESTIONNAIRE_MEATDATA";
export const SET_QUESTIONNAIRE_DATA = "SET_QUESTIONNAIRE_DATA";
export const FETCH_QUESTIONNAIRE_MEATDATA = "FETCH_QUESTIONNAIRE_MEATDATA";
export const CLEAR_QUESTIONNAIRE_METADATA = "CLEAR_QUESTIONNAIRE_METADATA";
export const FETCH_PAGE_QUESTIONS = "FETCH_PAGE_QUESTIONS";
export const SET_PAGE_QUESTIONS = "SET_PAGE_QUESTIONS";
export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const UPDATE_QUESTIONNAIRE_CONFIG = "UPDATE_QUESTIONNAIRE_CONFIG";
export const START_QUESTIONNAIRE_LOADING = "START_QUESTIONNAIRE_LOADING";
export const STOP_QUESTIONNAIRE_LOADING = "STOP_QUESTIONNAIRE_LOADING";
export const SAVE_PAGE_RESPONSES = "SAVE_PAGE_RESPONSES";
export const UPDATE_QUESTION_VALUE = "UPDATE_QUESTION_VALUE";
export const UPDATE_CONTINUE_BUTTON = "UPDATE_CONTINUE_BUTTON";
export const HIDE_HEADER = "HIDE_HEADER";
export const SET_IS_COMPLETED = "SET_IS_COMPLETED";
export const FROM_SETTINGS_PAGE = "FROM_SETTINGS_PAGE";

export const FETCH_USERPROFILE_METADATA = "FETCH_USERPROFILE_METADATA";
export const CLEAR_USERPROFILE_METADATA = "CLEAR_USERPROFILE_METADATA";
export const FETCH_THERAPIST_CLIENTPROFILE_METADATA =
    "FETCH_THERAPIST_CLIENTPROFILE_METADATA";
export const FETCH_THERAPIST_CLIENT_INFO = "FETCH_THERAPIST_CLIENT_INFO";
export const SET_CLIENTPROFILE_METADATA_FOR_HEADER =
    "SET_CLIENTPROFILE_METADATA_FOR_HEADER";
export const SET_CLIENT_USER_PROFILE = "SET_CLIENT_USER_PROFILE";
export const SET_USERPROFILE_METADATA = "SET_USERPROFILE_DATA";
export const UPDATED_PROFILE_PIC = "UPDATED_PROFILE_PIC";
export const CLEAR_THERAPIST_CLIENT_METADATA =
    "CLEAR_THERAPIST_CLIENT_METADATA";
export const SET_IS_VALID = "SET_IS_VALID"; // question , choices , nodes
export const UPDATE_SIGN_PAD_STATUS = "UPDATE_SIGN_PAD_STATUS";
export const UPDATE_CHOICE_METADATA = "UPDATE_CHOICE_METADATA";
export const SET_SELF_USER_DOB = "SET_SELF_USER_DOB";
export const UPDATE_MEETING_INFO_ZOOM = "UPDATE_MEETING_INFO_ZOOM";
export const UPDATE_COMBINED_FILTERS = "UPDATE_COMBINED_FILTERS";

// this return meta data -> containing qroup , categories , pages
export function fetchQuestionnaireMetaData(isSetToRedux: Boolean = true, pageUniqueKey = null, resetCurrentPage: Boolean = false): any {
    return { type: FETCH_QUESTIONNAIRE_MEATDATA, isSetToRedux, pageUniqueKey, resetCurrentPage };
}

// this is to set questionnaire metadata , data where the metadata , pages , questions along with previous answer is present
// this is mainly to be used from modal
export function setQuestionnaireData(profileDetails: any): any {
    return { type: SET_QUESTIONNAIRE_DATA, profileDetails };
}

// clear the state object for questionnaireMetaData , set it to initial
export function clearQuestionnaireMetaData(): any {
    return { type: CLEAR_QUESTIONNAIRE_METADATA };
}

export function updateQuestionnaireConfig(
    categoryId: string = null,
    direction: string = "forward",
    resetCurrentPage: Boolean = false,
): any {
    return { type: UPDATE_QUESTIONNAIRE_CONFIG, categoryId, direction, resetCurrentPage };
}

export function fetchQuestions(
    pageId: String,
    sessionId: String,
    direction: string
): any {
    return { type: FETCH_PAGE_QUESTIONS, pageId, sessionId, direction };
}

export function saveResponses(response: any): any {
    return { type: SAVE_PAGE_RESPONSES, response };
}

export function updateQuestionValue(
    questionId: string,
    choiceId: string,
    value: any,
    isValid: boolean = true // default true
) {
    return {
        type: UPDATE_QUESTION_VALUE,
        questionId,
        choiceId,
        value,
        isValid,
    };
}

export function setSelfUserDob(dob: String): any {
    return {
        type: SET_SELF_USER_DOB,
        dob,
    };
}

export function updateContinueButton({ buttonStatus }) {
    return { type: UPDATE_CONTINUE_BUTTON, buttonStatus };
}

export function hideHeader(shouldHide: boolean) {
    return { type: HIDE_HEADER, shouldHide };
}

export function setIsCompleted(isCompleted: boolean) {
    return { type: SET_IS_COMPLETED, isCompleted };
}

export function fetchUserProfileMetaData(
    isSetToRedux: Boolean = true,
    responseCallback: function = null,
    id: String
): any {
    return {
        type: FETCH_USERPROFILE_METADATA,
        isSetToRedux,
        responseCallback,
        id,
    };
}

// fetch therapist client profile data
export function fetchTherapistClientProfileMetaData(
    id: String = "",
    isSetToRedux: Boolean = true
): any {
    return { type: FETCH_THERAPIST_CLIENTPROFILE_METADATA, isSetToRedux, id };
}

export function removeTherapistClientProfileMetaData(): any {
    return { type: CLEAR_THERAPIST_CLIENT_METADATA };
}

export function clearUserProfileMetaData(): any {
    return { type: CLEAR_USERPROFILE_METADATA };
}

export function fromSettingsPage(isFromSttingsPage: boolean) {
    return { type: FROM_SETTINGS_PAGE, isFromSttingsPage };
}

export function setClientProfileMetadataForHeader(data: boolean) {
    return { type: SET_CLIENTPROFILE_METADATA_FOR_HEADER, data };
}

// for email and birth date
export function fetchTherapistClientMetaData(
    id: String = "",
    isSetToRedux: Boolean = true
): any {
    return { type: FETCH_THERAPIST_CLIENT_INFO, isSetToRedux, id };
}

export function updatedProfilePic(changedSrc: String) {
    return { type: UPDATED_PROFILE_PIC, changedSrc };
}

export function updateSignPadStatus(status: boolean = false) {
    return {
        type: UPDATE_SIGN_PAD_STATUS,
        status,
    };
}

export function updateChoiceMetadata(
    questionId: String,
    choiceId: string,
    data: any
) {
    return {
        type: UPDATE_CHOICE_METADATA,
        questionId,
        choiceId,
        data,
    };
}

export function updateCombinedFilters(
    combinedFilters: any
) {
    return {
        type: UPDATE_COMBINED_FILTERS,
        combinedFilters
    };
}

export function setMeetingInfoZoom(info:obj){
    return {
        type : UPDATE_MEETING_INFO_ZOOM,
        info
    };
}
