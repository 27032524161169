import makeApiRequest from './makeApiCall';
import { config } from '../config/constants';

// const THERAPIST_PROFILE_ENDPOINT = `${config.userService}therapist-profile/`;
const THERAPIST_PROFILE_ENDPOINT = `${config.userServiceRestApiV1}users`;

export const getTherapistProfile = async () => {
    return await makeApiRequest(THERAPIST_PROFILE_ENDPOINT, 'GET');
};

export const updateTherapistProfile = async (data:object) => {
    return await makeApiRequest(THERAPIST_PROFILE_ENDPOINT, 'PUT', data);
};
