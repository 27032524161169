// @flow
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Typography from '../Typography/Typography';
import type { Color } from '../../../types';
import './badge.scss';

type Props = {
    className?: string,
    variant?: string,
    textColor?: Color,
    props?: any,
    children: any,
}

export default function AppBadge({ variant, textColor, className = '', children }: Props): React$Element<any> {
    return (
        <Badge variant={variant || 'primary'} className={`badge ${className}`} >
            <Typography color={textColor || 'color-3'} variant='size-12' weight='extra-bold'>
                {children}
            </Typography>
        </Badge>
    );
}
