// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import Typography from '../Typography/Typography';
import type { ButtonVariant } from '../../../types';
import './status.scss';

type Props = {
    className?: string,
    status: string,
    variant: ButtonVariant
}

export default function Status({ className = '', variant = 'primary', status }: Props): React$Element<any> {
    return (
        <div className={`status w-100 p-5 text-center d-inline-block ${className} bg-${variant} mx-auto`} >
            <FontAwesomeIcon className='status-icon-md' icon={faUserMd}/>
            <Typography
                className='mt-5'
                color='color-4'
                weight={'regular'} variant='size-24'>
                {status}
            </Typography>
        </div>
    );
}
