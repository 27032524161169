/* eslint-disable camelcase */
import moment from "moment";
import "moment-timezone";
import Storage from "./Storage";
import { storageKey } from "../config/constants";
import store from "../redux/store";
import { userLogOutV1 } from "../api/user";
import { setFlashNotification } from "../redux/modules/flashNotification";
import { setLoggingOut } from "../redux/modules/authentication";
import { Logger } from "src/services";
import { resetOnboardingState } from "src/redux/actions/onboardingActions";
import { clearUserProfileMetaData } from "src/redux/actions/questionnaireActions";

export const checkAuthorization = () => {
    const accessToken = getAuthToken();

    const authenticated = accessToken !== undefined && accessToken !== null;

    return authenticated;
};

export const getAuthToken = () => {
    const accessToken = Storage.getItem(storageKey.accessToken);

    if (accessToken == null) {
        return null;
    }

    return JSON.parse(accessToken);
};

export const saveAuthorizationToken = (accessToken) => {
    localStorage.setItem("t2fa", accessToken.access_token);

    return Storage.setItem(storageKey.accessToken, JSON.stringify(accessToken));
};

/**
 * Check if token is valid
 * @param {String} date
 * @returns {Boolean}
 */
export function isTokenValid(date: string) {
    const dateObj = new Date(date);
    const timeZone = moment.tz.guess();
    const momentObj = moment(dateObj).tz(timeZone).format();

    return moment().isBefore(momentObj);
}

/**
 * Handle Log out event
 * @param {string} type
 * @param {string} userId
 */

export async function GetUserLogOut(userId, type, showMessage: boolean = true) {
    try {
        await userLogOutV1(userId, type);
        store.dispatch(setLoggingOut());
        store.dispatch(resetOnboardingState());
        store.dispatch(clearUserProfileMetaData());
        sessionStorage.clear();
        Storage.clear();
    } catch (err) {
        Logger.error(err);
    }

    if (showMessage) {
        store.dispatch(
            setFlashNotification({
                message: "You have been logged out.",
                duration: 10000,
            })
        );
    }
}
