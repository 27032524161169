import "./photo.scss";
import Typography from "src/components/base/Typography/Typography";
import uploadIcon from "src/assets/icons/icon-upload.svg";

export const UploadImageContainer = () => {
    return (
        <div>
            <img
                src={uploadIcon}
                alt="upload-icon"
                className="photo-widget__upload mx-auto d-block"
            />
            <Typography color="color-28" variant="size-16" weight="semi-bold">
                Upload Picture
            </Typography>
        </div>
    );
};
