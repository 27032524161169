import makeApiRequest from "../../api/makeApiCall";
import { config } from "../../config/constants";

const LOGGING_SERVICE = config.loggingService;
const USER_SERVICE = config.userServiceRestApiV1;

export const getOnBoardingAuditLogs = async (
    userId: string,
    offset: Number,
) => {
    return await makeApiRequest(
        `${LOGGING_SERVICE}user/${userId}/onboarding-audit-log?offset=${
            offset || 0
        }`
    );
};

export const existingUser  = async (userName : string) =>{
    return await makeApiRequest(`${USER_SERVICE}users/_search?is_profile_required=false&limit=20&offset=0&user_name=${encodeURIComponent(userName)}&active=true`, 'GET');
};

export const getUserDocuments = async (userId: string) => {
    return await makeApiRequest(`${USER_SERVICE}documents/${userId}`);

};

export const updateIfollowedUpTodo = async (todoId: string, data: Object) => {
    return await makeApiRequest(
        `${USER_SERVICE}todos/${todoId}/_follow-up`,
        "POST",
        data
    );
};

export const addNote = async (userId: string, note: string) => {
    return await makeApiRequest(
        `${USER_SERVICE}users/${userId}/_notes`,
        "POST",
        {
            note
        }
    );
};

export const assignScheduler = async (
    userId: string,
    schedulerId: String,
    note?: String
) => {
    return await makeApiRequest(
        `${USER_SERVICE}users/${userId}/_assign-scheduler`,
        "POST",
        { note: note || "", scheduler_id: schedulerId }
    );
};

export const changeOnboardingStatus = async (
    userId: string,
    status: String,
    note: String
) => {
    return await makeApiRequest(
        `${USER_SERVICE}users/${userId}/_onboarding-status`,
        "PUT",
        {
            status,
            note,
        }
    );
};
