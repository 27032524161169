export enum AppConfigValuesEnum {
    /* eslint-disable no-unused-vars */
    TENANT_BACKGROUND_LARGE = "tenantBackgroundLarge",
    TENANT_BACKGROUND_SMALL = "tenantBackgroundSmall",
    TENANT_LOGO = "tenantLogo",
    TENANT_SUBDOMAIN = "tenantSubdomain",
    TENANT_WEBSITE = "tenantWebsite",
    TENANT_ADDRESS = "tenantAddress",
    TENANT_THERAPY_TYPE = "tenantTherapyType",
    TENANT_CONTACT = "tenantContact",
    TENANT_EMAIL = "tenantEmail",
    TENANT_ID = "tenantId",
    TENANT_NAME = "tenantName",
    TENANT_CONTACT_FORM = "tenantContactForm",
    BUG_EMAIL = "bugEmail",
    EHR_NAME = "ehrName",
    POWERED_BY_I2I_LOGO = "poweredByI2ILogo",
    TENANT_BACKGROUND_COLOR = "tenantBackgroundColor",
}
