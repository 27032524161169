/** Signature of a logging function */
// interface LogFn {
//     (message?: any, ...optionalParams: any[]): void;
// }

/** Basic logger interface */
interface LoggerModel {
    log: any;
    warn: any;
    error: any;
}

/** Log levels */
export type LogLevel = "log" | "warn" | "error";

const NO_OP: any = () => {};

const IS_DEV: boolean = process.env.NODE_ENV === "development";

/** Logger which outputs to the browser console */
export class ConsoleLogger implements LoggerModel {
    readonly log: any;
    readonly warn: any;
    readonly error: any;

    constructor() {
        const _console = console;

        if (IS_DEV) {
            this.error = _console.error.bind(console);
            this.warn = _console.warn.bind(console);
            this.log = _console.log.bind(console);
        } else {
            this.error = NO_OP;
            this.warn = NO_OP;
            this.log = NO_OP;
        }
    }
}

export const Logger = new ConsoleLogger();
