// @flow
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './Tooltip.scss';
/**
 * Author : Pushpendu Ghosh <pushpendu.ghosh@i2itherapy.com>
 * Created Date : 2nd June , 2022
 * Last Updated Date : 2nd June , 2022,
 * Last Updated By : Pushpendu Ghosh
 * Render Over lay if the textlength is gt minLength
 * **/

// This custom tooltip receives the following props
type Props = {
    className?: string, // to add as class / css
    text?: string, // text to show
    placement?: string, // position of the tooltip
    children?: any, // components insides
    force?:Boolean
}

const minLength = 25;

export default function CustomTooltip({ className = '', text, placement = 'auto', id = '', children, force = true }: Props): React$Element<any> {
    
    const textLength = text?.length;
    const show = textLength >= minLength || force;
    

    return (
        <>
            { show ? <OverlayTrigger
                placement={placement}
                overlay={<Tooltip className={className + ' CustomTooltip'}>{text}</Tooltip>}>
                {
                    children
                }
            </OverlayTrigger> : <>{children}</>}
        </>
    );
}
