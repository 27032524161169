import React from 'react';

const TheraphyDashboard = React.lazy(() => import('../containers/TheraphyDashboard/TheraphyDashboardContainer'));
const Login = React.lazy(() => import('../containers/LoginV2/LoginContainerV2'));
const LoginV2 = React.lazy(() => import('../containers/LoginV2/LoginContainerV2'));
const NotFound = React.lazy(() => import('../containers/NotFound/NotFoundContainer'));
const ClientDashboard = React.lazy(() => import('../containers/ClientDashboard/ClientDashboardContainer'));
const TherapistDashboard = React.lazy(() => import('../containers/TherapistDashboard/TherapistDashboardContainer'));
const SignUp = React.lazy(() => import('../containers/SignUp/SignUpContainer'));
const MFAHelp = React.lazy(() => import('../containers/SignUp/MFAHelpScreen'));
const MFAQR = React.lazy(() => import('../containers/SignUp/MFAQRScreen'));
const PracticeAdminUserLoginSession = React.lazy(() => import('../containers/PracticeAdminUserLoginSession/PracticeAdminUserLoginSessionContainer'));
const PracticeAdminUserSession = React.lazy(() => import('../containers/PracticeAdminUserSession/PracticeAdminUserSessionContainer'));
const TherapistProfileSettings = React.lazy(() => import('../containers/TherapistProfileSettings/TherapistProfileSettingsContainer'));
const SchedulerDashboard = React.lazy(() => import('../containers/SchedulerDashboard/SchedulerDashboardContainer'));
const Matching = React.lazy(() => import('../containers/Matching/MatchingContainer'));
const Settings = React.lazy(() => import('../containers/Settings/SettingsContainer'));
const ForgotPassword = React.lazy(() => import('../containers/ForgotPassword/ForgotPasswordContainer'));
const ChangePassword = React.lazy(() => import('../containers/ChangePassword/ChangePasswordContainer'));
const Main = React.lazy(() => import('../containers/Main/MainContainer'));
const IntakeReport = React.lazy(() => import('../containers/IntakeReport/IntakeReportContainer'));
const ResetPassword = React.lazy(() => import('../containers/ResetPassword/ResetPasswordContainer'));
const RequestRematch = React.lazy(() => import('../containers/ClientRequestRematch/ClientRequestRematchContainer'));
const ActivityLog = React.lazy(() => import('../containers/ActivityLog/ActivityLogContainer'));
const Terms = React.lazy(() => import('../containers/Terms/TermsContainer'));
const PrivacyPolicy = React.lazy(() => import('../containers/PrivacyPolicy/PrivacyPolicyContainer'));
const SupportTicket = React.lazy(() => import('../containers/SupportTicket/SupportTicketContainer'));
const Support = React.lazy(() => import('../containers/Support/SupportContainer'));
const LoggingError = React.lazy(() => import('../containers/LoggingError/LoggingErrorContainer'));
const Set2FA = React.lazy(() => import('./../components/modules/TwoFactorQR/TwoFactorQRContainer'));
const AuthenticationRedirect = React.lazy(() => import('./../containers/AuthenticateRedirect/AuthenticateRedirect'));
const VerifyOnboarding = React.lazy(() => import("./../components/modules/selfOnboarding/VerifyOnboarding"));
const SelfScheduling = React.lazy(() => import('./../components/modules/self-scheduling/SelfSchedulingContainer'));
const BookingIntake = React.lazy(() => import('./../components/modules/BookingIntake/BookingIntakeContainer'));
const PracticeProfile = React.lazy(() => import('../latest/src/containers/TherapistProfile/Tabs/PracticeProfile'));
// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

const routes = [{
    path: '/',
    exact: true,
    name: 'Main',
    component: Main
}, {
    path: '/login',
    name: 'Login',
    component: Login
},{
    path: '/v2/login',
    name: 'LoginV2',
    component: LoginV2
}, {
    path: '/client',
    name: ClientDashboard,
    component: ClientDashboard,
    exact: true
},  {
    path: '/therapist',
    name: TherapistDashboard,
    component: TherapistDashboard
    // isExact: true
}, {
    path: '/practice/admin/user-login-session',
    name: PracticeAdminUserLoginSession,
    component: PracticeAdminUserLoginSession
}, {
    path: '/practice/admin/user-session/:id',
    name: PracticeAdminUserSession,
    component: PracticeAdminUserSession
}, {
    path: '/settings',
    component: TherapistProfileSettings,
    exact: true
}, {
    path: '/settings/user',
    component: Settings,
    exact: true
},
{
    path: '/scheduler/matching/:todoId/:userId',
    name: Matching,
    component: Matching,
    exact: true
}, {
    path: '/scheduler',
    name: SchedulerDashboard,
    component: SchedulerDashboard,
    exact: true
}, {
    path: '/sign-up/:verificationCode',
    name: SignUp,
    component: SignUp,
    exact: true
},
// {
//     path: '/sign-up/:userRole',
//     name: SignUp,
//     component: SignUp,
//     exact: true
// },
{
    path: '/mfa-help',
    name: MFAHelp,
    component: MFAHelp,
    exact: true
},
{
    path: '/practice-profile',
    name: 'practiceprofile',
    component: PracticeProfile,
},
{
    path: '/mfa-qr',
    name: MFAQR,
    component: MFAQR,
    exact: true
},
{
    path: '/forgot-password',
    name: ForgotPassword,
    component: ForgotPassword
}, {
    path: '/reset-password',
    name: ForgotPassword,
    component: ForgotPassword,
    exact: true
}, {
    path: '/change-password',
    name: ChangePassword,
    component: ChangePassword,
    exact : true,
}, {
    path: '/intake-report/:clientId',
    name: IntakeReport,
    component: IntakeReport
}, {
    path: '/reset-password/:verificationCode',
    name: ResetPassword,
    component: ResetPassword,
    exact: true
}, {
    path: '/therapy/*',
    component: TheraphyDashboard,
    name: TheraphyDashboard
}, {
    path: '/request-rematch',
    name: RequestRematch,
    component: RequestRematch
}, {
    path: '/activity-log',
    name: 'ActivityLog',
    component: ActivityLog
}, {
    path: '/termsofservice',
    name: 'Terms',
    component: Terms
}, {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
}, {
    path: '/support',
    name: 'Support',
    component: Support,
    exact: true
},
{
    path: '/support/:type',
    name: 'SupportTicket',
    component: SupportTicket,
    exact: true
}, {
    path: '/error',
    name: 'LoggingError',
    component: LoggingError
},{
    path:'/redirect',
    name:'Redicrect',
    component: AuthenticationRedirect,
    exact: true
},{
    path:'/set2FA',
    name:'set2FA',
    component:Set2FA
}, // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE// ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
{
    path : '/verify-onboarding',
    name : 'VerifyOnboarding',
    component : VerifyOnboarding,
},
{
    path : '/providers',
    name : 'SelfScheduling',
    component  : SelfScheduling
}
,
{
    path : '/meeting-info',
    name : 'BookingIntake',
    component : BookingIntake
},
{
    path: '*',
    name: NotFound,
    component: NotFound
}
];

export default routes;
