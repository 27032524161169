import { toType, getArrayDiff, getSliderGradient } from './helpers';
import Storage from './Storage';
import $ from 'jquery';

export const customRadioGroupProps = ['color',
    'bg',
    'borderColor',
    'image',
    'nodes',
    'availableType'];

export const widgetSlider = (Survey, noUiSlider) => {
    return {
        name: 'customslider',
        title: 'noUiSlider',
        iconName: 'icon-nouislider',
        widgetIsLoaded: function() {
            return typeof noUiSlider != 'undefined';
        },
        isFit: function(question) {
            return question.getType() === 'customslider';
        },
        htmlTemplate: '<div class="custom-slider"><div></div></div>',
        activatedByChanged: function() {
            Survey.JsonObject.metaData.addClass('customslider', [], null, 'empty');
            Survey.JsonObject.metaData.addProperties('customslider', [{
                name: 'step:number',
                category: 'slider',
                categoryIndex: 1,
                default: 1
            }, {
                name: 'rangeMin:number',
                category: 'slider',
                default: 0
            }, {
                name: 'rangeMax:number',
                category: 'slider',
                default: 100
            }, {
                name: 'pipsMode',
                category: 'slider',
                default: 'positions'
            }, {
                name: 'pipsValues:itemvalues',
                category: 'slider',
                default: [0, 25, 50, 75, 100]
            }, {
                name: 'pipsText:itemvalues',
                category: 'slider',
                default: [0, 25, 50, 75, 100]
            }, {
                name: 'pipsDensity:number',
                category: 'slider',
                default: 5
            }, {
                name: 'direction: string',
                category: 'slider',
                default: 'ltr'
            }, {
                name: 'tooltips: boolean',
                category: 'slider',
                default: true
            }, {
                name: 'leftLabel: string',
                category: 'slider',
                default: ''
            }, {
                name: 'rightLabel: string',
                category: 'slider',
                default: ''
            }
            ]);
        },
        afterRender: function(question, el) {
            const colors = question.subType ? question.subType.split('_').length === 2 ? question.subType.split('_') : ['blue', 'pink'] : ['blue', 'pink'];

            const slider = noUiSlider.create(el, {
                start: question.value || (question.rangeMin + question.rangeMax) / 2,
                connect: [true, false],
                step: question.step,
                tooltips: question.tooltips,
                pips: {
                    mode: question.pipsMode || 'positions',
                    values: question.pipsValues.map(function(pVal) {
                        let pipValue = pVal;

                        if (pVal.value !== undefined) {
                            pipValue = pVal.value;
                        }

                        return parseInt(pipValue);
                    }),
                    density: question.pipsDensity || 5,
                    format: {
                        to: function(pVal) {
                            let pipText = pVal;

                            question.pipsText.forEach(function(el) {
                                if (el.text !== undefined && pVal === el.value) {
                                    pipText = el.text;
                                }
                            });

                            return pipText;
                        }
                    }
                },
                range: { min: question.rangeMin, max: question.rangeMax },
                direction: question.direction
            });

            // Hide default fill
            // const defaultFill = document.getElementsByClassName('noUi-connect')[0];

            // Create new fill
            const node = document.createElement('div');

            // document.querySelectorAll('.noUi-connect')[0].style.width = 0;

            node.classList.add('noUi-connect');
            node.classList.add('noUi-connect-2');
            node.style.width = 0;
            node.style.position = 'absolute';
            node.style.display = 'none';
            const uiBase = el.querySelector('.noUi-base');

            uiBase.style.marginTop = '10px !important';

            el.style.display = 'flex';

            if (uiBase) {
                uiBase.classList.add('custom-ui-base');
                uiBase.prepend(node);
                const base = document.createElement('div');

                base.classList.add('noUi-connect-base');
                uiBase.prepend(base);

                if (uiBase.parentElement) {
                    if (question.value === 0) {
                        uiBase.parentElement.classList.add('no-value');
                    } else {
                        uiBase.parentElement.classList.remove('no-value');
                    }
                }
            }

            const horizontal = el.querySelector('.noUi-pips.noUi-pips-horizontal');

            if (horizontal) {
                const pips = [];

                horizontal.querySelectorAll('.noUi-value.noUi-value-horizontal.noUi-value-large').forEach((el) => {
                    if (isNaN(parseInt(el.innerHTML)) || parseInt(el.innerHTML) === -100 || parseInt(el.innerHTML) === 100) {
                        pips.push(el.innerHTML);
                    }
                });

                if (pips.length === 2 && el) {
                    pips.map((p, i) => {
                        const pip = document.createElement('div');

                        pip.innerHTML = p;

                        if (i === 0) {
                            el.prepend(pip);
                        } else {
                            el.appendChild(pip);
                        }
                    });
                }

                horizontal.style.display = 'none';
            }

            const handles = el.querySelector('.noUi-handle.noUi-handle-lower');

            const value = question.value || (question.rangeMin + question.rangeMax) / 2;

            if (handles) {
                handles.classList.add(question.subType || 'blue_pink');
                ['touchstart', 'mousedown', 'touchmove', 'click'].map(e => {
                    handles.addEventListener(e, function() {
                        this.style.transform = 'scale(1.3)';
                        this.style.cursor = 'grabbing';

                        if (this.classList.contains('handle-white')) {
                            this.style.backgroundImage = 'linear-gradient(270deg, #ffaecb 0%, #b1b6ff 100%) !important;';
                        }
                    });
                });
                ['touchend', 'mouseup', 'blur', 'mouseleave'].map(e => {
                    document.addEventListener(e, function() {
                        handles.style.transform = 'scale(1)';
                        handles.style.cursor = 'grab';
                    });
                });

                ['touchend', 'mouseup', 'blur'].map(e => {
                    handles.addEventListener(e, function() {
                        setTimeout(() => {
                            this.style.transform = 'scale(1)';
                            this.style.cursor = 'grab';
                        }, 0);
                    });
                });

                if (question.value === undefined) {
                    handles.classList.add('handle-white');
                } else {
                    handles.classList.remove('handle-white');
                    handles.classList.remove(question.subType || 'blue_pink');

                    if (question.value === 0) {
                        handles.classList.add(question.subType || 'blue_pink');
                    }
                }

                if (parseInt(value) > 0) {
                    handles.style.backgroundImage = getSliderGradient(colors[1]?.toLowerCase(), Math.floor(value) - 1, 0);
                    node.style.backgroundImage = getSliderGradient(colors[1]?.toLowerCase(), Math.floor(value) - 1, 0, true);

                    if (colors[1]?.toLowerCase() === 'grey') {
                        handles.classList.add('small-handle');
                    } else {
                        handles.classList.remove('small-handle');
                    }
                } else {
                    node.style.backgroundImage = '';
                }

                if (parseInt(value) < 0) {
                    handles.style.backgroundImage = getSliderGradient(colors[0]?.toLowerCase(), Math.abs(value) - 1, 180);
                    node.style.backgroundImage = getSliderGradient(colors[0]?.toLowerCase(), Math.abs(value) - 1, 0, true);

                    if (colors[0]?.toLowerCase() === 'grey') {
                        handles.classList.add('small-handle');
                    } else {
                        handles.classList.remove('small-handle');
                    }
                } else {
                    node.style.backgroundImage = '';
                }

                if (question.value === 0) {
                    handles.classList.remove('handle-white');
                }
            }

            slider.on('update', (values, handle, unencoded, tap, positions) => {
                const pos = positions[0];
                const value = parseFloat(values[0]);
                const connect = el.querySelectorAll('.noUi-connect:not(.noUi-connect-2)')[0];
                const connect2 = el.querySelector('.noUi-connect-2');

                if (uiBase.parentElement) {
                    if (value === 0) {
                        uiBase.parentElement.classList.add('no-value');
                    } else {
                        uiBase.parentElement.classList.remove('no-value');
                    }
                }

                if (handles) {
                    if (question.value === undefined) {
                        handles.classList.add('noUi-handle-no-value');
                        handles.classList.add('handle-white');
                    } else {
                        handles.classList.remove('handle-white');
                        handles.classList.remove('noUi-handle-no-value');

                        if (question.value === 0) {
                            handles.classList.add(question.subType || 'blue_pink');
                        }
                    }

                    if (connect) {
                        if (value > 0) {
                            setTimeout(() => {
                                handles.style.backgroundImage = getSliderGradient(colors[1]?.toLowerCase(), Math.floor(value) - 1, 0);
                                connect.style.backgroundImage = getSliderGradient(colors[1]?.toLowerCase(), Math.floor(value) - 1, 0, true);
                            }, 0)
                            ;

                            if (colors[1]?.toLowerCase() === 'grey') {
                                handles.classList.add('small-handle');
                            } else {
                                handles.classList.remove('small-handle');
                            }

                            connect.style.display = 'block';
                            connect.style.transform = 'scale(1) !important';
                            connect.style.width = `${pos - 50}%`;
                        } else {
                            connect.style.backgroundImage = '';
                            connect.style.display = 'none';
                        }
                    }

                    if (connect2) {
                        if (value < 0) {
                            handles.style.backgroundImage = getSliderGradient(colors[0]?.toLowerCase(), Math.abs(value) - 1, 180);
                            connect2.style.backgroundImage = getSliderGradient(colors[0]?.toLowerCase(), Math.abs(value) - 1, 0, true);

                            if (colors[0]?.toLowerCase() === 'grey') {
                                handles.classList.add('small-handle');
                            } else {
                                handles.classList.remove('small-handle');
                            }

                            connect2.style.display = 'block';
                            connect2.style.left = '50%';
                            connect2.style.transform = 'rotate(180deg)';
                            connect2.style.top = '3px';
                            connect2.style.width = `${50 - pos}%`;
                        } else {
                            connect2.style.backgroundImage = '';
                            connect2.style.display = 'none';
                        }
                    }
                }

                // if (pos >= 50) {
                //     node.style.left = '50%';
                //     node.style.right = 'auto';
                //     node.style.width = (pos - 50) + '%';
                // } else {
                //     node.style.left = 'auto';
                //     node.style.right = '50%';
                //     node.style.width = (50 - pos) + '%';
                // }
            });

            slider.on('change', function() {
                question.value = Number(slider.get());
                const result = Storage.getItem('intakeResult');

                Storage.setItem('intakeResult', { ...result, [question.name]: question.value });
                const elScroll = $(`div[name="${question.scrollTo}"] .noUi-target`);

                const isSame = Storage.getItem('scroll') === question.scrollTo;
                const lastScroll = Storage.getItem('scrollTop');
                const scrolled = window.scrolled || [];

                if (elScroll && !scrolled.includes(question.scrollTo)) {
                    const el = question.parent.elements.filter(e => e.name === question.scrollTo);

                    if (el.length > 0 && el[0].isVisible) {
                        if (!isSame || parseInt(lastScroll) !== document.body.scrollTop) {
                            $('body').animate({
                                scrollTop: elScroll[0].offsetTop - 150
                            }, 600, () => {
                                Storage.setItem('scroll', question.scrollTo);
                                Storage.setItem('scrollTop', document.body.scrollTop);
                                window.scrolled = window.scrolled ? [...window.scrolled, question.scrollTo] : [question.scrollTo];
                            });
                        }
                    }
                }
            });

            const updateValueHandler = function() {
                slider.set(question.value);
            };

            if (question.isReadOnly) {
                el.setAttribute('disabled', true);
            }

            updateValueHandler();
            question.noUiSlider = slider;
            question.valueChangedCallback = updateValueHandler;

            question.readOnlyChangedCallback = function() {
                if (question.isReadOnly) {
                    el.setAttribute('disabled', true);
                } else {
                    el.removeAttribute('disabled');
                }
            };
        },
        willUnmount: function(question) {
            if (question.noUiSlider) {
                question.noUiSlider.destroy();
                question.noUiSlider = null;
            }

            question.readOnlyChangedCallback = null;
        },
        pdfRender: function(_, options) {
            if (options.question.getType() === 'customslider') {
                const point = options.module.SurveyHelper.createPoint(
                    options.module.SurveyHelper.mergeRects.apply(null, options.bricks)
                );

                point.xLeft += options.controller.unitWidth;
                point.yTop += options.controller.unitHeight * options.module.FlatQuestion.CONTENT_GAP_VERT_SCALE;

                const rect = options.module.SurveyHelper.createTextFieldRect(point, options.controller);
                const textboxBrick = new options.module.TextFieldBrick(
                    options.question,
                    options.controller,
                    rect,
                    true,
                    options.question.id,
                    options.question.value || options.question.defaultValue || '',
                    '',
                    options.question.isReadOnly,
                    false,
                    'text'
                );

                options.bricks.push(textboxBrick);
            }
        }
    };
};

export const specialiseRender = (result) => {
    const specialise = result.currentPage.elements.filter((el) => el.name === 'therapy_specialise');

    if (specialise.length > 0) {
        // result.pageNextText = 'GET STARTED';

        if (!result.data.therapy_specialise) {
            document.querySelector('input.sv-btn.sv-footer__next-btn').setAttribute('disabled', true);
        }

        const specialiseQuestion = document.getElementsByName('therapy_specialise')[0];

        try {
            document.querySelector('.sv-page.sv-body__page').classList.add('therapy_specialise-page');
            document.querySelector('.sv-footer.sv-body__footer.sv-clearfix').classList.add('therapy_specialise-footer');
            document.querySelector('h4.sv-title.sv-page__title').style.fontSize = '24px';
            document.querySelector('h4.sv-title.sv-page__title').style.lineHeight = '32px';
            document.querySelector('h4.sv-title.sv-page__title').style.fontWeight = '800';
            document.querySelector('h4.sv-title.sv-page__title').style.marginTop = '2.1em';
        } catch (error) {}

        specialiseQuestion.querySelectorAll('.sv-selectbase__item').forEach((el, i) => {
            const { color, bg } = specialise[0].choices[i];
            const divImg = document.createElement('div');

            divImg.classList.add('custom-radio-img-container');
            // const img = document.createElement('img');

            // img.src = image;
            divImg.innerHTML = `<i class="fa fa-medkit custom-radio-img fa-3x" style="color:${el.classList.contains('sv-radio--checked') ? bg : color}"/>`;
            el.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('page-with-custom-radio');

            el.parentElement.parentElement.parentElement.parentElement.classList.add('custom-radio-container');

            el.parentElement.classList.add('custom-radio');
            el.setAttribute('data-color', color);
            el.setAttribute('data-bg', bg);
            divImg.style.background = el.classList.contains('sv-radio--checked') ? color : bg;

            if (el.classList.contains('sv-radio--checked')) {
                el.querySelector('span.sv-item__control-label').style.color = '#fff';
                el.querySelector('span.sv-item__control-label').style.background = color;
                el.querySelector('span.sv-item__decorator.sv-radio__decorator').style.borderColor = color;
                el.querySelector('span.check').style.borderBottomColor = color;
                el.querySelector('span.check').style.borderRightColor = color;
                if (el.querySelector('.custom-radio-img-container')) el.querySelector('.custom-radio-img-container').style.background = color;
                if (el.querySelector('.custom-radio-img')) el.querySelector('.custom-radio-img').style.color = '#fff';
            } else {
                el.querySelector('span.sv-item__control-label').style.color = color;
                el.querySelector('span.sv-item__control-label').style.background = bg;
                if (el.querySelector('.custom-radio-img-container')) el.querySelector('.custom-radio-img-container').style.background = bg;
                if (el.querySelector('.custom-radio-img')) el.querySelector('.custom-radio-img').style.color = color;
            }

            el.querySelectorAll('.sv-selectbase__label').forEach(label => {
                const decorator = label.querySelector('.sv-radio__decorator');

                decorator.classList.add('d-flex');
                decorator.classList.add('justify-content-center');
                decorator.classList.add('align-items-center');

                if (label.querySelector('svg')) {
                    label.querySelector('svg').remove();
                }

                if (!decorator.querySelector('.check')) {
                    const check = label.querySelector('.check');

                    decorator.appendChild(check);
                }

                if (!label.querySelector('.custom-radio-img-container')) {
                    divImg.prepend(decorator);
                    label.prepend(divImg);
                }
            });
        });
    } else {
        try {
            document.querySelector('.sv-footer.sv-body__footer.sv-clearfix').classList.remove('therapy_specialise-footer');
            document.querySelector('.sv-page.sv-body__page').classList.remove('therapy_specialise-page');
        } catch (error) {

        }

        const pageWithCustomRadio = document.querySelector('.page-with-custom-radio');

        if (pageWithCustomRadio) {
            pageWithCustomRadio.classList.remove('page-with-custom-radio');
        }
    }
};

export const customSliderRender = (result) => {
    const currentPage = JSON.stringify(result.currentPage); // need to stringify to show all props
    const hasCustomSlider = JSON.parse(currentPage).elements.filter(el => el.type === 'customslider').length > 0;
    // const hasHelpful = JSON.parse(currentPage).elements.filter(el => el.title === 'helpful_not_helpful').length > 0;

    const qContainer = document.querySelector('.session-questionnaire-container');

    if (qContainer) {
        qContainer.classList.remove('custom-slider-question');
        qContainer.classList.remove('custom-slider-show-tile-desc');
    }

    if (hasCustomSlider) {
        const sliders = document.querySelectorAll('.custom-slider');

        if (qContainer) {
            if (result.currentPage.title.includes('your values')) {
                qContainer.classList.add('custom-slider-question-values');
                setTimeout(() => {
                    const description = document.querySelector('#questionnaire-description');

                    if (description) {
                        const span = description.querySelector('span');

                        if (span && span.innerText.includes('each')) {
                            span.innerHTML = `${span.innerText.split('each')[0]}<span class="all_italic">each</span>${span.innerText.split('each')[1]}`;
                        } else if (result.currentPage.id === 'about-your-values-therapist' && description.querySelector('.border-title')) {
                            const child = document.createElement('div');

                            child.classList.add('therapist-values-description');

                            child.innerHTML = 'How important are <p class="all_italic">each</p> of these values to you?';

                            if (!description.querySelector('.therapist-values-description')) {
                                description.appendChild(child);
                            }
                        }
                    }
                });
            } else {
                qContainer.classList.remove('custom-slider-question-values');
                qContainer.querySelector('.therapist-values-description')?.remove();
            }

            if (result.currentPage.title.includes('personality and preferences.') || result.currentPage.title.includes('your therapist preferences.') || result.currentPage.title.includes('personality and preferences.') || result.currentPage.title.includes('you’ve been doing') || result.currentPage.title.includes('therapeutic style.')) {
                if (result.currentPage.title === 'Tell us how you’ve been doing') {
                    qContainer.classList.add('custom-slider-question-doing');
                    qContainer.classList.remove('custom-slider-question-values-preferences');
                    qContainer.classList.remove('custom-slider-question-therapist-preferences');
                }
            } else if (result.currentPage.title === 'Tell us how you’ve been doing') {
                qContainer.classList.add('custom-slider-question-doing');
                qContainer.classList.remove('custom-slider-question-values-preferences');
                qContainer.classList.remove('custom-slider-question-therapist-preferences');
            } else {
                qContainer.classList.remove('custom-slider-question-values-preferences');
                qContainer.classList.remove('custom-slider-question-doing');
                qContainer.classList.remove('custom-slider-question-therapist-preferences');
                qContainer.classList.remove('custom-slider-question-values-personality');
                const firstRow = document.querySelector('.sv-row.sv-clearfix');

                if (firstRow) {
                    firstRow.classList.remove('firstRow');
                }
            }
        }

        try {
            document.querySelector('.sv-title.sv-question__title').classList.add('custom-slider-title');
            document.querySelector('.sv-footer.sv-body__footer.sv-clearfix').classList.add('custom-slider-footer');
        } catch (error) {

        }

        sliders.forEach(slider => {
            slider.parentElement.classList.add('custom-slider-container');
            slider.parentElement.parentElement.parentElement.classList.add('custom-slider-content');
        });
    } else {
        try {
            document.querySelector('.sv-footer.sv-body__footer.sv-clearfix').classList.remove('custom-slider-footer');
            document.querySelector('.sv-title.sv-question__title').classList.remove('custom-slider-title');
        } catch {

        }
    }

    // if (hasHelpful) {
    //     const container = document.querySelector('div[name="helpful_not_helpful"]');

    //     if (container) {
    //         const header = container.querySelector('.sv-question__header');

    //     }
    // }
};

export const specialiseValueChanged = () => {
    const selectedCustomRadios = document.querySelectorAll('.custom-radio-container .sv-radio');
    // const nextBtn = document.querySelector('input.sv-btn.sv-footer__next-btn');

    // if (nextBtn) {
    //     nextBtn.removeAttribute('disabled');
    // }

    setTimeout(() => {
        selectedCustomRadios.forEach((el) => {
            const color = el.getAttribute('data-color');
            const bg = el.getAttribute('data-bg');

            if (el.classList.contains('sv-radio--checked')) {
                el.querySelector('span.sv-item__control-label').style.color = '#fff';
                el.querySelector('span.sv-item__control-label').style.background = color;
                el.querySelector('span.sv-item__decorator.sv-radio__decorator').style.borderColor = color;
                el.querySelector('span.check').style.borderBottomColor = color;
                el.querySelector('span.check').style.borderRightColor = color;
                el.querySelector('.custom-radio-img-container').style.background = color;
                el.querySelector('.custom-radio-img').style.color = '#fff';
            } else {
                el.querySelector('span.sv-item__control-label').style.color = color;
                el.querySelector('span.sv-item__control-label').style.background = bg;
                el.querySelector('.custom-radio-img-container').style.background = bg;
                el.querySelector('.custom-radio-img').style.color = color;
            }
        });
    }, 0);
};

export const helpfulRender = (result) => {
    const container = document.querySelector('.session-questionnaire-container');

    if (result.currentPage.elements.filter(el => el.name === 'helpful_not_helpful').length > 0) {
        const rowElement = document.querySelector('.sv-row.sv-clearfix');

        if (container) {
            container.classList.add('helpful_not_helpful-container');
        }

        if (rowElement) {
            if (rowElement.querySelector('.sv-question.sv-row__question')) {
                rowElement.querySelector('.sv-question.sv-row__question').style.minHeight = '170px';
            }

            rowElement.style.paddingBottom = 0;
        }

        if (document.querySelectorAll('.sv-title.sv-question__title')[0]) {
            document.querySelectorAll('.sv-title.sv-question__title')[0].style.marginTop = '.25em';
        }

        document.querySelectorAll('.sv-title.sv-question__title').forEach((element) => {
            element.style.fontSize = '20px';
            element.style.lineHeight = '27px';
            element.style.marginBottom = '.8em';
            element.style.paddingTop = '0';
        });
    } else {
        if (container) {
            container.classList.remove('helpful_not_helpful-container');
        }
    }
};

export const bestDescribe = (result) => {
    const row = document.querySelector('.sv-row.sv-clearfix');
    const title = document.querySelector('h4.sv-title.sv-page__title');
    const container = document.querySelector('.session-questionnaire-container');

    if (result.currentPage.name === 'describe') {
        if (row && title) {
            row.classList.add('best-row');
            title.classList.add('best-title');
        }

        if (container) {
            container.classList.add('best-describe-container');
        }
    } else {
        if (row && title) {
            row.classList.remove('best-row');
            title.classList.remove('best-title');
        }

        if (container) {
            container.classList.remove('best-describe-container');
        }
    }
};

export const concernsRender = (result) => {
    if (result.currentPage.name === 'concern-slider') {
        document.querySelector('.session-questionnaire-container').classList.add('concern-container');
        document.querySelector('.sv-page.sv-body__page').classList.add('concern-page');
    } else {
        document.querySelector('.session-questionnaire-container').classList.remove('concern-container');
        document.querySelector('.sv-page.sv-body__page').classList.remove('concern-page');
    }
};

export const concernListRender = (result) => {
    if (result.currentPage.name === 'concern-list') {
        document.querySelector('.sv-page.sv-body__page').classList.add('concern-page');
    } else {
        document.querySelector('.sv-page.sv-body__page').classList.remove('concern-page');
    }
};

export const concernAddFooter = (result) => {
    const footer = document.querySelector('.sv-footer.sv-body__footer.sv-clearfix');

    if (result.currentPage.name === 'concern-list') {
        if (footer) {
            footer.classList.add('concern-footer');
        }
    } else {
        if (footer) {
            footer.classList.remove('concern-footer');
        }
    }
};

export const disabledBtn = (result) => {
    const nextBtn = document.querySelector('input.sv-btn.sv-footer__next-btn');

    if (nextBtn) {
        nextBtn.setAttribute('disabled', true);

        if (result.data[result.currentPage.name] !== undefined) {
            nextBtn.removeAttribute('disabled');
        } else {
            if (result.currentPage.elements && result.currentPage.elements.length > 0) {
                const elem = result.currentPage.elements.filter(e => result.data[e.name])[0];

                if (elem) {
                    nextBtn.removeAttribute('disabled');
                } else {
                    nextBtn.setAttribute('disabled', true);
                }

                if (result.currentPage.name === 'describe') {
                    result.currentPage.elements.forEach(e => {
                        if (e.customWidgetValue && e.customWidgetValue.name === 'boolean') {
                            if (result.data[e.name] !== undefined && result.data[e.name] === false) {
                                nextBtn.removeAttribute('disabled');
                            } else {
                                result.currentPage.elements.forEach(i => {
                                    if (!i.customWidgitValue) {
                                        if (result.data[i.name]) {
                                            nextBtn.removeAttribute('disabled');
                                        } else {
                                            nextBtn.setAttribute('disabled', true);
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            } else {
                nextBtn.setAttribute('disabled', true);
            }
        }
    }
}
;

export const checkToDecorators = () => {
    const labels = document.querySelectorAll('.sv-selectbase__label');

    labels.forEach(el => {
        const decorator = el.querySelector('.sv-item__decorator');
        const check = el.querySelector('.check');

        if (!decorator.querySelector('.check') && check) {
            decorator.appendChild(check);
        }
    });
};

export const bipolarslider = (Survey) => {
    return {
    // the widget name. It should be unique and written in lowcase.
        name: 'bipolarslider',
        // the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
        title: 'Bipolar slider',
        // the name of the icon on the toolbox. We will leave it empty to use the standard one
        iconName: '',
        // SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
        isFit: function(question) {
        // we return true if the type of question is textwithbutton
            return question.getType() === 'bipolarslider';
        // the following code will activate the widget for a text question with inputType equals to date
        // return question.getType() === 'text' && question.inputType === "date";
        },
        // Use this function to create a new class or add new properties or remove unneeded properties from your widget
        // activatedBy tells how your widget has been activated by: property, type or customType
        // property - it means that it will activated if a property of the existing question type is set to particular value, for example inputType = "date"
        // type - you are changing the behaviour of entire question type. For example render radiogroup question differently, have a fancy radio buttons
        // customType - you are creating a new type, like in our example "textwithbutton"
        activatedByChanged: function(activatedBy) {
        // we do not need to check acticatedBy parameter, since we will use our widget for customType only
        // We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
            Survey.JsonObject.metaData.addClass('bipolarslider', [], null, 'text');

            // Add new property(s)
            // For more information go to https://surveyjs.io/Examples/Builder/?id=addproperties#content-docs
            Survey.JsonObject.metaData.addProperties('bipolarslider', [
                { name: 'leftText', default: 'Text to the left' }
            ]);
            Survey.JsonObject.metaData.addProperties('bipolarslider', [
                { name: 'rightText', default: 'Text to the right' }
            ]);

            // Defines the min value for the range slider
            Survey.JsonObject.metaData.addProperties('bipolarslider', [
                { name: 'min', default: 0 }
            ]);

            // Defines the max value for the range slider
            Survey.JsonObject.metaData.addProperties('bipolarslider', [
                { name: 'max', default: 100 }
            ]);

            // Defines the steps for the range slider
            Survey.JsonObject.metaData.addProperties('bipolarslider', [
                { name: 'step', default: 1 }
            ]);
        },
        // If you want to use the default question rendering then set this property to true. We do not need any default rendering, we will use our htmlTemplate
        isDefaultRender: false,
        // You should use it if your set the isDefaultRender to false
        // htmlTemplate: "<div><input /><button></button></div>",
        htmlTemplate: "<div class='sjs_bipolarslider_container'><span class='sjs_bipolarslider_left_text sjs_bipolarslider_text'></span><input class='sjs_bipolarslider_input' type='range'/><span class='sjs_bipolarslider_right_text sjs_bipolarslider_text'></span></div>",
        // The main function, rendering and two-way binding
        afterRender: function(question, el) {
            const slider = el.getElementsByTagName('input')[0];

            // Set the minimum and maximum of the slider
            slider.setAttribute('min', question.min);
            slider.setAttribute('max', question.max);
            slider.setAttribute('step', question.step);

            // Set the caption for left and right
            const left = el.getElementsByTagName('span')[0];

            left.innerHTML = question.leftText;
            const right = el.getElementsByTagName('span')[1];

            right.innerHTML = question.rightText;

            // set the changed value into question value
            slider.onchange = function() {
                question.value = slider.value;
            };

            const onValueChangedCallback = function() {
                slider.value = question.value ? question.value : '';
            };

            const onReadOnlyChangedCallback = function() {
                if (question.isReadOnly) {
                    slider.setAttribute('disabled', 'disabled');
                } else {
                    slider.removeAttribute('disabled');
                }
            };

            question.readOnlyChangedCallback = onReadOnlyChangedCallback;
            question.valueChangedCallback = onValueChangedCallback;

            // set initial value
            onValueChangedCallback();

            // make elements disabled if needed
            onReadOnlyChangedCallback();
        },
        // Use it to destroy the widget. It is typically needed by jQuery widgets
        willUnmount: function(question, el) {
        }
    };
};

export const getElementType = (key:string, elValue?:any) => {
    const pages = Storage.getItem('pages') || [];
    let type = '';
    let value = '';

    pages.map(page => page.elements.map(e => {
        if (e.name === key) {
            type = e.type;

            try {
                if (e.choices && e.choices.length > 0 && e.type === 'radiogroup' && typeof elValue !== 'boolean') {
                    value = e.choices[parseInt(elValue) - 1].text || null;
                }
            } catch (error) {
            }
        }
    }));

    return {
        type,
        value
    };
};

const getEventTitle = (key: string) => {
    let title = '';
    const lastInteraction = Storage.getItem('lastInteraction');
    const interactions = Storage.getItem('interactions') || {};

    if (Object.keys(interactions).length === 0) {
        title = 'PAGE_LOAD';
    } else if (lastInteraction === key) {
        title = 'VALUE_SET';
    } else if (lastInteraction !== key) {
        title = 'SELECTION_MOVED_FROM_QUESTION';
    }

    return title;
};

export const getInteractionData = (base:Object, target:Object) => {
    const interactions = Storage.getItem('interactions') || {};
    let interaction = {
        time: new Date().getTime()
    };

    Object.keys(target).map(b => {
        // eslint-disable-next-line default-case
        switch (toType(base[b])) {
            case 'string':
            case 'boolean':
            case 'number':
                if (target[b] !== base[b]) {
                    const { type, value } = getElementType(b, target[b]);

                    interaction = {
                        ...interaction,
                        event: getEventTitle(b),
                        label: b,
                        type,
                        value: value || target[b]
                    };
                }

                break;

            case 'array':
                if (getArrayDiff(target[b], base[b]).length > 0) {
                    const { type } = getElementType(b, target[b]);

                    if (target[b] !== base[b]) {
                        interaction = {
                            ...interaction,
                            event: getEventTitle(b),
                            label: b,
                            type,
                            value: target[b]
                        };
                    }
                }

                break;

            case 'object':
                if (JSON.stringify(target[b]) !== JSON.stringify(base[b])) {
                    const { type } = getElementType(b, target[b]);

                    interaction = {
                        ...interaction,
                        event: getEventTitle(b),
                        label: b,
                        type,
                        value: target[b]
                    };
                }
        }
    });

    if (interaction.label) {
        if (interactions[interaction.label]) {
            interactions[interaction.label].push(interaction);
        } else {
            interactions[interaction.label] = [interaction];
        }

        Storage.setItem('interactions', { ...interactions });
        Storage.setItem('lastInteraction', interaction.label);
    }

    return interactions;
};

export const handleGetResult = (result:Object) => {
    const others = { ...result.data.others, ...(Storage.getItem('others') || {}) };

    if (result.data.helpful_not_helpful && result.data.helpful_not_helpful > 0) {
        delete others.not_helpful;
    } else {
        delete others.helpful;
    }

    Object.keys(others).forEach(other => {
        Object.keys(others[other]).forEach(o => {
            if (others[other][o] === false || others[other][o] === 'false') {
                delete others[other][o];
            }
        });
    });

    return {
        ...result.data,
        others
    };
};

export const disableNextAndCompleteBtn = () => {
    const completeBtn = document.querySelector('input.sv-btn.sv-footer__complete-btn');
    const nextBtn = document.getElementById('org_next');

    if (completeBtn) {
        if (!completeBtn.getAttribute('disabled')) {
            completeBtn.setAttribute('disabled', 'true');
        } else {
            completeBtn.removeAttribute('disabled');
        }
    }

    if (nextBtn) {
        if (!nextBtn.getAttribute('disabled')) {
            nextBtn.classList.add('org_next_updating');
            nextBtn.setAttribute('disabled', 'true');
        } else {
            nextBtn.classList.remove('org_next_updating');
            nextBtn.removeAttribute('disabled');
        }
    }
};

export const enableNextAndCompleteBtn = () => {
    const completeBtn = document.querySelector('input.sv-btn.sv-footer__complete-btn');
    const nextBtn = document.getElementById('org_next');

    if (completeBtn) completeBtn.removeAttribute('disabled');

    if (nextBtn) {
        nextBtn.classList.remove('org_next_updating');
        nextBtn.removeAttribute('disabled');
    }
};

export const titleRender = () => {
    const title = document.querySelector('h4.sv-title.sv-page__title');
    const desc = document.querySelector('.sv-description.sv-page__description');

    if (title) title.id = 'questionnaire-title';

    if (desc) {
        desc.id = 'questionnaire-description';
    }
};

export const onValidated = (question:Object) => {
    const finishBtn = document.querySelector('input.sv-btn.sv-footer__complete-btn');
    const nextBtn = document.querySelector('input.sv-btn.sv-footer__next-btn');

    if (hasRequired(question)) {
        if (finishBtn) finishBtn.setAttribute('disabled', 'true');
        if (nextBtn) nextBtn.setAttribute('disabled', 'true');
    } else {
        if (window.location.pathname.toLowerCase().includes('checkin') || window.location.pathname.toLowerCase().includes('checkout')) {
            const interval = setInterval(() => {
                const result = Storage.getItem('intakeResult');

                if (Object.keys(result).length > 0) {
                    clearInterval(interval);
                    if (finishBtn && finishBtn.value === 'Please wait') finishBtn.value = 'Continue';
                    if (nextBtn && nextBtn.value === 'Please wait') nextBtn.value = 'Continue';
                    if (finishBtn) finishBtn.removeAttribute('disabled');
                    if (nextBtn) nextBtn.removeAttribute('disabled');
                } else {
                    if (finishBtn && finishBtn.value === 'Continue') finishBtn.value = 'Please wait';
                    if (nextBtn && nextBtn.value === 'Continue') nextBtn.value = 'Please wait';
                }
            }, 1000);
        } else {
            if (nextBtn) nextBtn.removeAttribute('disabled');
            if (finishBtn && isAnsweredAll(question)) finishBtn.removeAttribute('disabled');
        }
    }
};

export const onAddBorder = (result) => {
    const questionnaireContainer = document.querySelector('.session-questionnaire-container');

    if (questionnaireContainer) {
        questionnaireContainer.id = result.currentPage.id;
        const title = questionnaireContainer.querySelector('#questionnaire-title') || questionnaireContainer.querySelector('.sv-question__title');
        const desc = questionnaireContainer.querySelector('#questionnaire-description') || questionnaireContainer.querySelector('.sv-question__description');

        if (title) {
            const span = title.querySelector('span');

            if (span) span.innerText = result.currentPage.title;

            if (result.currentPage.borderedTitle) {
                if (title && !title.querySelector('hr')) {
                    title.innerHTML += '<hr class="border-title"/>';
                }
            } else {
                const hr = document.querySelector('.border-title');

                if (hr) hr.remove();
            }
        }

        if (desc) {
            const span = desc.querySelector('span');

            if (span) span.innerText = result.currentPage.description;
        }

        if (desc && result.currentPage.borderedDesc) {
            if (desc && !desc.querySelector('hr')) {
                const therapistValuesDescription = desc.querySelector('.therapist-values-description');

                if (!therapistValuesDescription) {
                    desc.innerHTML += '<hr class="border-title"/>';
                } else {
                    const border = document.createElement('hr');

                    border.classList.add('border-title');

                    desc.insertBefore(border, therapistValuesDescription);
                }
            }
        }

        if (!result.currentPage.borderedTitle && !result.currentPage.borderedDesc) {
            const hr = title?.querySelector('.border-title');

            if (hr) hr.remove();
        }

        if (!result.currentPage.borderedDesc && desc) {
            const hr = desc.querySelector('.border-title');

            if (hr) hr.remove();
        }
    }
};

export const clientCheckIn = (question) => {
    if (question.currentPage.id === 'client_checkin' || question.currentPage.id === 'client_checkout') {
        const desc = document.querySelector('#questionnaire-description span');

        if (desc && !desc.querySelector('a')) {
            const text = desc.innerText;

            desc.innerHTML = text.replace('will be shared', '<span class="semi-bold_italic">will be shared</span>').replace('Why?', '<a href="#" class="btn-why">Why?</a>');
        }
    }
};

export const hasRequired = (question) => question.currentPage.elements.filter(el => el.isRequired && !el.value && el.isVisible && el.value !== false && (typeof el.value !== 'number')).length > 0 || question.currentPage.elements.filter(el => toType(el.value) === 'array' && el.value.length === 0 && el.isRequired && el.isVisible).length > 0;

export const isLastQuestionVisible = (isSlider:boolean) => {
    try {
        const questions = Storage.getItem('question') || {};

        const result = Storage.getItem('intakeResult') || {};

        const lastPage = questions?.pages[questions?.pages?.length - 1];
        const lastElem = lastPage?.elements?.length - 1;
        const lastElemName = lastPage?.elements[lastElem]?.name;
        const elem = document.querySelector(`div[name=${lastElemName}]`);

        return !!elem && (result[lastElemName] || (isSlider && elem && !elem.querySelector('.noUi-handle-no-value')));
    } catch (error) {
        return false;
    }
};

export const questionnaireHeaderSection = (user:string, isExistingClientIntake:boolean, intakeResult:Object) => {
    const concerns = ['mood', 'anxiety-related', 'relationship-to-self', 'relationship-to-other', 'couples-&-family', 'personal-growth', 'problematic-behavior', 'difficult-life', 'difficult-experience', 'identity-issues', 'performance-productivity', 'neurological-disorders', 'mood__tertiary_concern', 'anxiety-related__tertiary_concern', 'relationship-to-self__tertiary_concern', 'relationship-to-other__tertiary_concern', 'couples-&-family__tertiary_concern', 'personal-growth__tertiary_concern', 'problematic-behavior__tertiary_concern', 'difficult-life__tertiary_concern', 'difficult-experience__tertiary_concern', 'identity-issues__tertiary_concern', 'performance-productivity__tertiary_concern', 'neurological-disorders__tertiary_concern'];

    let hasValueConcerns = false;
    let concernValue = 0;

    concerns.map(c => {
        if (intakeResult) {
            concernValue += intakeResult[c]?.length || 0;
        }
    });
    hasValueConcerns = concernValue > 2;

    const hasValueTopThree = intakeResult['top-three__concern']?.length > 1;

    const isAdjustOne = (user === 'client' ? (!hasValueTopThree && hasValueConcerns) : concernValue < 8 && concernValue > 3);
    const isAdjustTwo = user === 'client' ? !hasValueTopThree && !hasValueConcerns : concernValue < 8 && concernValue > -1;

    const existingClientPage = {
        'PERSONAL INFORMATION': [1, 2, 3, 4, 5],
        CONCERNS: isAdjustOne ? [6, 7] : isAdjustTwo ? [6] : [6, 7, 8],
        IDENTITY: isAdjustOne ? [8, 9, 10, 11, 12, 13] : isAdjustTwo ? [7, 8, 9, 10, 11, 12] : [9, 10, 11, 12, 13, 14],
        PERSONALITY: isAdjustOne ? [14, 15, 16, 17] : isAdjustTwo ? [13, 14, 15, 16] : [15, 16, 17, 18]
    };
    const newClientPage = {
        'PERSONAL INFORMATION': [1, 2, 3, 4, 5, 6],
        CONCERNS: isAdjustOne ? [7, 8] : isAdjustTwo ? [7] : [7, 8, 9],
        IDENTITY: isAdjustOne ? [9, 10, 11, 12, 13, 14] : isAdjustTwo ? [8, 9, 10, 11, 12, 13] : [10, 11, 12, 13, 14, 15],
        PERSONALITY: isAdjustOne ? [15, 16, 17, 18] : isAdjustTwo ? [14, 15, 16, 17] : [16, 17, 18, 19],
        'MEETING PREFERENCES': isAdjustOne ? [19, 20, 21] : isAdjustTwo ? [18, 19, 20] : [20, 21, 22]
    };

    return {
        client:
        isExistingClientIntake ? existingClientPage : newClientPage,
        therapist: {
            'PRACTICE INFORMATION': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            EXPERTISE: isAdjustOne ? [12, 13] : isAdjustTwo ? [12] : [12, 13, 14],
            IDENTITY: isAdjustOne ? [14, 15, 16, 17, 18] : isAdjustTwo ? [13, 14, 15, 16, 17] : [15, 16, 17, 18, 19],
            PERSONALITY: isAdjustOne ? [19, 20, 21] : isAdjustTwo ? [18, 19, 20] : [20, 21, 22]
        }
    };
};

export const questionnaireHeaderPage = (user:string, type:string, isExistingClientIntake:boolean, intakeResult:Object) => {
    return questionnaireHeaderSection(user?.toLowerCase(), isExistingClientIntake, intakeResult)[user?.toLowerCase()][type][0];
};

export const questionnaireHeaderActive = (user:string, type:string, isExistingClientIntake:boolean, intakeResult:Object) => {
    const pageNo = parseInt(window.location.href ? window.location.href?.split('#')[1]?.replace('page', '') : 1);

    return questionnaireHeaderSection(user?.toLowerCase(), isExistingClientIntake, intakeResult)[user?.toLowerCase()][type].includes(pageNo) || (((type === 'MEETING PREFERENCES' && user === 'client') || (type === 'PERSONALITY' && user === 'therapist')) && pageNo > 20);
};

export const licenseRender = (result) => {
    const license = document.getElementById('licenseAndCertification');

    if (license && !license.classList.toString().includes('sv-body')) {
        if (result?.data?.fullyLicensed === 'true') {
            license.classList.add('license');
            license.classList.remove('preLicense');
        } else {
            license.classList.add('preLicense');
            license.classList.remove('license');
        }
    }
};

export const checkInCheckOutRender = (questionType: string, sessionCountCheckins:number) => {
    if (questionType?.toLowerCase() === 'checkin' || questionType?.toLowerCase() === 'checkout') {
        const page = document.querySelector('.sv-body');

        if (page && isLastQuestionVisible(true) && sessionCountCheckins === 1) {
            const node = document.createElement('span');

            node.classList.add('checkin_checkout_info');
            node.classList.add('d-block');
            node.innerText = 'Thanks for taking the time to respond. Don’t worry, we won’t ask as many questions after future sessions.';

            if (!page.querySelector('.checkin_checkout_info')) {
                const finishBtn = document.querySelector('input.sv-footer__complete-btn');

                if (finishBtn) finishBtn.classList.remove('btn-mt-0');
                page.insertBefore(node, page.children[1]);
            }
        }
    }
};

export const btnTransition = () => {
    const btns = document.querySelectorAll('.btn-primary');

    btns.forEach(btn => {
        btn.classList.add('btn-transition');
        btn.addEventListener('mouseenter', () => {
            btn.classList.add('btn-transition-mouseout');
            // setTimeout(() => {
            //     btn.classList.remove('btn-transition-mouseout');
            // }, 500);
        });
        btn.addEventListener('click', () => {
            btn.classList.remove('btn-transition-mouseout');
        });
    });
};

export const appendToPrefer = (question, Survey) => {
    const questionToDisplay = [
        'gender', 'religious', 'cultural', 'sexual'
    ];

    const alreadyAdded = [];

    questionToDisplay.map(qd => {
        if (question.data[qd]) {
            question.data[qd].map(d => {
                if (typeof d === 'object') {
                    const value = Object.values(d)[0];
                    const data = new Survey.ItemValue(value, value);

                    if (question.getQuestionByName(`prefer-${qd}`).choices.filter(c => c.value === value).length === 0) {
                        question.getQuestionByName(`prefer-${qd}`).choices.push(data);
                    }

                    alreadyAdded.push(qd);
                }
            });
        }
    });
};

export const isAnsweredAll = (survey) => {
    let isAllAnswered = true;
    const questions = survey.getAllQuestions(true);

    for (let i = 0; i < questions.length; i++) {
        const q = questions[i];

        if (q.isRequired && q.isEmpty() && i === 0) { // remove i === 0 if requiring all questions
            Storage.setItem('requiredPage', i + 1);
            isAllAnswered = false;
            break;
        }
    }

    if (isAllAnswered) Storage.removeItem('requiredPage');

    return isAllAnswered;
};

export const isNotValidHeaderHash = (page:number) => {
    return !((Storage.getItem('validHeaders') || []).includes(page));
};

export const onRemoveOtherTopSeven = (question:any) => {
    if (question && question?.data && question?.data['top-seven']) {
        const index = question?.data['top-seven']?.indexOf('other');

        if (index > -1) {
            question?.data['top-seven']?.splice(index, 1);
        }
    }

    return question?.data || {};
};

export const isValidLastPage = (isRequiredPage: boolean, btnTitle: string) => {
    const finishBtn = document.querySelector('input.sv-btn.sv-footer__complete-btn');
    const requiredPage = Storage.getItem('requiredPage');
    const sched = document.querySelector('#schedule-availability');
    const isSchedulingAvailabilityHasValue = (sched && sched.value) || !sched;

    if (finishBtn) {
        if (((isRequiredPage && requiredPage) || (!isRequiredPage && !requiredPage)) && requiredPage !== 0 && isSchedulingAvailabilityHasValue && isLastQuestionVisible()) {
            finishBtn.removeAttribute('disabled');
            if (btnTitle) finishBtn.value = btnTitle;
        }
    }
};

export const onGenerateValidHeaders = (currentPageNo:number) => {
    const validHeaders = Storage.getItem('validHeaders');
    const generated = currentPageNo !== null ? Array(currentPageNo).fill(0).map((v, i) => i + 1) : [];

    Storage.setItem('validHeaders', [...(validHeaders || []), Storage.getItem('oldHash'), ...generated]);
};

export const gotoPageByHash = (hash: any, model: Object) => {
    if (!window.location.pathname.includes('intake')) return;

    if (Storage.getItem('validChange') && parseInt(Storage.getItem('currentPageNo')) - parseInt(hash) > 1) {
        return;
    }

    Storage.removeItem('validChange');

    if (!hash || hash.indexOf('#page') !== 0) return;
    hash = hash.replace('#page', '');
    let index = parseInt(hash);

    if (index > 0) {
        index--;
        model.currentPageNo = index > model.PageCount ? model.PageCount : index;
    }

    if (index <= 0) {
        try {
            window.location.hash = 'page1';
            model.currentPageNo = 0;
        } catch (error) {

        }
    }

    const spinner = document.querySelector('.questionnaire__spinner');

    if (spinner) {
        if (Storage.getItem('isChangedHash') !== 'true') {
            Storage.setItem('isChangedHash', true);
        }
    }
};

export const initBtn = (model:Object, questionType:string) => {
    const nextBtn = document.querySelector('input.sv-btn.sv-footer__next-btn');

    const finishBtn = document.querySelector('input.sv-footer__complete-btn');

    if (nextBtn) {
        nextBtn.classList.add('btn');
        nextBtn.classList.add('btn-primary');
        nextBtn.setAttribute('value', model.currentPage.id === 'therapy-reside' ? 'Update my Current Location' : 'Continue');
    }

    if (finishBtn) {
        finishBtn.classList.add('btn');
        finishBtn.classList.add('btn-primary');
    }

    if (questionType?.toLowerCase() === 'checkin' || questionType?.toLowerCase() === 'checkout') {
        const page = document.querySelector('.sv-body');

        if (page && !isLastQuestionVisible()) {
            if (finishBtn) finishBtn.classList.add('btn-mt-0');
        } else {
            if (finishBtn) finishBtn.classList.remove('btn-mt-0');
        }
    }
};

export const updateConcernsDescription = () => {
    const description = document.querySelector('.sv-description');

    const concernsContainer = document.getElementById('client_concerns');
    const subTitle = document.getElementById('concern-description-subtitle');

    if (description && concernsContainer && !subTitle) {
        const subTitleElement = document.createElement('p');

        subTitleElement.setAttribute('id', 'concern-description-subtitle');
        const subTitleText = document.createTextNode('You\'ll be able to select your top concerns on the next page');

        subTitleElement.classList.add('typography', 'size-18', 'color-28', 'weight-light', 'custom-concerns__desc');

        subTitleElement.appendChild(subTitleText);
        description.appendChild(subTitleElement);
    }
};

export const removeConcernsDescription = () => {
    const subTitle = document.getElementById('concern-description-subtitle');

    if (subTitle) {
        subTitle.remove();
    }
};
