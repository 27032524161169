import {
    SET_USER_TO_BE_ONBOARDED,
    UPDATE_USER_TO_BE_ONBOARDED,
    RESET_ONBOARDING_STATE,
} from "../actions/onboardingActions";

interface OnboardingStateType {
    userToBeOnboarded: any;
}

const initialState = {
    userToBeOnboarded: null,
};

export default function onboardingStore(
    state: OnboardingStateType = initialState,
    action: any
): any {
    switch (action.type) {
        case RESET_ONBOARDING_STATE: {
            return { ...initialState };
        }

        case SET_USER_TO_BE_ONBOARDED: {
            const userToBeOnboarded = action.userDetails;

            return { ...state, ...{ userToBeOnboarded } };
        }

        case UPDATE_USER_TO_BE_ONBOARDED: {
            const _userToBeOnboarded = Object.assign(
                state.userToBeOnboarded || {},
                {
                    profile_pic: action.profilePic,
                }
            );

            return {
                ...state,
                ...{
                    userToBeOnboarded: _userToBeOnboarded,
                },
            };
        }

        default:
            return state;
    }
}
