// @flow
import React, { useEffect, useState } from 'react';
import LoggingError from './LoggingError';
type Props = {
    error: Object,
    resetErrorBoundary: Object,
    location?: string
}

export default function LoggingErrorContainer({ error, resetErrorBoundary, location }: Props): React$Element<any> {
    const [isMaximum, setIsMaximum] = useState(true);

    useEffect(() => {
        if ((error?.stack?.split('@')[0]?.replace('/<', '') || location).includes('Maximum call stack size') && window.location.hash === '#page3') {
            window.location.reload();
        } else {
            setIsMaximum(false);
        }
    }, []);

    return (
        !isMaximum ? <LoggingError error={error} resetErrorBoundary={resetErrorBoundary} location={location}/> : null
    );
}
