// @flow
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./redux/store";
import App from "./containers/App/AppContainer";
import reportWebVitals from "./utils/reportWebVitals";
import "./styles/styles.scss";
import { Logger } from "./services";
import { BrowserRouter } from "react-router-dom";
// pages where reload should not occur
const noReloadPaths = ["/login"];

const reloadApplication = () => {
    const currentPath = window.location.pathname;
    if (!noReloadPaths.includes(currentPath)) {
        window.location.reload();
    }
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                {/* <ConnectedRouter history={history}> */}
                    <App />
                {/* </ConnectedRouter> */}
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
function registerServiceWorker() {
    Logger.log("registerServiceWorker", window.location.pathname);
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                Logger.log(
                    "Service Worker registered with scope:",
                    registration.scope
                );

                // Check for a new service worker taking control
                registration?.addEventListener("updatefound", () => {
                    const installingWorker = registration.installing;

                    installingWorker?.addEventListener("statechange", () => {
                        if (
                            installingWorker.state === "installed" &&
                            navigator.serviceWorker.controller
                        ) {
                            // New service worker has been installed
                            Logger.log(
                                "A new service worker is waiting to activate."
                            );
                            // Show a notification or prompt the user to refresh the page to get the latest version.
                        }
                    });
                });
            })
            .catch((error) => {
                console.error("Service Worker registration failed:", error);
            });

        // Prompt the user to refresh the page when a new version is available
        let refreshing;

        navigator.serviceWorker?.addEventListener("controllerchange", () => {
            if (refreshing) return;
            refreshing = true;
            reloadApplication();
        });
    }
}

export function checkServiceWorkerUpdates() {
    navigator.serviceWorker?.addEventListener("controllerchange", () => {
        // The "controllerchange" event indicates that a new service worker has taken control
        reloadApplication();
    });
    // Send a message to the service worker to skip waiting and activate immediately
    navigator.serviceWorker.ready.then((registration) => {
        if (registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
    });
}

registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
