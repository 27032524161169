import { useState, useEffect, useRef, MouseEventHandler } from "react";
import { Dropdown } from "react-bootstrap";
import "./photo.scss";
import Typography from "src/components/base/Typography/Typography";
import iconPencil from "src/assets/icons/icon-edit.svg";

interface Props {
    onProfileChangeClick: MouseEventHandler<HTMLElement>;
    removeImage: MouseEventHandler<HTMLElement>;
}

export const OptionDropdown = ({
    onProfileChangeClick,
    removeImage,
}: Props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            <Dropdown show={dropdownVisible}>
                <Dropdown.Toggle
                    as={"div"}
                    id="dropdown-basic-default"
                    className="remove-image-button"
                    onClick={handleToggleDropdown}
                >
                    <img src={iconPencil} alt="remove icon" />
                </Dropdown.Toggle>

                <Dropdown.Menu align={"start"}>
                    <Dropdown.Item onMouseDown={onProfileChangeClick}>
                        <Typography
                            color="color-4"
                            variant="size-16"
                            weight="regular"
                        >
                            Change Profile Image
                        </Typography>
                    </Dropdown.Item>
                    <Dropdown.Item onMouseDown={removeImage}>
                        <Typography
                            color="color-6"
                            variant="size-16"
                            weight="regular"
                        >
                            Remove Profile Image
                        </Typography>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
