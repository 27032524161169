import React, { useState } from "react";
import Button from "../../../../../components/base/Button/Button";
import { updateUserInfoV1 } from "../../../../../api/user";
import { useDispatch } from "react-redux";
import { clearModalContent } from "../../../../../redux/modules/modalEvent";

type Props = {
    selectedUserId: string;
    contactPreferenceUpdatedByUser: Function;
    contactPreference: string;
};

export function ContactPreferencesModal({
    selectedUserId,
    contactPreferenceUpdatedByUser,
    contactPreference,
}: Props) {
    const [note, setnote] = useState<string>(contactPreference || "");
    const [apiLoading, setapiLoading] = useState(false);
    const dispatch = useDispatch();

    const contactPreferenceUpdated = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setnote(e.target.value);
    };

    const updateContactPreference = async () => {
        setapiLoading(true);
        await updateUserInfoV1(selectedUserId, { contact_preference: note });
        setapiLoading(false);
        setnote("");
        contactPreferenceUpdatedByUser();
        await dispatch(clearModalContent());
    };

    return (
        <div className="add-note-text-area d-flex flex-column justify-content-center">
            <textarea
                rows={4}
                maxLength={500}
                placeholder="Add contact preference"
                onChange={contactPreferenceUpdated}
                value={note}
            />
            <Button
                label="Save Changes"
                variant="primary"
                textWeight="bold"
                textSize="size-14"
                disabled={note.trim().length === 0}
                isProcessing={apiLoading}
                className="m-3 modal-btn confirmation-modal__no-button"
                onClick={updateContactPreference}
            />
        </div>
    );
}
