import { AppConfigValuesEnum } from "./constants";
import { AppConfigValue } from "./types";

export const generateAppConfig = (appConfigValues: Array<AppConfigValue>) => {
    const appConfig: any = {};

    appConfigValues?.forEach((configValue: AppConfigValue) => {
        const serverConfigKey = configValue.config_name;
        const appConfigKey = AppConfigValuesEnum[serverConfigKey];

        appConfig[appConfigKey] = configValue.config_value;
    });

    return appConfig;
};
