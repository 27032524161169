/* eslint-disable camelcase */
// @flow
import React, { Fragment, useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Typography from "../Typography/Typography";
import type { TypographyVariant, Weight } from "../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import Storage from "../../../utils/Storage";
import { get, isEmpty } from "lodash";
import {
    getFullNameInitials,
    randomLinear,
    onTimer,
    onGenderPronouns,
    isValidLastPage,
    getEllipses,
} from "../../../utils/helpers";
import {
    questionnaireHeaderActive,
    questionnaireHeaderPage,
    isNotValidHeaderHash,
} from "../../../utils/QuestionnaireHelper";
import "./header.scss";
import { clearQuestionnaireMetaData, updateQuestionnaireConfig } from "../../../redux/actions/questionnaireActions";
import { clearModalContent, setModalContent } from "../../../redux/modules/modalEvent";
import Avatar from "../Avatar/Avatar";
import { getTherapistProfile } from "../../../api/therapistProfile";
import { setFlashNotification } from "../../../redux/modules/flashNotification";
import { cancelOnboarding, fetchUserInformationByIdV1 } from "../../../api/user";
import { userServiceSection } from "../../../config/constants";
import usePrevious from "../../../hooks/usePrevious";
import { patchTenantName } from "src/redux/services/commonServices";
import editIcon from "src/assets/icons/icon-edit.svg";
import { Photo } from "src/latest/src";
import { setUserToBeOnboarded } from "src/redux/actions/onboardingActions";
import { faCirclePause } from "@fortawesome/free-regular-svg-icons";
import { PauseOnboardingModal } from "src/containers/TherapistClientProfile";

type Props = {
    className?: string,
    expand?: string,
    navBrand?: string,
    variant?: string,
    textSize?: TypographyVariant,
    textWeight?: Weight,
    accountName?: string,
    navLinks?: Array<Object>,
    bellAction: Function,
    isAuthed: boolean,
    onLogout: Function,
    role: string,
    handleSwitchRole: Function,
    profileInfo: Object,
};
export default function Header({
    className = "",
    expand = "lg",
    navBrand = process.env.REACT_APP_SITE_TITLE,
    variant = "light",
    textSize,
    textWeight,
    navLinks,
    bellAction,
    isAuthed,
    onLogout,
    handleSwitchRole,
    profileInfo,
}: Props): React$Element<any> {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { loggedInUser } = useSelector(
        ({ authentication }) => authentication
    );
    const { userIntake, isExistingClientIntake, intakeResult, lastPageEdited } =
        useSelector(({ userIntake }) => userIntake);
    const { userRole, gender_pronoun, full_name, id, user_type } = loggedInUser;
    const location = useLocation();
    const isIntake = window.location.pathname.includes("intake-report");
    const lastPathIntake = Storage.getItem("lastPath");
    const isMyProfile = window.location.pathname.includes("my-profile");
    // eslint-disable-next-line no-unused-vars
    const [isTherapistClientProfile, setIsTherapistClientProfile] = useState(
        window.location.pathname.includes("therapist-client-profile")
    );
    const [isTherapistStaffProfile, setIsTherapistStaffProfile] = useState(
        window.location.pathname.includes("/therapy/therapist-profile/")
    );
    const [isPracticeProfile, setIsPracticeProfile] = useState(
        location.pathname.includes('/practice-profile')
    );


    const [timer, setTimer] = useState("");
    const [activeType, setActiveType] = useState("");

    const userTypes = [
        ...(user_type?.includes("THERAPIST") ? ["THERAPIST"] : []),
        ...(user_type?.includes("SCHEDULER") ? ["SCHEDULER"] : []),
        ...(user_type?.includes("PRACTICE_ADMIN") &&
            !user_type?.includes("SCHEDULER")
            ? ["SCHEDULER"]
            : []),
        ...(user_type?.includes("PRACTICE_ADMIN") ? ["PRACTICE_ADMIN"] : []),
    ];

    const getActiveTab = (currentActivePath: String) => {
        if (currentActivePath?.includes("/therapy/scheduler")) {
            setActiveType("SCHEDULER");
        } else if (currentActivePath?.includes("/therapy/practice_admin")) {
            setActiveType("PRACTICE_ADMIN");
        } else if (currentActivePath === "/therapy/therapist") {
            setActiveType("THERAPIST");
        } else {
            setActiveType("");
        }
    };

    useEffect(() => {
        getActiveTab(window.location.pathname);
    }, [window.location.pathname]);

    useEffect(() => {
        setIsTherapistStaffProfile(
            window.location.pathname.includes("/therapy/therapist-profile/")
        );
        
        setIsTherapistClientProfile(
            window.location.pathname.includes("therapist-client-profile")
        );
        setIsPracticeProfile(
            location.pathname.includes('/practice-profile')
        );

        if (
            window.location.pathname.includes("/therapy/therapist-profile/") ||
            window.location.pathname.includes("therapist-client-profile") ||
            window.location.pathname.includes("my-profile") ||
            window.location.pathname.includes('/practice-profile')
        ) {
            getDelayedInfo();
        } else {
            setShow(false);
        }
    }, [window.location.pathname]);

    const getDelayedInfo = () => {
        setTimeout(() => {
            setShow(true);
        }, 2000);
    };

    const questionnaireHeader =
        userRole === "client"
            ? isExistingClientIntake
                ? [
                    "PERSONAL INFORMATION",
                    "CONCERNS",
                    "IDENTITY",
                    "PERSONALITY",
                ]
                : [
                    "PERSONAL INFORMATION",
                    "CONCERNS",
                    "IDENTITY",
                    "PERSONALITY",
                    "MEETING PREFERENCES",
                ]
            : ["PRACTICE INFORMATION", "EXPERTISE", "IDENTITY", "PERSONALITY"];
    const headerContent = useSelector(
        (state) => state?.questionnaireData?.currentConfig?.header.content
    );
    const tenantLogo = useSelector(
        (state) => state?.configuration?.tenantLogo
    );
    const tenantContact = useSelector(
        (state) => state?.configuration?.tenantContact
    );
    const tenantName = useSelector(
        (state) => state?.configuration?.tenantName
    );

    const fetchingAppConfig = useSelector(
        (state) => state?.configuration?.fetchingAppConfig
    );
    const tenantWebsite = useSelector(
        (state) => state?.configuration?.tenantWebsite
    );
    const [dropdownToggle, setDropdownToggle] = useState(false);
    const [therapistTitles, setTherapistTitles] = useState(
        loggedInUser.credentials || []
    );
    const isFromSettingsPage =
        useSelector(
            (state) =>
                state?.questionnaireData?.initialMetadata?.questionnaire_group
                    ?.ui_origin_path
        ) === "settings";
    const [avatarTimestamp, setAvatarTimestamp] = useState(Date.now());
    const [therapistDetails, setTherapistDetails] = useState({});
    const [clientDetails, setClientDetails] = useState({});
    const [currentUser, setCurrentUser] = useState < any > (null);
    const [editingTenantName, setEditingTenantName] = useState(false);
    const [updateTenantName, setUpdateTenantName] = useState('');
    const [newTenantName, setNewTenantName] = useState('');
    const [editingHover, setEditingHover] = useState(false);

    useEffect(() => {
        setNewTenantName(tenantName);
    }, [tenantName]);

    const handleEditTenantName = () => {
        setEditingTenantName(true);
    };

    const handleUpdateTenantName = async () => {
        try {
            await patchTenantName(updateTenantName);
            setNewTenantName(updateTenantName);
            setEditingTenantName(false);
        } catch (error) {
            console.error("Error updating tenant name:", error);
        }
    };

    const handleCancelEdit = () => {
        setEditingTenantName(false);
        setUpdateTenantName(newTenantName);
    };

    const handleTenantNameChange = (event) => {
        setUpdateTenantName(event.target.value);
    };

    const handleMouseEnter = () => {
        setEditingHover(true);
    };

    const handleMouseLeave = () => {
        setEditingHover(false);
    };

    const userProfileDetails = useSelector(
        (state) => state?.questionnaireData?.clientMetaData
    );

    const setSelectCategoryHeader = (categoryId: string) => {
        !isFromSettingsPage && dispatch(updateQuestionnaireConfig(categoryId));
    };

    const profilePic = useSelector(
        (state) => state?.questionnaireData?.updatedProfilePic
    );

    const currentPageKey = useSelector(
        (state) =>
            state?.questionnaireData?.currentConfig?.currentPage?.unique_key
    );
    const previousPageKey = usePrevious(currentPageKey);

    const userId = useSelector(
        (state) => state?.questionnaireData?.initialMetadata?.user_id
    );
    const isPreonboardedUser = useSelector(
        (state) => state?.questionnaireData?.initialMetadata?.is_preonboarded_user
    );
    const questionnaireType = useSelector(
        (state) =>
            state?.questionnaireData?.initialMetadata?.questionnaire_group?.type
    );

    const handleNav = (route: string) => {
        dispatch(clearModalContent());

        if (route === "/logout") {
            onLogout("MANUAL");

            return;
        }

        history(route);
    };

    const handleNavNewTab = (route: string) => {
        dispatch(clearModalContent());
        const url = `${window.location.origin}${route}`;
        const pathname = window.location.pathname;

        if (route !== pathname) {
            window.open(url, "_blank");
        }
    };

    const handleOnBrandClick = () => {
        const excludedURLs = ["initquestions", "sign-up", "login"];
        const matchedUrl = excludedURLs.filter((url) =>
            window.location.pathname?.includes(url)
        );

        const urlMatched = matchedUrl.length !== 0;

        if (!urlMatched) {
            history("/");
        }
    };

    const openCancelOnboardingModal = () => {
        dispatch(
            setModalContent({
                modalContent: (
                    <PauseOnboardingModal
                            handleOnboardingStatus={onCancelOnboarding}
                            buttonLabel={"Cancel Onboarding"}
                    />
                ),
                title: (
                    <Typography color="color-28" variant="size-20">
                        Cancel Onboarding ?
                    </Typography>
                ),
                size: "sm",
                isGeneralPopup: true,
                backDrop: "static",
                backDrop: "static",
            })
        );
    };

    const onCancelOnboarding = async (note?: string) => {
        await cancelOnboarding(userId, note);
        dispatch(clearQuestionnaireMetaData());
        dispatch(clearModalContent());

        if (questionnaireType && questionnaireType === "SELF_MATCHING_CLIENT_INTAKE" ) {
            handleNav(
                "/therapy/clinician-availability"
            );
        } else {
            handleNav(
                "/therapy/scheduler"
            );
        }
    };

    const [show, setShow] = useState(false);

    useEffect(() => {
        setAvatarTimestamp(Date.now());
    }, [profilePic]);

    const onSetTimer = () => {
        const timeStorage = Storage.getItem("timer");
        const timeObj = onTimer(timeStorage);

        if (timeStorage && timeObj.seconds() > -1) {
            const time = setTimeout(() => {
                const minutes = timeObj.minutes();
                const seconds = timeObj.seconds();

                if (minutes > 0) {
                    setTimer(`${minutes} minute${minutes > 1 ? "s" : ""}`);
                } else {
                    setTimer(`${seconds} second${seconds > 1 ? "s" : ""}`);
                }

                onSetTimer();
            }, 1000);

            setTimeout(() => {
                if (timeObj.seconds() === 0) {
                    clearTimeout(time);
                }
            }, 1000);
        }
    };

    useEffect(() => {
        onSetTimer();
    }, []);

    const getInfo = async () => {
        try {
            const resp = await getTherapistProfile();

            setTherapistTitles(resp.credentials);
        } catch (error) {
            dispatch(
                setFlashNotification({
                    message: error?.response?.data?.message,
                    isError: true,
                    dispatchClass: "notif-settings",
                })
            );
        }
    };

    useEffect(() => {
        if (
            userRole &&
            userRole === "therapist" &&
            window.location.pathname.includes("my-profile/therapist")
        ) {
            getInfo();
        }
    }, [userRole]);

    // to update the credential on header , when ever changes
    useEffect(() => {
        setTherapistTitles(
            loggedInUser.credentials &&
                typeof loggedInUser.credentials === "object"
                ? loggedInUser.credentials.join(",")
                : loggedInUser.credentials
        );
    }, [loggedInUser.credentials]);

    useEffect(() => {
        setTherapistDetails(loggedInUser);
        setClientDetails(userProfileDetails);
        checkIfLoggedinUserIsOnboarding();
    }, [loggedInUser, userProfileDetails]);

    const showRegister =
        /\/register|\/verify-onboarding/.test(window.location.pathname) ||
        (/\/providers/.test(window.location.pathname) && window.innerWidth < 480);

    useEffect(() => {
        getTherapistDataAsScheduler();
        checkIfLoggedinUserIsOnboarding();
    }, [userId]);

    useEffect(() => {
        if (previousPageKey === "basicInformationPage") {
            getTherapistDataAsScheduler();
        }
    }, [currentPageKey]);

    const checkIfLoggedinUserIsOnboarding = () => {
        if (userId === loggedInUser?.id) {
            dispatch(setUserToBeOnboarded(loggedInUser));
        }
    };

    const getTherapistDataAsScheduler = async () => {

        if (
            userId &&
            userId !== id 
        ) {
            const currentUserResponse = await fetchUserInformationByIdV1(
                userId,
                userServiceSection.profile_display
            );

            dispatch(setUserToBeOnboarded(currentUserResponse));

            setCurrentUser({
                ...currentUserResponse,
            });
        }
    };

    const getContent = () => {
        let content;

        if (isTherapistStaffProfile) {
            if (userTypes.includes("PRACTICE_ADMIN")) {
                content = (
                    <Photo userDetails={clientDetails}/>
                );
            } else {
                if (clientDetails?.profile_pic) {
                    content = (
                        <img
                            src={`${decodeURIComponent(
                                clientDetails?.profile_pic
                            )}`}
                            alt=""
                        />
                    );
                } else {
                    content = (
                        <Typography
                            weight="bold"
                            variant="size-24"
                            className="text-uppercase"
                        >
                            {clientDetails?.full_name &&
                                !isEmpty(clientDetails?.full_name)
                                ? getFullNameInitials(
                                    clientDetails?.full_name
                                )
                                : getFullNameInitials(
                                    clientDetails.email
                                )}
                        </Typography>
                    );
                }
            }
        } else {
            if (isTherapistClientProfile) {
                if (clientDetails?.profile_pic) {
                    content = (
                        <img
                            src={`${decodeURIComponent(
                                clientDetails?.profile_pic
                            )}`}
                            alt=""
                        />
                    );
                } else {
                    content = (
                        <Typography
                            weight="bold"
                            variant="size-24"
                            className="text-uppercase"
                        >
                            {clientDetails?.full_name &&
                                !isEmpty(clientDetails?.full_name)
                                ? getFullNameInitials(
                                    clientDetails?.full_name
                                )
                                : getFullNameInitials(clientDetails.email)}
                        </Typography>
                    );
                }
            }
            else {
                if (isPracticeProfile) {
                    content = (
                        <>
                            <img
                                src={`${decodeURIComponent(
                                    tenantLogo
                                )}`}
                                alt="practice logo"
                                className="practicelogo"
                            />
                        </>
                    );
                }
                else {
                    if (therapistDetails?.profile_pic) {
                        content = (
                            <img
                                src={`${decodeURIComponent(
                                    therapistDetails?.profile_pic
                                )}`}
                                alt=""
                            />
                        );
                    } else {
                        content = (
                            <Typography
                                weight="bold"
                                variant="size-24"
                                className="text-uppercase"
                            >
                                {therapistDetails?.full_name &&
                                    !isEmpty(therapistDetails?.full_name)
                                    ? getFullNameInitials(
                                        therapistDetails?.full_name
                                    )
                                    : getFullNameInitials(therapistDetails.email)}
                            </Typography>
                        );
                    }
                }
            }
        }

        return <>{content}</>;
    };

    return (
        <Navbar
            variant={variant || "light"}
            expand={expand}
            className={`${showRegister ? "self-onboarding-header" : "header"
                }  ${window.location.pathname.includes("profile")
                    ? "header-profile"
                    : ""
                } ${userRole === "client" &&
                    window.location.pathname.includes("profile/therapist")
                    ? "no-tabs"
                    : ""
                } ${window.location.pathname.includes("profile") ? "header-relative" : ""
                } ${!isAuthed ||
                    window.location.pathname.includes("sign-up") ||
                    window.location.pathname.toLowerCase().includes("checkin") ||
                    window.location.pathname.toLowerCase().includes("checkout") ||
                    Storage.getItem("required2FA")
                    ? " justify-content-center"
                    : ""
                } ${className}`}
        >
            <Navbar.Brand
                className="cursor-pointer"
                onClick={!isIntake ? handleOnBrandClick : () => { }}
            >
                {showRegister ? (
                    <div className="header__logo">
                        {!fetchingAppConfig && (
                            <img
                                src={`${decodeURIComponent(
                                    tenantLogo
                                )}`}
                                alt="Tenant logo"
                                className="register__logo"
                            />
                        )}
                    </div>
                ) : !isIntake ? (
                    <div
                        className={`header__logo ${window.location.pathname.includes("profile")
                            ? "logo-therapist-profile"
                            : ""
                            }`}
                    >
                        {!loggedInUser.id ? (
                            <a
                                href={tenantWebsite}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {!fetchingAppConfig && (
                                    <img
                                        src={`${decodeURIComponent(
                                            tenantLogo
                                        )}`}
                                        alt="Tenant logo"
                                        className="d-inline-block"
                                    />
                                )}
                            </a>
                        ) : ((!isPracticeProfile) &&
                            <>
                                {!fetchingAppConfig && (
                                    <img
                                        src={`${decodeURIComponent(
                                            tenantLogo
                                        )}`}
                                        alt="Tenant logo"
                                        className="d-inline-block"
                                    />
                                )}
                            </>
                        )}
                    </div>
                ) :
                    (
                        <Typography
                            color={
                                textSize || variant === "light"
                                    ? "color-2"
                                    : variant === "link"
                                        ? "color-1"
                                        : "color-3"
                            }
                            weight="regular"
                            variant="size-18"
                        >
                            Client:{" "}
                            <Typography
                                color={
                                    textSize || variant === "light"
                                        ? "color-2"
                                        : variant === "link"
                                            ? "color-1"
                                            : "color-3"
                                }
                                weight={textWeight || "extra-bold"}
                                variant="size-18"
                                className="d-inline-block"
                            >
                                {get(userIntake, "fullName", "")}
                            </Typography>
                        </Typography>
                    )}
            </Navbar.Brand>
            {window.location.pathname
                .toLowerCase()
                .includes("questionnaire/intake") && (
                    <Fragment>
                        <Navbar.Toggle aria-controls="questionnaire-header">
                            {[...Array(3)].map((_, i) => (
                                <span key={i} className="menu-stripped" />
                            ))}
                        </Navbar.Toggle>
                        {intakeResult && (
                            <>
                                <Navbar.Collapse id="questionnaire-header">
                                    <Nav
                                        className={`m${userRole?.toLowerCase() === "client" &&
                                            isValidLastPage(lastPageEdited)
                                            ? "x"
                                            : "x"
                                            }-auto`}
                                    >
                                        {questionnaireHeader.map((qh, i) => (
                                            <Nav.Link
                                                key={i}
                                                disabled={isNotValidHeaderHash(
                                                    questionnaireHeaderPage(
                                                        userRole?.toLowerCase(),
                                                        qh,
                                                        isExistingClientIntake,
                                                        intakeResult
                                                    )
                                                )}
                                                href={`#page${questionnaireHeaderPage(
                                                    userRole?.toLowerCase(),
                                                    qh,
                                                    isExistingClientIntake,
                                                    intakeResult
                                                )}`}
                                                active={questionnaireHeaderActive(
                                                    userRole,
                                                    qh,
                                                    isExistingClientIntake,
                                                    intakeResult
                                                )}
                                            >
                                                <div>
                                                    <Typography
                                                        weight="semi-bold"
                                                        variant="size-12"
                                                        color="color-29"
                                                    >
                                                        {qh}
                                                    </Typography>
                                                    <span />
                                                </div>
                                            </Nav.Link>
                                        ))}
                                    </Nav>
                                </Navbar.Collapse>
                            </>
                        )}
                    </Fragment>
                )}
            {(window.location.pathname === "/therapy/initquestions" ||
                window.location.pathname === "/therapy/opt-out") && (
                    <Fragment>
                        <Navbar.Toggle aria-controls="questionnaire-header">
                            {[...Array(3)].map((_, i) => (
                                <span key={i} className="menu-stripped" />
                            ))}
                        </Navbar.Toggle>
                        {intakeResult && (
                            <>
                                <Navbar.Collapse id="questionnaire-header">
                                    <Nav
                                        className={`m${userRole?.toLowerCase() === "client" &&
                                            isValidLastPage(lastPageEdited)
                                            ? "x"
                                            : "x"
                                            }-auto`}
                                    >
                                        {headerContent &&
                                            headerContent.map((item, i) => {
                                                return (
                                                    <Nav.Link
                                                        key={i}
                                                        href=""
                                                        className={`${!id
                                                            ? "d-none"
                                                            : "d-block"
                                                            } ${item.isSelected
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        onClick={() => {
                                                            setSelectCategoryHeader(
                                                                item.id
                                                            );
                                                        }}
                                                    >
                                                        <div>
                                                            <Typography
                                                                color="color-29"
                                                                variant="size-12"
                                                                weight="semi-bold"
                                                                className="text-uppercase"
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                            <span />
                                                        </div>
                                                    </Nav.Link>
                                                );
                                            })}
                                    </Nav>
                                    <div className="username-section">
                                        {isAuthed && userId && id !== userId ? (
                                            !currentUser?.full_name ? (
                                                <div>
                                                    <Typography
                                                        color="color-35"
                                                        weight="normal"
                                                        variant="size-12"
                                                        className="me-2"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faUser}
                                                        />{" "}
                                                        Creating a profile for{" "}
                                                    </Typography>
                                                    <Typography
                                                        color="color-2"
                                                        weight="bold"
                                                        variant="size-12"
                                                    >
                                                        {currentUser?.email}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <Typography
                                                            color="color-35"
                                                            weight="normal"
                                                            variant="size-12"
                                                            className="me-1"
                                                        >
                                                            Creating a profile for{" "}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            color="color-2"
                                                            weight="bold"
                                                            variant="size-12"
                                                        >
                                                            {currentUser?.full_name}
                                                        </Typography>
                                                    </div>
                                                </>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    {headerContent && (
                                        <div className="intake-logout">
                                            <Nav>
                                                {questionnaireType &&
                                                    questionnaireType ===
                                                    "SELF_MATCHING_CLIENT_INTAKE" && !isAuthed && (
                                                        <>
                                                            <Typography
                                                                color="color-28"
                                                                weight="regular"
                                                                variant="size-12"
                                                                className="d-inline-block text-right"
                                                            >
                                                                Need help? Call us
                                                                at
                                                                <br />
                                                                <Typography
                                                                    color="color-27"
                                                                    weight="extra-bold"
                                                                    variant="size-12"
                                                                    className="d-inline-block mt-2"
                                                                >
                                                                    {tenantContact}
                                                                </Typography>
                                                            </Typography>
                                                        </>
                                                    )}
                                            </Nav>
                                            <Nav>
                                                {/* // TODO: show this when user is not client */}
                                                {isAuthed && isPreonboardedUser && (
                                                        <>
                                                            <Nav.Link
                                                                onClick={() =>
                                                                    openCancelOnboardingModal()
                                                                }>
                                                                <Typography
                                                                    color="color-29"
                                                                    variant="size-12"
                                                                    weight="bold"
                                                                    className="text-uppercase intake-logout-text"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="me-2"
                                                                        icon={faCirclePause}
                                                                    />
                                                                    Cancel onboarding
                                                                </Typography>
                                                            </Nav.Link>
                                                        </>
                                                    )}
                                            </Nav>
                                            <Nav>
                                                {id && (
                                                    <Nav.Link
                                                        onClick={() =>
                                                            handleNav("/logout")
                                                        }
                                                    >
                                                        <Typography
                                                            color="color-29"
                                                            variant="size-12"
                                                            weight="bold"
                                                            className="text-uppercase intake-logout-text"
                                                        >
                                                            Log Out
                                                        </Typography>
                                                    </Nav.Link>
                                                )}
                                                {!id && (
                                                    <Nav.Link
                                                        onClick={() =>
                                                            handleNav("/login")
                                                        }
                                                        className="text-center"
                                                    >
                                                        <Typography
                                                            color="color-27"
                                                            variant="size-12"
                                                            weight="bold"
                                                            className="text-uppercase intake-logout-text"
                                                        >
                                                            Log In
                                                        </Typography>
                                                    </Nav.Link>
                                                )}
                                            </Nav>
                                        </div>
                                    )}
                                </Navbar.Collapse>
                            </>
                        )}
                    </Fragment>
                )}
            {isAuthed &&
                !isIntake &&
                !window.location.pathname.includes("sign-up") &&
                !window.location.pathname
                    .toLocaleLowerCase()
                    .includes("meeting-info") &&
                !window.location.pathname.toLowerCase().includes("intake") &&
                !window.location.pathname.toLowerCase().includes("checkin") &&
                !window.location.pathname.toLowerCase().includes("checkout") &&
                !Storage.getItem("required2FA") &&
                window.location.pathname !== "/therapy/initquestions" &&
                window.location.pathname !== "/therapy/opt-out" &&
                (showRegister ? (
                    ""
                ) : (
                    <Fragment>
                        <Nav.Link href="#" className="notif notif-sm ms-auto" />
                        <Navbar.Toggle aria-controls="responsive-navbar-nav">
                            {[...Array(3)].map((_, i) => (
                                <span key={i} className="menu-stripped" />
                            ))}
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            {Storage.getItem("timer") && timer && (
                                <Nav className="ms-auto">
                                    <Nav.Item>
                                        <Typography
                                            weight="light"
                                            className="d-inline-block"
                                            variant="size-16"
                                            color="color-28"
                                        >
                                            {
                                                therapistDetails.full_name.split(
                                                    " "
                                                )[0]
                                            }{" "}
                                            you’ve got about{" "}
                                            <Typography
                                                className="d-inline-block"
                                                weight="bold"
                                                variant="size-16"
                                                color="color-28"
                                            >
                                                {timer}
                                            </Typography>{" "}
                                            left
                                        </Typography>
                                    </Nav.Item>
                                </Nav>
                            )}
                            {showRegister ? (
                                ""
                            ) : (
                                <Nav className="ms-auto">
                                    {navLinks &&
                                        navLinks.map((nL, i) => (
                                            <Nav.Link
                                                key={i}
                                                href={nL.route}
                                                className="me-5 py-4 d-inline-block hovered-nav-link header-custom-links"
                                            >
                                                <Typography
                                                    color={
                                                        textSize ||
                                                            variant === "light"
                                                            ? "color-2"
                                                            : variant === "link"
                                                                ? "color-1"
                                                                : "color-3"
                                                    }
                                                    weight={
                                                        textWeight || "bold"
                                                    }
                                                    variant="size-16"
                                                >
                                                    {nL.title}
                                                </Typography>
                                            </Nav.Link>
                                        ))}

                                    <div className="user-type-wrapper">
                                        {userTypes?.length > 0
                                            ? userTypes.map((type, i) => (
                                                <Typography
                                                    key={i}
                                                    onClick={() => {
                                                        handleSwitchRole(
                                                            type?.toLowerCase()
                                                        );
                                                        setActiveType(type);
                                                    }}
                                                    className={`text-capitalize ${type === activeType
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    color="color-27"
                                                    weight={`${type === activeType
                                                        ? "bold"
                                                        : ""
                                                        }`}
                                                    variant="size-16"
                                                >
                                                    {type
                                                        .replace("_", " ")
                                                        .toLowerCase()}
                                                </Typography>
                                            ))
                                            : (user_type || []).length > 1 &&
                                            user_type?.map((type, i) => (
                                                <Typography
                                                    key={i}
                                                    onClick={() => {
                                                        handleSwitchRole(
                                                            type?.toLowerCase()
                                                        );
                                                        setActiveType(type);
                                                    }}
                                                    className={`text-capitalize ${type === activeType
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    color="color-27"
                                                    weight={`${type === activeType
                                                        ? "bold"
                                                        : ""
                                                        }`}
                                                    variant="size-16"
                                                >
                                                    {type
                                                        .replace("_", " ")
                                                        .toLowerCase()}
                                                </Typography>
                                            ))}
                                    </div>

                                    {/* TODO : Remove Old  */}
                                    {/* {(user_type || []).length > 1 && (
                                  user_type
                                      .map((type, i) => (
                                          <Typography
                                              key={i}
                                              onClick={() =>{
                                                  handleSwitchRole(
                                                      type?.toLowerCase()
                                                  ); setActiveType(type);}}
                                              className={`text-capitalize ${type === activeType ? 'active' : ''}`}
                                              color='color-27'
                                              weight={`${type === activeType ? 'bold' : ''}`}
                                              variant='size-16'>
                                              {type
                                                  .replace("_", " ")
                                                  .toLowerCase()}
                                          </Typography>
                                      ))
                              )} */}
                                    {/* <Nav.Link
                              href='#'
                              className='py-4 d-block notif notif-lg ms-3'>
                              <Typography
                                  color={textSize || variant === "light"
                                      ? "color-2"
                                      : variant === "link"
                                          ? "color-1"
                                          : "color-3"}>
                                  <img
                                      src={bell}
                                      alt='notif'
                                      onClick={bellAction} />
                              </Typography>
                          </Nav.Link> */}

                                    {showRegister ? (
                                        ""
                                    ) : (
                                        <Nav.Item className="header__container">
                                            <NavDropdown
                                                id="collasible-nav-dropdown"
                                                className="py-3 user-menu"
                                                show={dropdownToggle}
                                                onToggle={(isOpen) => {
                                                    setDropdownToggle(
                                                        !dropdownToggle
                                                    );
                                                }}
                                                title={
                                                    <div className="header__avatar">
                                                        <Avatar
                                                            self={true}
                                                            userId={id}
                                                            fullName={full_name}
                                                            photoUrl={
                                                                therapistDetails?.profile_pic
                                                            }
                                                            hideImage={true}
                                                            timeStamp={
                                                                avatarTimestamp
                                                            }
                                                        />
                                                    </div>
                                                }
                                            >
                                                {!user_type?.includes(
                                                    "CLIENT"
                                                ) && (
                                                        <NavDropdown.Item
                                                            onClick={() =>
                                                                handleNav(
                                                                    `/therapy/my-profile/${userRole.toLowerCase() ===
                                                                        "client"
                                                                        ? "client"
                                                                        : `${userRole?.toLowerCase() ===
                                                                            "practice_admin" ||
                                                                            userRole?.toLowerCase() ===
                                                                            "scheduler"
                                                                            ? "staff"
                                                                            : "therapist"
                                                                        }`
                                                                    }/${loggedInUser.id
                                                                    }/#profile`
                                                                )
                                                            }
                                                        >
                                                            <Typography
                                                                color="color-30"
                                                                weight={
                                                                    textWeight ||
                                                                    "semi-bold"
                                                                }
                                                                variant="size-16"
                                                            >
                                                                My Profile
                                                            </Typography>
                                                        </NavDropdown.Item>
                                                    )}
                                                {(user_type?.includes(
                                                    "SCHEDULER"
                                                ) ||
                                                    user_type?.includes(
                                                        "PRACTICE_ADMIN"
                                                    )) && (
                                                        <NavDropdown.Item
                                                            onClick={() =>
                                                                handleNav(
                                                                    "/therapy/clinician-availability"
                                                                )
                                                            }
                                                        >
                                                            <Typography
                                                                color="color-30"
                                                                weight={
                                                                    textWeight ||
                                                                    "semi-bold"
                                                                }
                                                                variant="size-16"
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginLeft:
                                                                            "-15px",
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        style={{
                                                                            fontSize:
                                                                                "12px",
                                                                        }}
                                                                        className={
                                                                            "me-1"
                                                                        }
                                                                        icon={
                                                                            faCircle
                                                                        }
                                                                        color="green"
                                                                    />{" "}
                                                                    Availability
                                                                </div>
                                                            </Typography>
                                                        </NavDropdown.Item>
                                                    )}
                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        handleNav(
                                                            `${userRole.toLowerCase() !==
                                                                "client"
                                                                ? "/therapy"
                                                                : ""
                                                            }/settings${userRole.toLowerCase() ===
                                                                "client"
                                                                ? "/user"
                                                                : ""
                                                            }`
                                                        )
                                                    }
                                                >
                                                    <Typography
                                                        color="color-30"
                                                        weight={
                                                            textWeight ||
                                                            "semi-bold"
                                                        }
                                                        variant="size-16"
                                                    >
                                                        Settings
                                                    </Typography>
                                                </NavDropdown.Item>

                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        handleNav(
                                                            "/therapy/feedback"
                                                        )
                                                    }
                                                >
                                                    <Typography
                                                        color="color-30"
                                                        weight={
                                                            textWeight ||
                                                            "semi-bold"
                                                        }
                                                        variant="size-16"
                                                    >
                                                        Support
                                                    </Typography>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        handleNav("/logout")
                                                    }
                                                >
                                                    <Typography
                                                        color="color-30"
                                                        weight={
                                                            textWeight ||
                                                            "semi-bold"
                                                        }
                                                        variant="size-16"
                                                    >
                                                        Log Out
                                                    </Typography>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item>
                                                    <hr className="header-separator" />
                                                </NavDropdown.Item>
                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        handleNavNewTab(
                                                            "/termsofservice"
                                                        )
                                                    }
                                                >
                                                    <Typography
                                                        color="color-28"
                                                        weight={
                                                            textWeight ||
                                                            "semi-bold"
                                                        }
                                                        variant="size-12"
                                                    >
                                                        Terms of Service
                                                    </Typography>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item
                                                    onClick={() =>
                                                        handleNavNewTab(
                                                            "/privacypolicy"
                                                        )
                                                    }
                                                >
                                                    <Typography
                                                        color="color-28"
                                                        weight={
                                                            textWeight ||
                                                            "semi-bold"
                                                        }
                                                        variant="size-12"
                                                    >
                                                        Privacy Policy
                                                    </Typography>
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav.Item>
                                    )}
                                </Nav>
                            )}
                        </Navbar.Collapse>
                    </Fragment>
                ))}

            {isIntake && (
                <React.Fragment>
                    <Navbar.Toggle
                        className=" ms-auto"
                        aria-controls="responsive-navbar-nav"
                    >
                        {[...Array(3)].map((_, i) => (
                            <span key={i} className="menu-stripped" />
                        ))}
                    </Navbar.Toggle>

                    <Nav.Link
                        href="#"
                        onClick={() => handleNav(lastPathIntake || "/")}
                        className="header-close-sm"
                    >
                        <Typography
                            color={
                                textSize || variant === "light"
                                    ? "color-2"
                                    : variant === "link"
                                        ? "color-1"
                                        : "color-3"
                            }
                            weight="extra-bold"
                            className="text-uppercase"
                        >
                            <FontAwesomeIcon
                                onClick={bellAction}
                                icon={faTimes}
                                className="mt-1 header-close"
                            />
                        </Typography>
                    </Nav.Link>
                    <Navbar.Collapse
                        id="collapse-intake"
                        className="collapse-intake"
                    >
                        <Nav
                            className="justify-content-center nav-intake"
                            style={{ flex: 1 }}
                        >
                            {headerContent &&
                                headerContent.map((item, i) => {
                                    return (
                                        <Nav.Item
                                            key={i}
                                            className={
                                                item.isSelected
                                                    ? "active-intake"
                                                    : ""
                                            }
                                        >
                                            <Nav.Link href="#demographics">
                                                <Typography
                                                    color={
                                                        textSize ||
                                                            variant === "light"
                                                            ? "color-2"
                                                            : variant === "link"
                                                                ? "color-1"
                                                                : "color-3"
                                                    }
                                                    variant="size-14"
                                                    weight="extra-bold"
                                                    className="text-uppercase"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                        </Nav>
                        <Nav.Link
                            href="#"
                            onClick={() => handleNav(lastPathIntake || "/")}
                            className="header-close-lg"
                        >
                            <Typography
                                color={
                                    textSize || variant === "light"
                                        ? "color-2"
                                        : variant === "link"
                                            ? "color-1"
                                            : "color-3"
                                }
                                weight="extra-bold"
                                className="text-uppercase"
                            >
                                <FontAwesomeIcon
                                    onClick={bellAction}
                                    icon={faTimes}
                                    className="mt-1 header-close"
                                />
                            </Typography>
                        </Nav.Link>
                    </Navbar.Collapse>
                </React.Fragment>
            )}
            {((!isPracticeProfile) && window.location.pathname.includes("profile")) && (
                <div className="header-profile__user">
                    <div className="header-profile__user-content">
                        <div
                            className="header-profile__user-content__avatar"
                            style={{
                                backgroundImage: Storage.getItem("bgLinear")
                                    ? Storage.getItem("bgLinear")
                                    : randomLinear(255),
                            }}
                        >
                            {show && getContent()}
                        </div>
                        {window.location.pathname.includes("client-profile") ||
                            window.location.pathname.includes(
                                "/therapy/therapist-profile/"
                            ) ? (
                            <React.Fragment>
                                <Typography
                                    variant="size-34"
                                    weight="regular"
                                    color="color-2"
                                    className="header-profile__user-content__name"
                                >
                                    {((clientDetails?.full_name !== "") && (clientDetails.credentials !== undefined))
                                        ? `${getEllipses(clientDetails?.full_name)}, ${clientDetails.credentials}` :
                                        ((clientDetails?.full_name !== "") && clientDetails.credentials === undefined) ? getEllipses(clientDetails?.full_name)
                                            : ""}
                                    {(clientDetails?.full_name?.trim() === "" ||
                                        clientDetails?.full_name ===
                                        undefined) &&
                                        clientDetails?.email
                                        ? getEllipses(clientDetails?.email)
                                        : ""}
                                </Typography>
                                <Typography
                                    className="header-profile__user-genderPronouns"
                                    variant="size-12"
                                    color="color-28"
                                    weight="regular"
                                >
                                    {onGenderPronouns(
                                        clientDetails?.gender_pronoun ?? ""
                                    )}
                                </Typography>
                            </React.Fragment>
                        ) : (

                            <React.Fragment>
                                <Typography
                                    variant="size-34"
                                    weight="regular"
                                    color="color-2"
                                    className="header-profile__user-content__name"
                                >
                                    {isMyProfile
                                        ? full_name
                                        : profileInfo?.full_name}

                                    {(window.location.pathname.includes(
                                        "staff"
                                    ) ||
                                        window.location.pathname.includes(
                                            "therapist"
                                        )) && `${therapistTitles=== undefined ? ""  : `, ${therapistTitles && therapistTitles?.length > 25 ? therapistTitles?.substr(
                                                0,
                                                25
                                            ) + "..." : therapistTitles}` }`          
                                        }
                                </Typography>
                                <Typography
                                    className="header-profile__user-genderPronouns"
                                    variant="size-12"
                                    color="color-28"
                                    weight="regular"
                                >
                                    {onGenderPronouns(
                                        !isMyProfile
                                            ? profileInfo?.gender_pronoun || ""
                                            : gender_pronoun
                                    )}
                                </Typography>
                            </React.Fragment>
                        )}


                    </div>
                   
                    {!window.location.pathname.includes("/my-profile/therapist") &&
                        (userRole !== "client" ||
                            (userRole === "client" &&
                                !window.location.pathname.includes(
                                    "profile/therapist"
                                ))) && (
                            <div className="header-profile__links">
                                {(window.location.pathname.includes("therapist")
                                    ? [
                                        "PROFILE",
                                        // "REPORTS",
                                        // "THERAPISTS",
                                        `${userRole &&
                                            userRole !== "therapist" &&
                                            userRole !== "client"
                                            ? "ONBOARDING"
                                            : ""
                                        }`,
                                        `${ !isTherapistStaffProfile && userRole &&
                                            userRole !== "therapist" &&
                                            userRole !== "client"
                                            ? "DOCUMENTS"
                                            : ""
                                        }`,
                                        `${(userRole === "practice_admin" ||
                                            userRole === "scheduler") &&
                                            userProfileDetails?.display_match_result_tab ===
                                            true
                                            ? "MATCH RESULTS"
                                            : ""
                                        }`,
                                    ]
                                    : userRole === "client" &&
                                        location.hash
                                            .toLowerCase()
                                            .replace("#", "")
                                            ?.toLowerCase() !== "match"
                                        ? []
                                        : [
                                            "",
                                            // "REPORTS",
                                            // "THERAPISTS",
                                        ]
                                ).map((item, i) => (
                                    <a
                                        href={`#${item
                                            .toLowerCase()
                                            .replace(" ", "_")}`}
                                        key={i}
                                        className={`${location.hash
                                            .toLowerCase()
                                            .replace("#", "")
                                            .replace("_", " ")
                                            ?.toLowerCase() ===
                                            item.toLowerCase() ||
                                            (!location.hash &&
                                                item === "PROFILE") ||
                                            item
                                                .toLowerCase()
                                                .includes(
                                                    location.hash
                                                        .toLowerCase()
                                                        .replace("#", "")
                                                        ?.toLowerCase()
                                                )
                                            ? "header-profile__active"
                                            : ""
                                            }`}
                                    >
                                        <Typography
                                            color="color-2"
                                            variant="size-14"
                                            weight="bold"
                                        >
                                            {item}
                                        </Typography>
                                    </a>
                                ))}
                            </div>
                        )}
                </div>
            )}

           

            {isPracticeProfile && (
                <div className="header-profile__user">
                    <div className="header-profile__user-content">
                        <div className="d-logo" style={{ height: "20px", display: "flex", justifyContent: 'center' }}>
                            {show && getContent()}
                        </div>
                        <div className=" mt-5">
                            {editingTenantName ? (
                                <div>
                                    <input
                                        type="text"
                                        value={updateTenantName}
                                        onChange={handleTenantNameChange}
                                        className="input-field"
                                    />
                                    <button className="btn btn-primary" onClick={handleUpdateTenantName}><Typography style={{ color: "white" }} >Save</Typography></button>
                                    <button className="btn btn-secondary ms-2" onClick={handleCancelEdit}>
                                        <Typography style={{ color: "white" }}>Cancel</Typography>
                                    </button>
                                </div>
                            ) : (
                                <div className="mt-5">
                                    <div
                                        className="header-profile__therapyname-container"
                                        onClick={handleEditTenantName}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <Typography className="header-profile__therapyname" variant="size-36">{newTenantName}</Typography>
                                        {(editingTenantName || editingHover) && <img src={editIcon} alt="Edit Icon" className="edit-icon" />}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Navbar>
    );
}



