import * as React from "react";
import type { ModalSize, ButtonVariant } from "../../types";

const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";
const CLEAR_MODAL_CONTENT = "CLEAR_MODAL_CONTENT";
const SET_MODAL_LOADING_STATUS = "SET_MODAL_LOADING_STATUS";

type Props = {
    id?: String, // to identify current modal from context
    title?: any,
    modalContent: string | React.Node,
    isWithCloseButton?: boolean,
    onToggle?: Function,
    className?: string,
    size?: ModalSize,
    confirmButtonLabel?: string,
    onConfirmButtonClick?: Function,
    confirmButtonVariant?: ButtonVariant,
    cancelButtonLabel?: string,
    isWithCancelButton?: boolean,
    isGeneralPopup?: boolean,
    isLoadingPopup?: boolean,
    isProcessing?: boolean,
    isDisableClose?: boolean,
    backDrop?:String | Boolean,
};

export function setModalContent({ ...props }: Props) {
    return {
        type: SET_MODAL_CONTENT,
        ...props,
    };
}

export function clearModalContent() {
    return {
        type: CLEAR_MODAL_CONTENT,
    };
}

export function setModalLoadingStatus(loading){
    return {
        type: SET_MODAL_LOADING_STATUS,
        loading,
    };
}

const initialState = {
    id: null,
    title: null,
    isWithCloseButton: true,
    modalContent: null,
    modalComponentKey: "",
    contentProps: null,
    className: "",
    size: "md",
    onToggle: null,
    confirmButtonLabel: null,
    onConfirmButtonClick: null,
    confirmButtonVariant: "",
    cancelButtonLabel: "",
    isWithCancelButton: false,
    isProcessing: false,
    isDisabledDragging: true,
    isDisableClose: false,
    backDrop:true
};

export default function modalEvent(
    state: Object = initialState,
    action: Object
): any {

    switch (action.type) {
        case SET_MODAL_CONTENT:
            return {
                ...state,
                ...action,
            };
        case CLEAR_MODAL_CONTENT:
            return {
                ...state,
                ...initialState,
            };
        case SET_MODAL_LOADING_STATUS:
            return {
                ...state,
                isProcessing: action.loading,
            };
        default:
            return state;
    }
}
