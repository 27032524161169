// @flow
import Storage from "../../utils/Storage";
import {
    fetchBrowserStorage,
    onGetStorage,
    setBrowserStorage,
} from "../../utils/helpers";
import {
    SET_CLUSTER_SESSION_DATA,
    SET_CLUSTER_LIST_DATA,
    SET_ACTIVE_RUNNING_SESSION_DATA,
    SET_CLIENT_DATA,
    SET_CLIENT_CHECKIN_QUESTIONNAIRE_DATA,
    SET_CHIME_SESSION_DATA,
    SET_ATTENDEES,
    SET_WAITING_ROOM_MEMBERS,
    SET_JOIN_DATA,
    CLEAR_SESSION,
    UPDATE_AUDIO_VIDEO,
    SET_TILE_STATUS,
    CLEAR_TILE_STATUS,
    SET_NEW_WAITING_ROOM_MEMBERS,
    CLEAR_NEW_WAITING_ROOM_MEMBER,
    SET_CLIENT_JOINED_IN,
    SET_CLIENT_LEFT_THE_WAITING_ROOM,
    SET_START_SESSION,
    SET_VIDEO,
    SET_AUDIO,
    SET_MIC,
    SET_VIDEO_DEVICE,
    SET_MIC_DEVICE,
    SET_AUDIO_DEVICE,
    SHOW_SELF_VIEW,
    HIDE_SELF_VIEW,
    UPDATE_LOCAL_VIDEO_POSITION,
    TELETHERAPY_SETTINGS_PAGE_STATUS,
    BLUR_BACKGROUND,
} from "../actions/teleTherapyActions";

const initialState = {
    teleTherapySessionData: onGetStorage("teleTherapySessionData", {}),
    clusterListData: onGetStorage("clusterListData", {}),
    activeRunningSessionData: onGetStorage("activeRunningSessionData", {
        therapy_sessions: [],
        total: 0,
    }),
    client_data: onGetStorage("client_data", {}),
    chime_data: onGetStorage("chime_data", {}),
    isReadyToJoin: onGetStorage("isReadyToJoin", {}),
    attendees: onGetStorage("attendees", []),
    waitingRoomMembers: onGetStorage("waitingRoomMembers", []),
    joinData: onGetStorage("joinData", {}),
    isAudioEnabled: onGetStorage("isAudioEnabled", true),
    isVideoEnabled: onGetStorage("isVideoEnabled", true),
    isMicEnabled: onGetStorage("isMicEnabled", true),
    tileStatus: onGetStorage("tileStatus", {}),
    newWaitingRoomMembers: onGetStorage("newWaitingRoomMembers", {}),
    isClientJoinedIn: onGetStorage("isClientJoinedIn", false),
    startSession: onGetStorage("startSession", {}),
    sessionData: onGetStorage("sessionData", {}),
    client_checkin_questionnaire: onGetStorage(
        "client_checkin_questionnaire",
        {}
    ),
    videoDevice: onGetStorage("videoDevice", null),
    micDevice: onGetStorage("micDevice", null),
    audioDevice: onGetStorage("audioDevice", null),
    showSelfView: onGetStorage("showSelfView", true),
    blurBackground: onGetStorage("blurBackground", false),
    localVideoPosition: fetchBrowserStorage("localStorage", "position") || null,
    teletherapySettingsPageStatus: false,
};

export default function teleTherapyStore(
    state: Object = initialState,
    action: Object
): any {
    switch (action.type) {
        case SET_CLUSTER_SESSION_DATA:
            Storage.setItem(
                "teleTherapySessionData",
                JSON.stringify(action.teleTherapySessionData)
            );

            return {
                ...state,
                teleTherapySessionData: action.teleTherapySessionData,
            };
        case SET_CLUSTER_LIST_DATA:
            return {
                ...state,
                clusterListData: action.clusterListData,
            };
        case SET_ACTIVE_RUNNING_SESSION_DATA:
            return {
                ...state,
                activeRunningSessionData: action.activeRunningSessionData,
            };

        case SET_CLIENT_DATA:
            Storage.setItem("client_data", JSON.stringify(action.client_data));

            return {
                ...state,
                client_data: action.client_data,
            };
        case SET_CLIENT_CHECKIN_QUESTIONNAIRE_DATA:
            Storage.setItem(
                "client_checkin_questionnaire",
                JSON.stringify(action.client_checkin_questionnaire)
            );

            return {
                ...state,
                client_checkin_questionnaire:
                    action.client_checkin_questionnaire,
            };
        case SET_CHIME_SESSION_DATA:
            Storage.setItem("chime_data", JSON.stringify(action.chime_data));

            return {
                ...state,
                ...{ chime_data: action.chime_data },
                ...{ isReadyToJoin: action.isReadyToJoin },
            };
        case SET_ATTENDEES:
            Storage.setItem("attendees", JSON.stringify(action.attendees));

            return {
                ...state,
                attendees: action.attendees,
            };

        case SET_WAITING_ROOM_MEMBERS: {
            const activeRunningSession = state.activeRunningSessionData; // all current active running session data
            const newMembers = [];

            action.waitingRoomMembers.map((memeber) => {
                if (
                    memeber &&
                    activeRunningSession.therapy_sessions.findIndex(
                        (x) =>
                            x.clients[0].client_id ===
                            memeber.clients[0].client_id
                    ) === -1
                ) {
                    newMembers.push(memeber);
                }
            });

            const newSessionState = {
                therapy_sessions: [
                    ...activeRunningSession.therapy_sessions,
                    ...newMembers,
                ],
            };

            return {
                ...state,
                activeRunningSessionData: newSessionState,
            };
        }

        case SET_JOIN_DATA:
            Storage.setItem("joinData", JSON.stringify(action.joinData));
            Storage.setItem("isReadyToJoin", `${action.isReadyToJoin}`);

            return {
                ...state,
                joinData: action.joinData,
                isReadyToJoin: action.isReadyToJoin,
            };

        case SET_CLIENT_LEFT_THE_WAITING_ROOM: {
            const activeRunningSession = state.activeRunningSessionData;
            const leftMemeber = action.leftWaitingRoomMembers[0];

            const newSessionState = {
                therapy_sessions: activeRunningSession.therapy_sessions.filter(
                    (memeber) =>
                        memeber.clients[0].client_id !== leftMemeber.client_id
                ),
            };

            return {
                ...state,
                activeRunningSessionData: newSessionState,
            };
        }

        case CLEAR_SESSION:
            Storage.removeItem("attendees");
            Storage.removeItem("waitingRoomMembers");
            Storage.removeItem("joinData");
            Storage.removeItem("isReadyToJoin");
            Storage.removeItem("isAudioEnabled");
            Storage.removeItem("isMicEnabled");
            Storage.removeItem("isVideoEnabled");
            Storage.removeItem("tileStatus");
            Storage.removeItem("newWaitingRoomMembers");
            Storage.removeItem("isClientJoinedIn");
            Storage.removeItem("videoDevice");
            Storage.removeItem("micDevice");
            Storage.removeItem("audioDevice");

            return {
                ...state,
                attendees: [],
                waitingRoomMembers: [],
                joinData: {},
                isReadyToJoin: false,
                isAudioEnabled: true,
                isVideoEnabled: true,
                isMicEnabled: true,
                tileStatus: {},
                newWaitingRoomMembers: {},
                isClientJoinedIn: false,
                videoDevice: null,
                micDevice: null,
                audioDevice: null,
            };
        case UPDATE_AUDIO_VIDEO:
            Storage.setItem(`${action.mediaType}`, !state[action.mediaType]);

            return {
                ...state,
                [action.mediaType]: !state[action.mediaType],
            };
        case SET_VIDEO:
            Storage.setItem("isVideoEnabled", action.isVideoEnabled);

            return {
                ...state,
                isVideoEnabled: action.isVideoEnabled,
            };
        case SET_AUDIO:
            Storage.setItem("isAudioEnabled", action.isAudioEnabled);

            return {
                ...state,
                isAudioEnabled: action.isAudioEnabled,
            };
        case SET_MIC:
            Storage.setItem("isMicEnabled", action.isMicEnabled);

            return {
                ...state,
                isMicEnabled: action.isMicEnabled,
            };
        case SET_TILE_STATUS:
            Storage.setItem("tileStatus", action.tileStatus);

            return {
                ...state,
                tileStatus: action.tileStatus,
            };
        case CLEAR_TILE_STATUS:
            Storage.setItem("tileStatus", {});

            return {
                ...state,
                tileStatus: {},
            };
        case SET_NEW_WAITING_ROOM_MEMBERS:
            Storage.setItem(
                "newWaitingRoomMembers",
                action.newWaitingRoomMembers
            );

            return {
                ...state,
                newWaitingRoomMembers: action.newWaitingRoomMembers,
            };
        case CLEAR_NEW_WAITING_ROOM_MEMBER:
            Storage.setItem("newWaitingRoomMembers", {});

            return {
                ...state,
                newWaitingRoomMembers: {},
            };
        case SET_CLIENT_JOINED_IN:
            Storage.setItem("isClientJoinedIn", true);

            return {
                ...state,
                isClientJoinedIn: true,
            };
        case SET_START_SESSION:
            Storage.setItem("startSession", action.startSession);

            return {
                ...state,
                startSession: action.startSession,
            };
        case SET_VIDEO_DEVICE:
            Storage.setItem("videoDevice", JSON.stringify(action.videoDevice));

            return {
                ...state,
                videoDevice: action.videoDevice,
            };
        case SET_MIC_DEVICE:
            Storage.setItem("micDevice", JSON.stringify(action.micDevice));

            return {
                ...state,
                micDevice: action.micDevice,
            };
        case SET_AUDIO_DEVICE:
            Storage.setItem("audioDevice", JSON.stringify(action.audioDevice));

            return {
                ...state,
                audioDevice: action.audioDevice,
            };
        case SHOW_SELF_VIEW:
            Storage.setItem("showSelfView", true);

            return {
                ...state,
                showSelfView: true,
            };
        case HIDE_SELF_VIEW:
            Storage.setItem("showSelfView", false);

            return {
                ...state,
                showSelfView: false,
            };

        case BLUR_BACKGROUND:
            Storage.setItem("blurBackground", action.status);

            return {
                ...state,
                blurBackground: action.status,
            };

        case UPDATE_LOCAL_VIDEO_POSITION: {
            const position = action.position;

            setBrowserStorage("localStorage", { position });

            return {
                ...state,
                localVideoPosition: position,
            };
        }

        case TELETHERAPY_SETTINGS_PAGE_STATUS: {
            const status = action.status;

            return {
                ...state,
                teletherapySettingsPageStatus: status,
            };
        }

        default:
            return state;
    }
}
