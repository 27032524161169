import makeApiRequest from "../../api/makeApiCall";
import { config } from "../../config/constants";

const QUESTIONNAIRE_SERVICE = config.questionnaireService;
const THERAPY_SESSIONS_SERVICE = config.therapySessionService;

interface RsponseRequestBody {
    answers: Array;
    total: number;
}

export const getQuestionnaireMetadata = async () => {
    const selfUserId = localStorage.getItem("self-user-id");

    let url;

    if (selfUserId) {
        url = `${QUESTIONNAIRE_SERVICE}questionnaire-metadata?user_id=${selfUserId}`;
    } else {
        url = `${QUESTIONNAIRE_SERVICE}questionnaire-metadata`;
    }

    return await makeApiRequest(url, "GET");
};

export const fetchQuestionsOfCurrentPage = async (
    pageId: string,
    sessionId: string
) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-pages/${pageId}/questionnaire-questions?questionnaire_completion_session_id=${sessionId}`,
        "GET"
    );
};

export const saveQuestionResponsesOfCurrentPage = async (
    questionnaireSessionId: string,
    categoryId: String,
    pageId: string,
    responses: Array,
    responseCallback: Function = null
) => {
    const requestBody: RsponseRequestBody = { answers: responses, total: 0 };

    const res = await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-completion-sessions/${questionnaireSessionId}/questionnaire-categories/${categoryId}/questionnaire-pages/${pageId}/responses`,
        "POST",
        requestBody
    );

    if (responseCallback) {
        responseCallback(res);
    }

    return res;
};

export const postQuestionnaireCompletionSession = async (data: Object) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-completion-sessions`,
        "POST",
        data
    );
};

export const getClientSubmittedResponse = async (clientData: Object) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-questions/_questions-with-responses?questionnaire_completion_session_id=${clientData.checkinQuestionnaireId}`,
        "GET"
    );
};

export const getUserProfileMetadata = async (responseCallback, id: String) => {
    const res = await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}user-profile-info/${id}`,
        "GET"
    );

    if (responseCallback) {
        responseCallback();
    }

    return res;
};

// therapist client profile metadata
export const getClientUserProfileMetadata = async (id: String) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}user-profile-info/${id}`,
        "GET"
    );
};

export const getQuestionnaireCategoriesMetadata = async (userRole: String) => {
    let questionnaireType = "";

    if (userRole === "THERAPIST") {
        questionnaireType = "THERAPIST_INTAKE";
    }

    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-groups?type=${questionnaireType}`,
        "GET"
    );
};

export const markCategoryPending = async (categoryId: String) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-categories/${categoryId}/_mark-pending?ui_origin_path=settings`,
        "PATCH"
    );
};

// Get match results for selected client
export const getMatchScore = async (clientId) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}match-scores?client_id=${clientId}`,
        "POST"
    );
};

export const getMatchSearchResultsWithFilters = async (
    clientId,
    offsetData,
    filterData
) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}match-scores/_search?client_id=${clientId}&offset=${offsetData.offset}&limit=${offsetData.limit}`,
        "POST",
        filterData
    );
};

export const getUserFiltersForMatchScore = async (clientId) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}match-scores/_filters?client_id=${clientId}`,
        "GET"
    );
};

export const addClusterMember = async (
    clientId: String,
    therapistId: String,
    therapyType: String,
    isActive?: boolean,
) => {
    return await makeApiRequest(
        `${THERAPY_SESSIONS_SERVICE}cluster-members`,
        "POST",
        {
            client_id: clientId,
            therapist_id: therapistId,
            match_types: [{ match_type: therapyType }],
            is_active: isActive
        }
    );
};

// Cancel Match Request For user
export const cancelUserMatchRequest = async (clientId) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}match-scores?client_id=${clientId}`,
        "DELETE"
    );
};

// Payout of pocket
export const payOutOfPocket = async (clientId) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-responses/_update-payment?client_id=${clientId}`,
        "PUT"
    );
};

// THerapist meeting info

export const TherapistMeetingInfo = async (therapistId) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}therapist-meeting-info/${therapistId}`,
        "GET"
    );
};

// Clinician availability Filter search

export const getClinicianAvailabilityData = async (data: Object,limit:Number,offset:Number) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}clinician-availability-data/_search?limit=${limit}&offset=${offset}`,
        "POST",
        data
    );
};


// GET Clinician availabity initial Filaters

export const getClinicianAvailabilityInitFilters = async () => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}clinician-availability-filter`,
        "GET"
    );
};

// GET Filters for Client self matching 


export const getSelfMatchingFilters = async (clientId:String) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}clinician-availability-filter/${clientId}`,
        "GET"
    );
};

export const getAllInsurances = async () => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}user-profile-info/insurances`,
        "GET"
    );
};

// This API is used to mark page pending 

export const markQuestionnairePagePending = async (pageUniqueKey: String,userId : String) => {
    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-pages/_mark-pending?page_unique_key=${pageUniqueKey}&user_id=${userId}`,
        "PUT"
    );
};


// API to toggle match score state
export const changeMatchScoreState = async (
    state: Boolean,
    clientId: string
) => {
    const data = {
        calculate_match_score: state,
        client_id: clientId
    };

    return await makeApiRequest(
        `${QUESTIONNAIRE_SERVICE}questionnaire-completion-sessions/_change-match-score-state`,
        "PATCH",
        data
    );
};