// @flow
import Storage from '../../utils/Storage';
import { onGetStorage } from '../../utils/helpers';

const SET_USER_INTAKE = 'SET_USER_INTAKE';
const SET_EXISTING_CLIENT = 'SET_EXISTING_CLIENT';
const SET_RESULT = 'SET_RESULT';
const SET_LAST_PAGE_EDITED = 'SET_LAST_PAGE_EDITED';
const SET_YEAR_GRADUATED = 'SET_YEAR_GRADUATED';
const SET_QUESTION_TYPE = 'SET_QUESTION_TYPE';

export function updateUserIntake(userIntake: Object): {userIntake: Object} {
    return {
        type: SET_USER_INTAKE,
        userIntake
    };
}

export function setIsExistingClient(isExistingClientIntake: boolean): {isExistingClientIntake: boolean} {
    return {
        type: SET_EXISTING_CLIENT,
        isExistingClientIntake
    };
}

export function setResult(intakeResult: Object): {intakeResult: Object} {
    return {
        type: SET_RESULT,
        intakeResult
    };
}

export function setLastPageEdited(lastPageEdited: any): {lastPageEdited: any} {
    return {
        type: SET_LAST_PAGE_EDITED,
        lastPageEdited
    };
}

export function setYearGraduated(yearGraduated: any): {yearGraduated: any} {
    return {
        type: SET_YEAR_GRADUATED,
        yearGraduated
    };
}

export function setQuestionType(questionnaireType: any): {questionnaireType: any} {
    return {
        type: SET_QUESTION_TYPE,
        questionnaireType
    };
}

const initialState = {
    userIntake: onGetStorage('userIntake', {}),
    isExistingClientIntake: false,
    intakeResult: onGetStorage('intakeResult', {}),
    lastPageEdited: onGetStorage('lastPageEdited', null),
    yearGraduated: null,
    questionnaireType: onGetStorage('questionnaireType', null)
};

export default function userIntake(state: Object = initialState, action: Object):|any| {
    initialState: Object,
  } {
    switch (action.type) {
        case SET_USER_INTAKE:
            Storage.setItem('userIntake', action.userIntake);

            return {
                ...state,
                userIntake: action.userIntake
            };
        case SET_EXISTING_CLIENT:
            return {
                ...state,
                isExistingClientIntake: action.isExistingClientIntake
            };
        case SET_RESULT:
            Storage.setItem('intakeResult', action.intakeResult);

            return {
                ...state,
                intakeResult: action.intakeResult
            };
        case SET_LAST_PAGE_EDITED:
            Storage.setItem('lastPageEdited', action.lastPageEdited);

            return {
                ...state,
                lastPageEdited: action.lastPageEdited
            };
        case SET_YEAR_GRADUATED:
            return {
                ...state,
                yearGraduated: action.yearGraduated
            };
        case SET_QUESTION_TYPE:
            Storage.setItem('questionnaireType', action.questionnaireType);

            return {
                ...state,
                questionnaireType: action.questionnaireType
            };
        default:
            return state;
    }
}
