import "./photo.scss";
import Typography from "src/components/base/Typography/Typography";

interface Props {
    errorMessage: string;
}

export const UploadErrorText = ({ errorMessage }: Props) => {
    return (
        <Typography
            className={`photo-widget__exceeds ${
                window.location.pathname.includes("settings")
                    ? "photo-widget__exceeds-settings-page"
                    : ""
            }`}
            color="color-6"
            weight="semi-bold"
        >
            {errorMessage}
        </Typography>
    );
};
