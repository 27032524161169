// @flow
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons';
import eyeSlashed from '../../../assets/icons/icon-hidePassword.svg';
import eye from '../../../assets/icons/icon-seePassword.svg';
import lock from '../../../assets/icons/icon-lock.svg';
import Info from '../Info/Info';
import Typography from '../Typography/Typography';
import { capitalize } from '../../../utils/helpers';
import './input.scss';

type Props = {
    className?: string,
    placeholder?: string,
    onChange: Function,
    variant?: string,
    isSearchInput?: boolean,
    isForForm?: boolean,
    value?: string,
    type?: string,
    label?: any,
    required?: boolean,
    name?: string,
    props?: Object,
    feedbackMessage?: string,
    warningMessage?: string,
    isInvalid?: boolean,
    isValidImage?: boolean,
    hasEye?: boolean,
    hasLock?: boolean,
    info?: any,
    onFocus?: Function,
    isCustomPlaceHolder?: boolean,
    customPlaceHolder?: any,
    isSelfOnboarding?: Boolean,
    isInviteStaff?: Boolean,
}

export default function Input({
    className = '', placeholder, onChange, variant = 'border-light', isSearchInput = false,
    value, type = 'text', label, required, name = '', isForForm = true, feedbackMessage, warningMessage, isInvalid, isValidImage = false, hasEye, hasLock, onFocus, isCustomPlaceHolder, customPlaceHolder, info, isSelfOnboarding, isInviteStaff, ...props
}: Props): React$Element<any> {
    const [show, setIsShow] = useState(false);
    const [showInfo, setIsShowInfo] = useState(false);

    const onShowEye = () => {
        if (hasEye && type === 'password') setIsShow(!show);
    };

    const onFocusCustomPlaceHolder = () => {
        const input = document.querySelector(`input[name="${name}"]`) || document.querySelector(`textarea[name="${name}"]`);

        if (input) input.focus();
    };

    return (
        <Form.Group id={name} className={`${!isForForm ? 'input' : 'custom-input'} ${value && label ? 'show-label' : ''} ${isInvalid ? 'input-error' : ''} ${className}`}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                {...props}
                className={`${variant}${isSearchInput ? ' with-icon' : ''}`}
                type={hasEye && type === 'password' ? show ? 'text' : type : type}
                name={name}
                value={value}
                required={required}
                placeholder={placeholder && placeholder.length <= 30 ? isSelfOnboarding || isInviteStaff ? placeholder : capitalize(placeholder) : placeholder}
                onChange={onChange}
                isInvalid={isInvalid || false}
                onFocus={onFocus} />
            {isCustomPlaceHolder && !value && <span onClick={onFocusCustomPlaceHolder} className='custom-placeholder'>{customPlaceHolder}</span>}
            {feedbackMessage && (
                <Form.Control.Feedback type={isInvalid ? 'invalid' : 'valid'}>
                    {feedbackMessage}
                </Form.Control.Feedback>
            )}
            {warningMessage && !isInvalid && (
                <Form.Control.Feedback type='valid' className='warning-feedback'>
                    {warningMessage}
                </Form.Control.Feedback>
            )}
            {
                hasEye && type === 'password' && <img className='password-eye cursor-pointer' alt='password-eye' onClick={onShowEye}
                    src={show ? eyeSlashed : eye} />
            }
            {
                hasLock && <img className='input-lock' alt='lock' src={lock} />
            }
            {
                isValidImage && !name.includes('cert_') && name !== 'fee' && <span><FontAwesomeIcon icon={faCheck} className='input__icon'/></span>
            }
            {
                info && !isValidImage && <Info isShow={showInfo} onHover={() => setIsShowInfo(true)} onLeave={() => setIsShowInfo(false)}
                    className='input-info' description={info}/>
            }
            {
                name === 'fee' && <span className='fee_symbol'><Typography variant='size-14' color='color-2' weight='bold'>$ USD</Typography></span>
            }
            {isSearchInput && <FontAwesomeIcon onClick={onFocus} icon={faSearch} className='input__icon icon-search cursor-pointer'/>}
        </Form.Group>
    );
}
