// @flow
import React from 'react';
import Typography from '../../base/Typography/Typography';
import checkIcon from '../../../assets/icons/icon-checkmark.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
} from "@fortawesome/free-solid-svg-icons";
import Input from '../../base/Input/Input';
import './mini-check-box.scss';

type Props = {
    className?: string,
    isActive: boolean,
    onClick: Function,
    text: string,
    value: any,
    isCheckFirst?: boolean,
    hasOther?:boolean,
    onChange?: Function,
    inputValue?: string,
    isRadio?: boolean,
    props?: Object
}

export default function MiniCheckBox({ className = '', isActive, onClick, text, value, isCheckFirst, hasOther, onChange = () => {}, inputValue,isRadio=false, ...props }: Props): React$Element<any> {
    return (
        <div className={`mini-check-box ${isActive ? 'active-parent' : ''} ${className}`} >
            <div className={`mini-check-box__container ${isCheckFirst ? '-checkbox-first' : ''} ${isActive ? 'active-container' : ''}`}>
                {
                    !isCheckFirst ? <React.Fragment>
                        <Typography className='mini-check-box__check-title' variant='size-14' color={isActive ? 'color-2' : 'color-28'}
                            weight='regular'>
                            {text}
                        </Typography>
                        <span className={`mini-check-box__check cursor-pointer ${isRadio === true ? 'rounded-circle':''}`} onClick={onClick}>
                            {
                                (isActive && isRadio=== false) && <img src={checkIcon} alt='check'/>
                            }
                            {
                                (isActive && isRadio=== true) && <FontAwesomeIcon icon={faCircle} size="xs" style={{color: "#ffffff",width:"10px"}} />
                            }
                        </span>
                    </React.Fragment> : <React.Fragment>
                        <span className={`mini-check-box__check cursor-pointer ${isRadio === true ? 'rounded-circle':''}`} onClick={onClick}>
                            {
                                (isActive && isRadio=== false) && <img src={checkIcon} alt='check'/>
                            }
                            {
                                (isActive && isRadio=== true) && <FontAwesomeIcon icon={faCircle} size="xs" style={{color: "#ffffff",width:"10px"}} />
                            }
                        </span>
                        {
                            hasOther && isActive ? <Input {...props} className='mb-0'
                                value={inputValue}
                                placeholder='Other'
                                onChange={e => onChange(e)}/> : <Typography onClick={onClick} className='mini-check-box__check-title cursor-pointer' variant='size-14'
                                color={isActive ? 'color-2' : 'color-28'}
                                weight='regular'>
                                {text}
                            </Typography>
                        }
                    </React.Fragment>
                }
            </div>
        </div>
    );
}
