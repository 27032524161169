// @flow
import { storageKey } from '../../config/constants';
import { onGetStorage } from '../../utils/helpers';
import Storage from '../../utils/Storage';

const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const SET_LOGGING_OUT = 'SET_LOGGING_OUT';
const SET_USER_ROLE = 'SET_USER_ROLE';
const SET_USER_FULLNAME = 'SET_USER_FULLNAME';

export function updateUserToken(userToken: Object): {userToken: string} {
    return {
        type: SET_ACCESS_TOKEN,
        userToken
    };
}

export function authUser(data: Object): {data: Object} {
    return {
        type: AUTHENTICATE_USER,
        data
    };
}

export function updateLoggedInUser(data: Object): {data: Object} {
    return {
        type: SET_PROFILE_DATA,
        data
    };
}

export function setUserFullName(fullName:string,nameType:string){
    return {
        type: SET_USER_FULLNAME,
        fullName,
        nameType
    }; 
}

export function setLoggingOut(): {} {
    return {
        type: SET_LOGGING_OUT
    };
}

export function setUserRole(userRole: string): {userRole: string} {
    return {
        type: SET_USER_ROLE,
        userRole
    };
}

const initialState = {
    isAuthed: onGetStorage('isAuthed', false),
    isLoggingOut: onGetStorage('isLoggingOut', false),
    loggedInUser: onGetStorage('loggedInUser', {}),
    userToken: onGetStorage(storageKey.accessToken, null)
};

export default function authentication(state: Object = initialState, action: Object):|any| {
    isAuthed: boolean,
    isLoggingOut: boolean,
    loggedInUser: {},
    userToken: null
  } {
    switch (action.type) {
        case SET_ACCESS_TOKEN:{
            Storage.setItem('isLoggingOut', false);
            const userToken = Storage.getItem(storageKey.accessToken) || '{}';

            const updatedUserToken = {...JSON.parse(userToken),...action.userToken};

            Storage.setItem(storageKey.accessToken, JSON.stringify(updatedUserToken));

            return {
                ...state,
                isLoggingOut: false,
                userToken: {...state.userToken, ...action.userToken}
            };
        }

        case AUTHENTICATE_USER:
            Storage.setItem('isAuthed', true);
            Storage.setItem('loggedInUser', JSON.stringify(action.data));

            return {
                ...state,
                loggedInUser: action.data,
                isAuthed: true
            };
        case SET_PROFILE_DATA:
            Storage.setItem('loggedInUser', JSON.stringify({
                ...state.loggedInUser,
                ...action.data
            }));

            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    ...action.data
                }
            };

        case SET_USER_FULLNAME:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    ...(action.nameType === 'firstname' &&{firstName: action.fullName}),
                    ...(action.nameType === 'lastname' &&{lastName: action.fullName})
                }
            };
        case SET_LOGGING_OUT:
            return {
                isAuthed: false,
                loggedInUser: {},
                userToken: null,
                isLoggingOut: true
            };
        case SET_USER_ROLE:
            return {
                ...state,
                loggedInUser: {
                    ...state.loggedInUser,
                    userRole: action.userRole
                },
                userToken: {
                    ...state.userToken,
                    userRole: action.userRole
                }
            };
        default:
            return state;
    }
}
