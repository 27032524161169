// @flow
import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import type { ButtonVariant } from '../../../types';
import './alert.scss';

type Props = {
    className?: string,
    variant?: string,
    props?: any,
    children: any,
    hasButton?: boolean,
    buttonLabel?: string,
    buttonAction?: Function,
    buttonClass?: string,
    buttonVariant?: ButtonVariant,
    isShow?: boolean,
    toggle?: Function
}

export default function AppAlert({ variant, className = '', children, hasButton, buttonLabel, buttonAction, buttonClass = '', buttonVariant, isShow, toggle }: Props): React$Element<any> {
    const [show, setShow] = useState(isShow !== undefined ? isShow : true);
    const toggleShow = () => setShow(!show);

    const handleAction = () => {
        buttonAction && buttonAction();

        toggle ? toggle() : toggleShow();
    };

    return (
        <div className={`custom-alert ${className}`}>
            <Toast className={`w-100 bg-${variant || 'primary'} text-${variant === 'light' ? 'dark' : 'light'}`}>
                <Toast.Body className={hasButton && 'toast-body-has-button'}>
                    <div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Typography
                                className={`d-inline-block ${typeof children === 'string' && hasButton ? 'custom-alert-text' : ''}`}
                                variant='size-16'
                                color={variant === 'light' ? 'color-2' : variant === 'link' ? 'color-1' : 'color-3'}
                                weight='regular'>
                                {children}
                            </Typography>
                        </div>
                    </div>
                    {
                        hasButton && <Button variant={buttonVariant} textSize='size-16' label={buttonLabel}
                            onClick={handleAction}
                            className={`custom-alert-button px-4 py-3 text-uppercase d-block mx-auto cursor-pointer ${buttonClass}`} />
                    }
                </Toast.Body>
            </Toast>
        </div>
    );
}
