/* eslint-disable camelcase */

// CONSTANTS
export const SET_CREATE_TODO = "SET_CREATE_TODO";
export const SET_REMOVE_TODO = "SET_REMOVE_TODO";

// this is to Add todo at runtime
export function setCreateTodo(todoDetails: any): any {
    return { type: SET_CREATE_TODO, todoDetails };
}

// this is to Add todo at runtime
export function setRemoveTodo(todoDetails: any): any {
    return { type: SET_REMOVE_TODO, todoDetails };
}

// clear the state object for questionnaireMetaData , set it to initial
