export const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
    "image/bmp",
    "image/gif",
    "image/tiff",
]; // Add allowed file types here

export const invalidFileExtensions = ["html", "csv", "doc", "xlsx"];
