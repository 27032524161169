import Typography from "src/components/base/Typography/Typography";

type Props = {
    className?: string;
    insurancePlans?: string;
    serviceType?: string;
    meetingPreference?: string;
    worksWithAges?: string;
    allOfficeLocations?: string;
    clientMessage?: string;
};

export function TherapistSelectionCriteriaInfo({
    className = "",
    insurancePlans,
    serviceType,
    meetingPreference,
    worksWithAges,
    allOfficeLocations,
    clientMessage,
}: Props) {
    return (
        <div className={`${className}`}>
            {insurancePlans && (
                <div className="mb-4">
                    <Typography>
                        Insurance:
                        <br />
                        <Typography weight="bold">{insurancePlans}</Typography>
                    </Typography>
                </div>
            )}

            {serviceType && (
                <div className="mb-4">
                    <Typography>
                        Service Type
                        <br />
                        <Typography weight="bold">{serviceType}</Typography>
                    </Typography>
                </div>
            )}
            {meetingPreference && (
                <div className="mb-4">
                    <Typography>
                        Meeting Preference:
                        <br />
                        <Typography weight="bold">
                            {meetingPreference}
                        </Typography>
                    </Typography>
                </div>
            )}
            {worksWithAges && (
                <div className="mb-4">
                    <Typography>
                        Age:
                        <br />
                        <Typography weight="bold">{worksWithAges}</Typography>
                    </Typography>
                </div>
            )}
            {allOfficeLocations && (
                <div className="mb-4">
                    <Typography>
                        Office Location(s): <br />
                        <Typography weight="bold">
                            {allOfficeLocations}
                        </Typography>
                    </Typography>
                </div>
            )}

            {clientMessage && (
                <div className="mb-4 pl-4 pr-4">
                    <Typography>
                        Availability and Concerns:
                        <br />
                        <Typography weight="bold">{clientMessage}</Typography>
                    </Typography>
                </div>
            )}
        </div>
    );
}
