// @flow
import React from 'react';
import Button from '../../components/base/Button/Button';
// import { isValidEmail } from '../../../utils/helpers';
import './confirmation-modal.scss';
import Typography from '../../components/base/Typography/Typography';
import { sanitiseHTML } from '../../utils/helpers';

type Props = {
    className?: string,
    onChange?: Function,
    clickedOnYes: Function,
    clickedNo: Function,
    isProcessing?: boolean,
    text: any,
    descriptionNote?: String,
    yesButtonText?:String,
    noButtonText?:String,
    hasNoButton?:Boolean,
    variant:String,
    childElement:any,
    primaryButtonVariant:String,
}

export default function ConfirmationModal({ className = '', clickedOnYes, isProcessing, clickedNo,text, descriptionNote,yesButtonText='Yes',noButtonText='No',hasNoButton=true,variant,childElement,primaryButtonVariant }: Props): React$Element<any> {

    return (
        <div className={`confirmation-modal m-3 ${className}`}>
            <Typography
                variant={variant || 'size-18'}
                color='color-2'
                weight='regular'
                className='mt-5 mb-4'>
                {text && sanitiseHTML(text)}
            </Typography>
            <div className='d-flex mt-2 mb-4 col-8 ms-auto me-auto child-wrapper'>
                {childElement}
            </div>
            <div className='confirmation-modal__buttons d-flex'>
                <Button
                    disabled={isProcessing}
                    label={yesButtonText}
                    variant={primaryButtonVariant ||'success'}
                    textWeight='bold'
                    textSize='size-14'
                    isProcessing={isProcessing} className='m-3 p-2 btn-yes'
                    onClick={clickedOnYes}/>
                {hasNoButton && <Button
                    disabled={isProcessing}
                    label={noButtonText}
                    variant='light'
                    textWeight='bold'
                    textSize='size-14'
                     className='m-3 confirmation-modal__no-button'
                    onClick={clickedNo} />}
            </div>
            <Typography
                variant='size-18'
                color='color-28'
                weight='extra-light '
                className='m-5'>
                {descriptionNote}
            </Typography>
                
        </div>
    );
}
