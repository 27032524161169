import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import routes from "../../config/routes";
import ModalContainer from "../../components/base/ModalContainer/ModalContainer";
import AppLoadingContainer from "../AppLoading/AppLoadingContainer";
import Header from "../../components/base/Header/Header";
import Offline from "../../components/base/Offline/Offline";
import "./app.scss";
import FlashNotification from "../../components/modules/FlashNotification/FlashNotification";
import { clearModalContent } from "../../redux/modules/modalEvent";
import Storage from "../../utils/Storage";
import { storageKey, userServiceSection } from "../../config/constants";
import { useIdleTimer } from "react-idle-timer";
import { checkAuthorization, GetUserLogOut } from "../../utils/Authentications";
import { setUserRole } from "../../redux/modules/authentication";
import { fetchUserInformationByIdV1 } from "../../api/user";
import { ErrorBoundary } from "react-error-boundary";
import LoggingError from "../../components/modules/LoggingError/LoggingErrorContainer";
import "../../styles/styles.scss";
import { setFlashNotification } from "../../redux/modules/flashNotification";
import _ from "lodash";
import { Loader } from "src/components/base/Loader/Loader";
import withClearCache from "../../ClearCache";
// import { checkServiceWorkerUpdates } from "../..";
import {
    setAppConfig,
    toggleAppConfigLoader,
} from "src/redux/actions/configurationActions";
import { generateAppConfig } from "src/latest/src/core";
import { getAppConfig } from "src/redux/services/commonServices";
import { AppConstant, shouldLoggoutAndRedirectRoutes } from "src/latest/src";

const ClearCacheComponent = withClearCache(MainApp);
const loading = () => <div />;

function MainApp() {
    const dispatch = useDispatch();
    // const location = useLocation();
    const isOnline = true;
    const isHeaderVisible = useSelector(
        (state) => state?.questionnaireData?.currentConfig?.header.visible
    );
    const navigate = useNavigate();
    const popupLoaderStatus = useSelector(
        (state) => state?.configuration?.showPopupLoader
    );
    const tenantBackgroundColor = useSelector(
        (state) => state?.configuration?.tenantBackgroundColor
    );
    const { isAuthed, loggedInUser, userToken } = useSelector(
        ({ authentication }) => authentication
    );
    const [isTimedOut, setIsTimedOut] = useState(false);
    const [appConfigLoaded, setAppConfigLoaded] = useState(false);
    const refIdle = useRef();
    const timeout = 900000;
    const { title, modalContent, onToggle, ...modalEvent } = useSelector(
        ({ modalEvent }) => modalEvent
    );
    const [profileInfo, setProfileInfo] = useState(loggedInUser);
    const profileType = window.location.pathname.includes("client")
        ? "client"
        : "therapist";
    const profileId = localStorage.getItem("profileId");

    // Scroll to top if path changes
    useEffect(() => {
        document.body.scrollTop = 0; // enforcing the page to always scroll to top when a new page open
    }, [window.location.pathname]);

    // Getting the APP Config
    const fetchAppConfig = async () => {
        try {
            dispatch(toggleAppConfigLoader(true));
            const appConfig = await getAppConfig();
            const clientAppConfig = generateAppConfig(appConfig?.config_values);

            sessionStorage.setItem("TENANT_ID", clientAppConfig.tenantId);

            dispatch(setAppConfig(clientAppConfig));
        } finally {
            initApp();
            dispatch(toggleAppConfigLoader(false));
            setAppConfigLoaded(true);
        }
    };

    useEffect(() => {
        window?.addEventListener("scroll", handleScroll, true);

        fetchAppConfig();

        return () => {
            window?.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    const initApp = () => {
        handleRedirections();

        if (!profileInfo || _.isEmpty(profileInfo)) {
            onGetProfileInfo(profileType, profileId);
        }
    };

    const handleRedirections = () => {
        if (isAuthed) {
            if (shouldLoggoutAndRedirectRoutes) {
                handleLogout("SESSION_EXPIRED", false, true);
            }
        }
    };

    const handleCloseModal = () => {
        dispatch(clearModalContent());
        onToggle && onToggle();
    };

    const handleLogout = async (type, showMessage?: boolean, disableRedirect?: boolean) => {
        await GetUserLogOut(loggedInUser.id, type, showMessage);

        if (!disableRedirect) {
            console.log("AppContainer (handleLogout): Redirecting to Login");

            nextPath("/");
        }
    };

    const nextPath = (path: string) => {
        return navigate(path);
    };

    const handleSwitchRole = async (role: string) => {
        await dispatch(setUserRole(role));
        // const roleTo =role?.toLowerCase();

        if (userToken) {
            Storage.setItem(
                storageKey.accessToken,
                JSON.stringify({ ...userToken, userRole: role })
            );
        }

        nextPath(`/therapy/${role?.toLowerCase()}`);
    };

    const handleTimeOutAction = () => {
        setIsTimedOut(false);
    };

    const handleIdle = async () => {
        if (isTimedOut) {
            dispatch(clearModalContent());
            await handleLogout("SESSION_EXPIRED");
        } else {
            refIdle.reset();
            setIsTimedOut(true);
        }
    };

    const handleScroll = () => {
        const collapseHeader = document.querySelector("button.navbar-toggler");
        const collapseMenu = document.getElementById("responsive-navbar-nav");

        if (collapseHeader && collapseMenu) {
            collapseHeader.classList.add("collapsed");
            collapseMenu.classList.remove("show");
        }
    };

    //    useEffect(() => {
    //        const unlisten = navigate(() => {
    //            // Check for a new service worker
    //            checkServiceWorkerUpdates();
    //        });

    //        return () => {
    //            unlisten(); // Clean up the listener when the component unmounts
    //        };
    //    }, [navigate]);

    const onGetProfileInfo = async (type, id) => {
        try {
            if (isAuthed && id && loggedInUser?.user_role) {
                const resp = await fetchUserInformationByIdV1(
                    id,
                    userServiceSection.profile_display
                );

                setProfileInfo(resp);
            }
        } catch (error) {
            dispatch(
                setFlashNotification({
                    message:
                        error?.response?.data?.message ||
                        "Something went wrong please try again!",
                    isError: true,
                    dispatchClass: "notif-settings",
                })
            );
        }
    };

    function IdleTimerComponent({
        isAuthed,
        isAuthorized,
        pathname,
        onTimeOutAction,
        onIdle,
    }) {
        // eslint-disable-next-line no-unused-vars
        let refIdle = null;

        useIdleTimer({
            ref: (ref) => {
                refIdle = ref;
            },
            element: document,
            onActive: onTimeOutAction,
            onIdle,
            onAction: onTimeOutAction, // Optional, same as onIdle
            debounce: 250,
            timeout,
        });

        return null; // This component doesn't render anything directly
    }

    return (
        <ErrorBoundary FallbackComponent={LoggingError}>
            <Loader modalStatus={popupLoaderStatus || !appConfigLoaded} />
            {appConfigLoaded && (
                <AppLoadingContainer>
                    {!isOnline &&
                        !/\/meeting-room|\/waiting-room/.test(
                            window.location.pathname
                        ) && (
                            <ModalContainer
                                className="offline-modal text-center"
                                isShow={true}
                                size="lg"
                            >
                                <Offline variant="light" />
                            </ModalContainer>
                        )}
                    <div
                        style={{
                            backgroundColor:
                                tenantBackgroundColor ||
                                AppConstant.defaultAppBackgroundColor,
                        }}
                        className={`page-container ${
                            window.location.pathname.includes("meeting-room")
                                ? "remove-top-margin"
                                : ""
                        }`}
                    >
                        {!/\/meeting-room|\/waiting-room|\/greetings/.test(
                            window.location.pathname
                        ) &&
                            isHeaderVisible && (
                                <Header
                                    accountName={loggedInUser.name}
                                    isAuthed={isAuthed}
                                    onLogout={handleLogout}
                                    handleSwitchRole={handleSwitchRole}
                                    className="bg-white"
                                    profileInfo={profileInfo || {}}
                                />
                            )}
                        <React.Suspense fallback={loading()}>
                            <Routes>
                                {routes.map((route, i) => (
                                    <Route
                                        key={i} // Ensure unique keys
                                        exact={route.exact}
                                        path={route.path}
                                        element={
                                            <route.component
                                                loggedInUser={
                                                    route.loggedInUser
                                                }
                                            />
                                        }
                                    />
                                ))}
                            </Routes>
                        </React.Suspense>
                    </div>
                    <FlashNotification />
                    <ModalContainer
                        {...modalEvent}
                        header={title}
                        isShow={!!modalContent}
                        toggle={handleCloseModal}
                    >
                        {modalContent}
                    </ModalContainer>
                    {isAuthed &&
                        checkAuthorization() &&
                        !/\/waiting-room|\/meeting-room/.test(
                            window.location.pathname
                        ) && (
                            <>
                                {/* Use the useIdleTimer hook here */}
                                <IdleTimerComponent
                                    onTimeOutAction={handleTimeOutAction}
                                    onIdle={handleIdle}
                                />
                            </>
                        )}
                </AppLoadingContainer>
            )}
        </ErrorBoundary>
    );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export function RouteWithSubRoutes(route) {
    return route.routes ? (
        <>
            {route.routes.map((nestedRoute, i) => (
                <Route
                    key={i}
                    path={nestedRoute.path}
                    element={
                        <nestedRoute.component
                            routes={nestedRoute.routes}
                            loggedInUser={nestedRoute.loggedInUser}
                        />
                    }
                />
            ))}
        </>
    ) : (
        <Route
            path={route.path}
            element={
                <route.component
                    routes={route.routes}
                    loggedInUser={route.loggedInUser}
                />
            }
        />
    );
}

function App() {
    return <ClearCacheComponent />;
}

export default App;
