// @flow
import React from "react";
import ConfirmationModal from "./ConfirmationModal";
// import { useDispatch, useSelector } from 'react-redux';

type Props = {
    className?: string,
    noteBelow?: String,
    clickedYes: Function,
    clickedOnNo: Function,
    yesButtonText?: String,
    noButtonText?: String,
    hasNoButton?: Boolean,
    variant?: String,
    primaryButtonVariant?: String,
    primaryButtonLabel?: String,
    children?: ReactElement,
    dynamicText: String
};

export default function ConfirmationModalContainer({
    className = "",
    clickedYes,
    clickedOnNo,
    dynamicText,
    noteBelow,
    yesButtonText,
    noButtonText,
    hasNoButton,
    variant,
    primaryButtonVariant,
    children,
}: Props): React$Element<any> {
    return (
        <ConfirmationModal
            clickedOnYes={clickedYes}
            clickedNo={clickedOnNo}
            text={dynamicText}
            className={className}
            descriptionNote={noteBelow}
            yesButtonText={yesButtonText}
            noButtonText={noButtonText}
            hasNoButton={hasNoButton}
            variant={variant}
            primaryButtonVariant={primaryButtonVariant}
            childElement={children}
        />
    );
}
