// @flow
import React from 'react';
import Typography from '../../base/Typography/Typography';
import './logging-error.scss';

import errorIcon from './../../../assets/icons/illustration-error.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setFlashNotification } from '../../../redux/modules/flashNotification';

type Props = {
    error: Object,
    resetErrorBoundary: Object,
    location?: string
}

export default function LoggingError({ error, resetErrorBoundary, location }: Props): React$Element<any> {
    const isProduction =  false; // process.env.NODE_ENV !== 'development'; // update the condition as per required
    const dispatch = useDispatch();

    const CopyToClipboard = () => {
        navigator.clipboard.writeText('support@i2iconnect.com').then(() => {
            dispatch(setFlashNotification({
                message: 'Email has been copied to clipboard.',
                isError: false,
                duration:2000
            }));
        });
    };

    return (
        <div className='logging-error d-flex justify-content-center align-items-center w-100' >
            <div className='rectangle'>
                <div className='error-icon-holder'><img src={errorIcon} alt='error icon' /></div>
                <div className='error-message-container'>
                    {!isProduction && <div>
                        {(error?.stack?.split('@')[0]?.replace('/<', '') || location).includes('Loading chunk') ? <div className='logging-error__chunk mt-3'>
                            <Typography>Please try to refresh your browser or <a href='/support/technical'>contact us</a> for assistance</Typography> </div> : <React.Fragment>
                            <div contentEditable={true} className='logging-error__code mt-3 ms-3 me-3'>
                                <code>{location ? JSON.stringify(error) : error.message}</code>{location ? <br/> : '@'}
                                <code>{error?.stack?.split('@')[0]?.replace('/<', '') || location}</code>
                            </div>
                        </React.Fragment>
                        }
                    </div>}
                    {isProduction && <div className='error-message'>
                        It looks like you've encountered an error, please try again in a few minutes. If the problem persists, please contact us at:
                    </div>}
                </div>
                <div className='contact-us'>
                    <div className='rectangle-card'>
                        <div className='content-holder' onClick={CopyToClipboard}>
                            <FontAwesomeIcon className='icon-email' icon={faEnvelope} />
                            <div className='support-email'>support@i2iconnect.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
