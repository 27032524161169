import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmationModalContainer from "../../../../ConfirmationModal/ConfirmationModalContainer";
import { setModalContent } from "../../../../../redux/modules/modalEvent";

type Props = {
    handleOnboardingStatus: Function;
    buttonLabel: String;
};

const descriptionContent = {
    resumeOnboarding: "If you resume onboarding for this user, they will return to<br/> their most recent onboarding status. <br/> <br/> To resend an email with a link for the user to continue their <br/>onboarding process, invite them again from the scheduler dashboard.",
    pauseOnboarding: "This user will be marked as paused. This will cancel any outstanding match requests and this user will not receive any further automated onboarding reminders from i2iConnect.",
    cancelOnboarding: "Are you sure that you want to cancel the onboarding process for this patient?"
};

export function PauseOnboardingModal({
    handleOnboardingStatus,
    buttonLabel,
}: Props) {
    const dispatch = useDispatch();
    const [note, setnote] = useState("");
    const [updateStatus, setUpdateStatus] = useState(false);

    useEffect(() => {
        setUpdateStatus(false);
    }, []);

    const editNoteContent = (e: any) => {
        setnote(e.target.value);
    };

    function getDynamicText(): string {
        switch (buttonLabel) {
            case "Resume Onboarding":
                return descriptionContent.resumeOnboarding;
            case "Pause Onboarding":
                return descriptionContent.pauseOnboarding;
            case "Cancel Onboarding":
                return descriptionContent.cancelOnboarding;
            default:
                return "";
        }
    }

    const handleSubmitUpdateOnboardingStatus = () => {
        updateStatus === false && handleOnboardingStatus(note);
        setUpdateStatus(true);
    };

    return (
        <ConfirmationModalContainer
            hasNoButton={buttonLabel === "Resume Onboarding"}
            clickedYes={handleSubmitUpdateOnboardingStatus}
            clickedOnNo={() =>
                dispatch(setModalContent({ modalContent: null }))
            }
            dynamicText={
                getDynamicText()
            }
            children={
                buttonLabel !== "Resume Onboarding" && (
                    <textarea
                        onChange={editNoteContent}
                        value={note}
                        style={{ height: "150px" }}
                        placeholder="Add note (optional)"
                        maxLength={500}
                    />
                )
            }
            className="pb-0"
            primaryButtonVariant={
                buttonLabel === "Resume Onboarding" ? "success" : "danger"
            }
            yesButtonText={
                (buttonLabel === "Resume Onboarding" || buttonLabel === "Cancel Onboarding") ? "Yes" : buttonLabel
            }
        />
    );
}
