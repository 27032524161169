import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getAuthToken,
    saveAuthorizationToken,
} from "../../utils/Authentications";
import {
    updateUserToken,
    authUser,
    updateLoggedInUser,
} from "../../redux/modules/authentication";
import WrapperBackground from "../../components/base/WrapperBackground/WrapperBackground";
import Status from "../../components/base/Status/Status";
import Storage from "../../utils/Storage";
import Spinner from "react-bootstrap/Spinner";
import "./app-loading.scss";
import { userServiceSection } from "../../config/constants";
import { fetchUserInfoV1 } from "../../api/user";
import { Logger } from "src/services";
import {
    privateRoutes,
    publicRoutes,
    shouldLoggoutAndRedirectRoutes,
    shouldLoggoutRoutes,
} from "src/latest/src";

type Props = {
    children: any,
    route: string,
};

export default function AppLoadingContainer({
    children,
}: Props): any | React$Element<"div"> {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useNavigate();

    const { isAuthed } = useSelector(
        ({ authentication }) => authentication
    );

    const [isAppReady, setAppReady] = useState(false);
    const [isErrorLoaded, setErrorLoaded] = useState(false);

    useEffect(() => {
        setErrorLoaded(false);

        if (shouldLoggoutAndRedirectRoutes) {
            setAppReady(true);
        } else {
            checkAuth();
        }
    }, []);

    useEffect(() => {
        const excludedURLs = [
            "initquestions",
            "error",
            "sign-up",
            "login",
            "greetings",
            "opt-out",
            "redirect",
            "logout",
            "termsofservices",
        ];
        const matchedUrl = excludedURLs.filter((url) =>
            window.location.pathname?.includes(url)
        );

        const urlMatched =
            matchedUrl.length !== 0 && window.location.pathname !== "/";

        try {
            if (!urlMatched) {
                if (Storage.getItem("qrCode")) {
                    history(Storage.getItem("signup-url"));
                }
            }
        } catch (error) {
            Logger.log("AppLoadingContainer", { error });
        }

        const host = sessionStorage.getItem("host");

        if (host && isAuthed) {
            history("/redirect"); // go to redirect
        }
    }, [window?.location?.pathname]);

    const checkAuth = async () => {
        try {
            const accessToken = getAuthToken();
           
            const userDetails: Object =
                shouldLoggoutRoutes &&
                Object.keys(accessToken || {}).length === 0 &&
                !window.location.pathname.includes("therapy") &&
                !Storage.getItem("required2FA")
                    ? null
                    : accessToken || null;

            if (userDetails) {
                saveAuthorizationToken(userDetails);
                dispatch(updateUserToken(userDetails));
            }

            if (
                userDetails?.hasOwnProperty("id") &&
                !Storage.getItem("required2FA") &&
                !Storage.getItem("signup-url")
            ) {
                const profile = await fetchUserInfoV1(
                    userServiceSection.profile_display
                );

                let role = profile?.user_type?.includes("THERAPIST")
                    ? "therapist"
                    : profile?.user_type?.includes("SCHEDULER")
                    ? "scheduler"
                    : "client";

                if (userDetails && userDetails.userRole)
                    role = userDetails.userRole;

                dispatch(
                    authUser({
                        ...profile,
                        userRole: role,
                        photoUrl: userDetails?.photoUrl || "",
                    })
                );
                dispatch(
                    updateLoggedInUser({
                        ...profile,
                        userRole: role,
                        photoUrl: userDetails?.photoUrl || "",
                    })
                );

                if (!privateRoutes && !shouldLoggoutRoutes){
                console.log("AppLoadingContainer (checkAuth): Redirecting to ", /client|therapist/.test(role));
                    history(
                        /client|therapist/.test(role) ? `/therapy/${role}` : "/"
                    );
                }
            } else {
                if (
                    !shouldLoggoutRoutes &&
                    !location.pathname.includes("error") &&
                    !Storage.getItem("required2FA") &&
                    !Storage.getItem("signup-url") &&
                    !publicRoutes
                ) {
                    console.log(
                        "AppLoadingContainer (checkAuth): Redirecting to Login"
                    );
                    history("/login");
                }
            }

            setAppReady(true);
        } catch (err) {
            setAppReady(true);
        }
    };

    if (isAppReady) {
        return isErrorLoaded ? (
            <WrapperBackground>
                <div className="app-loading__error">
                    <Status status="Page error" variant="light" />
                </div>
            </WrapperBackground>
        ) : (
            children
        );
    }

    return (
        <WrapperBackground className="app-loading">
            <Spinner animation="border" role="status"/>
        </WrapperBackground>
    );
}
