import {
    FilterModel,
    FilterSelectionDataModel,
    SelectedFilters,
} from "../types";

export function getServiceTypeFromFilterForSlot(
    selectedFilters: SelectedFilters,
    serviceType: string
): FilterSelectionDataModel | undefined {
    if (selectedFilters?.filters) {
        const serviceFilterData: FilterModel = selectedFilters?.filters?.filter(
            (filter: any) => filter.filter_key === "serviceType"
        )[0];
        const selectedService: FilterSelectionDataModel | undefined =
            serviceFilterData
                ? serviceFilterData?.filter_selection_data?.filter(
                      (filter: any) => filter.unique_key === serviceType
                  )[0]
                : undefined;

        return selectedService;
    }
}
