// @flow

import {
    SET_APP_CONFIG,
    TOGGLE_POPUP_LOADER,
    TOGGLE_APP_CONFIG_LOADER,
    SET_SUPPORT_CONFIG,
} from "../actions/configurationActions";

interface ConfigurationStateType {
    showPopupLoader: boolean;
    fetchingAppConfig: boolean;
    practiceSupport: any;
    technicalSupport: any;
    questionnaireService: any;
}

const initialState: ConfigurationStateType = {
    showPopupLoader: false,
    fetchingAppConfig: false,
    practiceSupport: undefined,
    technicalSupport: undefined,
    questionnaireService: undefined,
};

export default function configurationStore(
    state: Object = initialState,
    action: any
): any {
    switch (action.type) {
        case TOGGLE_POPUP_LOADER: {
            const status = action.status;

            return { ...state, ...{ showPopupLoader: status } };
        }

        case TOGGLE_APP_CONFIG_LOADER: {
            const status = action.status;

            return { ...state, ...{ fetchingAppConfig: status } };
        }

        case SET_APP_CONFIG: {
            const config = action.appConfig;

            return { ...state, ...config };
        }

        case SET_SUPPORT_CONFIG: {
            const config = action.config;

            return { ...state, ...config };
        }

        default:
            return state;
    }
}
