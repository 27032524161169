import { get } from "lodash";
import makeApiRequest from "../../api/makeApiCall";
import { config, storageKey } from "../../config/constants";
import { blobToBase64, getRefreshToken } from "../../utils/helpers";
import Storage from "../../utils/Storage";
import { Logger } from "src/services";

const USER_SERVICE_URL = config.userService;
const token = JSON.parse(Storage.getItem(storageKey.accessToken) || "{}");
const USERS_SERVICE = config.userService;
const LOGGING_SERVICE = config.loggingService;
const THERAPY_SESSIONS_SERVICE = config.therapysessionservieces;
const CONFIGURATION_SERVICE = config.configurationService;

export const getAppConfig = async () => {
    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations/public`,
        "GET"
    );
};

export const getSupportConfig = async () => {
    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations?module=technical-support,practice-support,questionnaires-service`,
        "GET"
    );
};

export const patchTenantName = async (newTenantName: any) => {
    const requestBody = {
        config_name: "TENANT_NAME",
        config_value: newTenantName,
    };

    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations/config-keys/TENANT_NAME`,
        "PATCH",
        requestBody
    );
};

export const patchScheduling = async (updatedSchedulingData: {
    title?: string;
    email?: string;
    phone?: string;
}) => {
    const requestBody = {
        config_name: "SCHEDULING",
        config_value: JSON.stringify(updatedSchedulingData),
    };

    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations/config-keys/SCHEDULING`,
        "PATCH",
        requestBody
    );
};

export const patchSupport = async (updatedSupportData: {
    title?: string;
    email?: string;
    phone?: string;
}) => {
    const requestBody = {
        config_name: "PROVIDER_REMATCHED",
        config_value: JSON.stringify(updatedSupportData),
    };

    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations/config-keys/PROVIDER_REMATCHED`,
        "PATCH",
        requestBody
    );
};

export const patchbilling = async (updatedBillingData: {
    title?: string;
    email?: string;
    phone?: string;
}) => {
    const requestBody = {
        config_name: "BILLING",
        config_value: JSON.stringify(updatedBillingData),
    };

    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations/config-keys/BILLING`,
        "PATCH",
        requestBody
    );
};

export const patchFeedback = async (updatedFeedbackData: {
    title?: string;
    email?: string;
    phone?: string;
}) => {
    const requestBody = {
        config_name: "FEEDBACK",
        config_value: JSON.stringify(updatedFeedbackData),
    };

    return await makeApiRequest(
        `${CONFIGURATION_SERVICE}configurations/config-keys/FEEDBACK`,
        "PATCH",
        requestBody
    );
};

export const uploadPicture = async (data: any) => {
    return await makeApiRequest(
        `${USER_SERVICE_URL}user/profile-pic/upload`,
        "POST",
        data
    );
};

export const getPicture = async (fileKey: string) => {
    const imageResponse = await fetch(
        `${config.apiUrl + "/" + USER_SERVICE_URL
        }user/profile-pic/download/${fileKey}`,
        {
            method: "GET",
        }
    );
    const imageSrc = await imageResponse.blob();
    const img = await blobToBase64(imageSrc);

    return img;
};

export const getPictureURL = async (fileKey: string) => {
    return `${config.apiUrl + "/" + USER_SERVICE_URL
        }user/profile-pic/download/${fileKey}`;
};

export const getUserPicture = async (userId: string) => {
    const authToken = `Bearer ${get(token, "access_token", "") || localStorage.getItem("t2fa")
        }`;

    const imageResponse = await fetch(
        `${config.apiUrl + "/" + USERS_SERVICE}user/${userId}/profile-picture`,
        {
            method: "GET",
            headers: { Authorization: authToken },
        }
    );

    const imageSrc = await imageResponse.blob();

    if (imageSrc.size === 0 && imageSrc.type === "") {
        return "";
    } else {
        const img = await blobToBase64(imageSrc);

        return img;
    }
};

export const getLastLoginDetail = async (userID: string) => {
    return await makeApiRequest(
        `${LOGGING_SERVICE}activity-log/findRecords?offset=1&limit=1`,
        "POST",
        { action: "Logged in", actedByUserId: userID }
    );
};

export const getTherapySessionStatus = async (sessionId: string) => {
    return await makeApiRequest(
        `${THERAPY_SESSIONS_SERVICE}/therapy-sessions/${sessionId}`,
        "GET"
    );
};

// Document Service :-
// = ===========================
// UPLOAD
// 1. Get PreSigned URL -> getPreSignedUrl()
// 2. Upload Image to Presined url -> uploadImagePresignedUrl()
// 3. Confirm Uploading to Presined url

export const getPreSignedUrl: any = async (
    fileType: string,
    file: any,
    userId: string,
    intakeUserId: string,
    isFromProfile = false
) => {
    const tokenKey = JSON.parse(
        Storage.getItem(storageKey.accessToken) || "{}"
    );
    const accessToken =
        get(tokenKey, "access_token", "") || localStorage.getItem("t2fa");

    const headers = new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
    });
    const options = {
        method: "POST",
        headers,
        body: JSON.stringify({
            file_type: fileType,
            mime_type: file.type,
            name: file.name,
            size_byte: file.size,
        }),
        retryOn: [401],
        retries: 3,
        retryDelay: 1000,
    };
    let apiResponse: any = null;

    const queryParams = new URLSearchParams();

    const location = window.location.pathname === "/therapy/initquestions";

    if (intakeUserId && location) {
        queryParams.append("user_id", intakeUserId);
    }

    const apiUrl =
        intakeUserId && location
            ? `${config.apiUrl + "/" + USERS_SERVICE}v1/document?${queryParams}`
            : `${config.apiUrl + "/" + USERS_SERVICE}v1/document`;

    return await fetch(apiUrl, options).then(async (response) => {
        if (response.status === 200) {
            const responseUrl = await response.json();

            if (responseUrl.signed_url) {
                const uploadFinalStatus: any =
                    await uploadDocumentWithPreSignedUrl(
                        responseUrl.id,
                        decodeURIComponent(responseUrl.signed_url),
                        file
                    );
                const updateUploadStatusToServer = await updateUploadStatus(
                    uploadFinalStatus?.documentId,
                    uploadFinalStatus?.failureReason,
                    uploadFinalStatus?.isUploaded
                );

                Logger.log(updateUploadStatusToServer);

                if (uploadFinalStatus.isUploaded) {
                    let patchAndGetUserDetails;

                    if (isFromProfile === true) {
                        if (!userId) {
                            apiResponse = "";

                            return apiResponse;
                        }

                        patchAndGetUserDetails = await patchUserProfilePic(
                            responseUrl.id,
                            userId
                        );
                    } else {
                        if (!userId && !intakeUserId) {
                            apiResponse = "";

                            return apiResponse;
                        }

                        patchAndGetUserDetails = await patchUserProfilePic(
                            responseUrl.id,
                            userId,
                            intakeUserId
                        );
                    }

                    apiResponse = patchAndGetUserDetails.profile_pic;

                    return apiResponse;
                } else {
                    apiResponse = "";

                    return apiResponse;
                }
            }

            return apiResponse;
        } else if (response.status === 401) {
            // retry uploading
            const isRefreshToken = await getRefreshToken();

            return (
                isRefreshToken &&
                (await getPreSignedUrl(fileType, file, userId))
            );
        }
    });
};

export const uploadDocumentWithPreSignedUrl = async (
    documentId: string,
    preSignedUrl: string,
    file: any
) => {
    const body = new FormData();

    body.append("file", file);

    const convertToBase64 = await blobToBase64(file);

    const binaryBlob = await fetch(convertToBase64)
        .then((response) => response.blob())
        .then((blob) => {
            return blob;
        });

    const uploadData = await fetch(preSignedUrl, {
        method: "PUT",
        body: binaryBlob,
    })
        .then((response) => {
            let data = {};

            if (response.status === 200) {
                data = {
                    documentId,
                    failureReason: null,
                    isUploaded: true,
                };
            } else if (!response.ok) {
                updateUploadStatus(
                    documentId,
                    response.statusText.toString(),
                    false
                );
                data = {
                    documentId,
                    failureReason: response.statusText,
                    isUploaded: false,
                };
            }

            return data;
        })
        .catch((err: any) => {
            Logger.error("failed response: ", err, err.status);

            return {
                documentId,
                failureReason: err.toString(),
                isUploaded: false,
            };
        });

    return uploadData;
};

export const updateUploadStatus = async (
    documentId: string,
    failureReason: string,
    isUploaded: boolean
) => {
    return await makeApiRequest(
        `${USERS_SERVICE}v1/document/${documentId}/_upload-status`,
        "PUT",
        { failure_reason: failureReason, uploaded: isUploaded }
    );
};

export const patchUserProfilePic = async (
    documentId: string,
    userId: string,
    intakeUserId?: string
) => {
    const location = window.location.pathname === "/therapy/initquestions";

    let SelectedUserId = "";

    if (intakeUserId && location) {
        SelectedUserId = intakeUserId;
    } else {
        SelectedUserId = userId;
    }

    return await makeApiRequest(
        `${USERS_SERVICE}v1/users/${SelectedUserId}`,
        "PATCH",
        {
            profile_pic_id: documentId,
        }
    );
};

export const resetUser2FA = async (
    userId: string,
) => {
    return await makeApiRequest(
        `${USERS_SERVICE}v1/users/${userId}/credentials/_reset-2fa`,
        "PUT",
    );
};

export const esignPDF = async (
    documentId: string,
    base64SignatureString: string
) => {
    return await makeApiRequest(
        `${USERS_SERVICE}v1/digital-document/${documentId}/signature`,
        "POST",
        { base64: base64SignatureString }
    );
};

export const esignCustomConsent = async (
    documentId: string,
    base64String: string
) => {
    return await makeApiRequest(
        `${USERS_SERVICE}v1/digital-document/${documentId}/generate`,
        "POST",
        { base64: base64String }
    );
};

export const deleteFile = async (fileId: string) => {
    return await makeApiRequest(
        `${USERS_SERVICE}v1/document/${fileId}`,
        "DELETE"
    );
};
