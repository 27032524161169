/* eslint-disable camelcase */
import type { Attendee } from '../../types';
import { capitalize } from '../../utils/helpers';

// CONSTANTS
export const SET_CLUSTER_SESSION_DATA = 'SET_CLUSTER_SESSION_DATA';
export const SET_CLUSTER_LIST_DATA = 'SET_CLUSTER_LIST_DATA';
export const SET_ACTIVE_RUNNING_SESSION_DATA = 'SET_ACTIVE_RUNNING_SESSION_DATA';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const SET_CLIENT_CHECKIN_QUESTIONNAIRE_DATA = 'SET_CLIENT_CHECKIN_QUESTIONNAIRE_DATA';
export const SET_CHIME_SESSION_DATA = 'SET_CHIME_SESSION_DATA';
export const SHOW_SELF_VIEW = 'SHOW_SELF_VIEW';
export const HIDE_SELF_VIEW = 'HIDE_SELF_VIEW';
export const UPDATE_LOCAL_VIDEO_POSITION = 'UPDATE_LOCAL_VIDEO_POSITION';
export const TELETHERAPY_SETTINGS_PAGE_STATUS = 'TELETHERAPY_SETTINGS_PAGE_STATUS';
export const BLUR_BACKGROUND = "BLUR_BACKGROUND";

// Set cluster session data
export function setClusterSessionData({ teleTherapySessionData }: { teleTherapySessionData: Object }): any {
    return {
        type: SET_CLUSTER_SESSION_DATA,
        teleTherapySessionData
    };
}

export function setClusterListData(clusterListData: { clusterListData: Object }): any {
    return {
        type: SET_CLUSTER_LIST_DATA,
        clusterListData
    };
}

export function setActiveRunningSessionData(activeRunningSessionData: { activeRunningSessionData: Object }): any {
    return {
        type: SET_ACTIVE_RUNNING_SESSION_DATA,
        activeRunningSessionData
    };
}

export function setClientData({ client_data }: { client_data: Objese }): any {
    return {
        type: SET_CLIENT_DATA,
        client_data
    };
}

export function setClientCheckinQuestionnaireData(client_checkin_questionnaire) {
    return {
        type: SET_CLIENT_CHECKIN_QUESTIONNAIRE_DATA,
        client_checkin_questionnaire
    };
}

export function setChimeSessionData(chime_data, isReadyToJoin) {
    return {
        type: SET_CHIME_SESSION_DATA,
        chime_data,
        isReadyToJoin
    };
}

/// /////////////////////////////////////////////////

export const SET_ATTENDEES = 'SET_ATTENDEES';
export const SET_WAITING_ROOM_MEMBERS = 'SET_WAITING_ROOM_MEMBERS';
export const SET_JOIN_DATA = 'SET_JOIN_DATA';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const UPDATE_AUDIO_VIDEO = 'UPDATE_AUDIO_VIDEO';
export const SET_VIDEO = 'SET_VIDEO';
export const SET_AUDIO = 'SET_AUDIO';
export const SET_MIC = 'SET_MIC';
export const SET_TILE_STATUS = 'SET_TILE_STATUS';
export const CLEAR_TILE_STATUS = 'CLEAR_TILE_STATUS';
export const SET_NEW_WAITING_ROOM_MEMBERS = 'SET_NEW_WAITING_ROOM_MEMBERS';
export const CLEAR_NEW_WAITING_ROOM_MEMBER = 'CLEAR_NEW_WAITING_ROOM_MEMBER';
export const SET_CLIENT_JOINED_IN = 'SET_CLIENT_JOINED_IN';
export const SET_START_SESSION = 'SET_START_SESSION';
export const SET_CLIENT_LEFT_THE_WAITING_ROOM = 'SET_CLIENT_LEFT_THE_WAITING_ROOM';
export const SET_MIC_DEVICE = 'SET_MIC_DEVICE';
export const SET_VIDEO_DEVICE = 'SET_VIDEO_DEVICE';
export const SET_AUDIO_DEVICE = 'SET_AUDIO_DEVICE';



export function setAttendees({ attendees }: { attendees: Array<Attendee> }): any {
    return {
        type: SET_ATTENDEES,
        attendees
    };
}

export function setWaitingRoomMembers({ waitingRoomMembers }: { waitingRoomMembers: Array<any> }): any {
    return {
        type: SET_WAITING_ROOM_MEMBERS,
        waitingRoomMembers
    };
}

export function setJoinData({ joinData, isReadyToJoin }: { joinData: Object, isReadyToJoin: boolean }): any {
    return {
        type: SET_JOIN_DATA,
        joinData,
        isReadyToJoin
    };
}

export function toggleAudioVideo({ mediaType }: { mediaType: 'audio' | 'video' | 'mic' }): any {
    return {
        type: UPDATE_AUDIO_VIDEO,
        mediaType: `is${capitalize(mediaType)}Enabled`
    };
}

export function setVideo(isVideoEnabled): any {
    return {
        type: SET_VIDEO,
        isVideoEnabled,
    };
}

export function setAudio(isAudioEnabled): any {
    return {
        type: SET_AUDIO,
        isAudioEnabled,
    };
}

export function setMic(isMicEnabled): any {
    return {
        type: SET_MIC,
        isMicEnabled,
    };
}

export function setClientLeftWaitingRoom({ leftWaitingRoomMembers }: { leftWaitingRoomMembers: Array<any> }): any {
    return {
        type: SET_CLIENT_LEFT_THE_WAITING_ROOM,
        leftWaitingRoomMembers
    };
}

export function setClientJoinedIn(): any {
    return {
        type: SET_CLIENT_JOINED_IN
    };
}

export function clearSession(): any {
    return {
        type: CLEAR_SESSION
    };
}

export function setNewWaitingRoomMembers({ newWaitingRoomMembers }: { newWaitingRoomMembers: Array<any> }): any {
    return {
        type: SET_NEW_WAITING_ROOM_MEMBERS,
        newWaitingRoomMembers
    };
}

export function clearNewWaitingRoomMembers(): any {
    return {
        type: CLEAR_NEW_WAITING_ROOM_MEMBER
    };
}

export function setStartSession({ startSession }: { startSession: Object }): any {

    return {
        type: SET_START_SESSION,
        startSession
    };
}

export function setMicDevice(micDevice): any{
    
    return {
        type: SET_MIC_DEVICE,
        micDevice
    };
}

export function setVideoDevice(videoDevice): any{
    
    return {
        type: SET_VIDEO_DEVICE,
        videoDevice
    };
}

export function setAudioDevice(audioDevice): any{
    
    return {
        type: SET_AUDIO_DEVICE,
        audioDevice
    };
}

// self view actions
export function showSelfView(){
    return {
        type: SHOW_SELF_VIEW
    };
}

export function hideSelfView(){
    return {
        type: HIDE_SELF_VIEW
    };
}


export function blurBackground(status){
    return {
        type: BLUR_BACKGROUND,
        status:status
    };
}

export function updateteleTherapySettingPageStatus(status:boolean){
    return {
        type: TELETHERAPY_SETTINGS_PAGE_STATUS,
        ...{status:status}
    };
}

export function updateLocalVideoPosition(position:any){

    return {
        type: UPDATE_LOCAL_VIDEO_POSITION,
        ...position
    };
}

