// @flow
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button/Button';
import type { ButtonVariant } from '../../../types';
import './modal-container.scss';
import CardWrapperForModal from '../CardWrapperForModal/CardWrapperForModal';
import { clearModalContent } from "../../../redux/modules/modalEvent";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { AppConstant } from 'src/latest/src';

type Size = 'sm' | 'md' | 'lg';

type Props = {
    className?: string,
    header?: any,
    children: any | React.Node,
    isWithCloseButton?: boolean,
    size: Size,
    isDemo?: boolean,
    isShow: boolean,
    toggle?: Function,
    isGeneralPopup?: boolean,
    isLoadingPopup?: boolean,
    confirmButtonLabel?: string,
    onConfirmButtonClick?: Function,
    confirmButtonVariant?: ButtonVariant,
    cancelButtonLabel?: string,
    onCancelButtonCLick?: Function,
    isWithCancelButton?: boolean,
    isProcessing?: boolean,
    isDisabledDragging?: boolean,
    isDisableClose?: Boolean,
    backDrop: any
};

export default function ModalContainer({
    className = '', header, children, isWithCloseButton = true, size = 'lg', isDemo = false, isShow = false, toggle, onCancelButtonCLick, isProcessing,
    confirmButtonLabel, onConfirmButtonClick, confirmButtonVariant, cancelButtonLabel, isWithCancelButton, isGeneralPopup = false, isLoadingPopup, isDisabledDragging = true, isDisableClose = false, backDrop
}: Props): React$Element<any> {
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(isShow);
    const tenantBackgroundColor = useSelector(
        (state) => state?.configuration?.tenantBackgroundColor
    );

    const handleClose = () => {
        !isDisableClose && toggle && toggle();
        !isDisableClose && setShow(!show);
    };

    const handleCancel = () => {
        onCancelButtonCLick && onCancelButtonCLick();
        handleClose();
        dispatch(clearModalContent());
    };

    const closeModal = (e) => {
        if(e.target.id === 'closeModalImg'){
            handleClose();
        }
    };

    return (
        <React.Fragment>
            {isDemo && <Button label="Show modal" onClick={handleClose} />}
            <Modal
                size={size}
                show={isDemo ? show : isShow}
                onHide={() => handleClose()}
                animation={false}
                backdropClassName={
                    className.includes("confirmation-modal")
                        ? "confirmation-modal-backdrop"
                        : "" ||
                          className.includes("client-report-modal-extended")
                        ? "client-report-backdrop"
                        : ""
                } // for setting page confirmation
                className={`modal-container ${className}`}
                centered={true}
                backdrop={backDrop || true}
            >
                <div className="modal-container__wrapper">
                    {header && (
                        <Modal.Header className="modal-container__header">
                            <div className="text">{header}</div>
                            {isWithCloseButton && (
                                <div
                                    className="modal-container__header-close cursor-pointer"
                                    onClick={handleClose}
                                >
                                    <FontAwesomeIcon width={14} height={14} icon={faClose} />
                                </div>
                            )}
                        </Modal.Header>
                    )}
                    <Draggable
                        handle=".card-wrapper"
                        axis="both"
                        disabled={isDisabledDragging}
                        onMouseDown={closeModal}
                    >
                        <div
                            style={{ backgroundColor: tenantBackgroundColor || AppConstant.defaultAppBackgroundColor }}
                        >
                            <Modal.Body
                                style={{ backgroundColor: tenantBackgroundColor || AppConstant.defaultAppBackgroundColor }}
                                className={`modal-container__body ${!header && "top_border_radius"}`}
                            >
                                <div className="text-center">
                                    {isGeneralPopup ? (
                                        <CardWrapperForModal isLoading={isLoadingPopup}>
                                            {children}
                                        </CardWrapperForModal>
                                    ) : (
                                        children
                                    )}
                                </div>

                                {(onConfirmButtonClick || isWithCancelButton) && (
                                    <div className="modal-container__button-actions">
                                        {onConfirmButtonClick && (
                                            <div className="modal-container__submit-wrapper">
                                                <Button
                                                    label={confirmButtonLabel || "SUBMIT"}
                                                    onClick={onConfirmButtonClick}
                                                    variant={confirmButtonVariant || "success"}
                                                    textSize="size-16"
                                                    className="modal-container__submit"
                                                    isProcessing={isProcessing}
                                                    disabled={isProcessing}
                                                />
                                            </div>
                                        )}
                                        {isWithCancelButton && (
                                            <div className="modal-container__cancel-wrapper">
                                                <Button
                                                    size={"sm"}
                                                    label={cancelButtonLabel || "CANCEL"}
                                                    onClick={handleCancel}
                                                    variant="link"
                                                    textSize="size-16"
                                                    className="modal-container__cancel"
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Modal.Body>
                        </div>
                    </Draggable>
                </div>
            </Modal>
        </React.Fragment>
    );
}
