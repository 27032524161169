import axios from "axios";
import { config, storageKey } from "../config/constants";
import { get } from "lodash";
import Storage from "../utils/Storage";
import store, { history } from "./../redux/store";
import { updateUserToken } from "../redux/modules/authentication";
import { getAuth0Token } from "./user";
import { setFlashNotification } from "../redux/modules/flashNotification";
import { Logger } from "src/services";

export const api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 180000, // for intake
});
let isRefreshingToken = false;
let accessToken: string = "";

api.interceptors.request.use((config) => {
    return config;
});
/** this interceptor only hanldes error case **/
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const statusCode = error?.response?.status; // this should not change from BE response
        const err =
            error?.response?.data?.error || error?.response?.data?.exception;

        const errorMessage = error?.response?.data?.message;

        // if (
        //      errorMessage && errorMessage.includes("Transaction Rejected") &&
        //     errorMessage.includes("400")
        // ) {
        //     throw error;
        // }

        if (error.response && !error.response?.config.isResilient) {
            /** when we get access token expired **/
            if (statusCode === 401 && err === "invalid_token") {
                try {
                    /** check if refresh token api is already getting called **/
                    if (isRefreshingToken === false) {
                        /** if not - call the refresh token **/
                        isRefreshingToken = true;
                        const response = await getAuth0Token({
                            grant_type: "refresh_token",
                            refresh_token: get(
                                JSON.parse(
                                    Storage.getItem(storageKey.accessToken)
                                ),
                                "refresh_token",
                                ""
                            ),
                        }); // function to refresh the token

                        if (response.access_token && response.refresh_token) {
                            store.dispatch(updateUserToken(response)); // set the token to global storage to be able to use from everywhere else
                            accessToken = response.access_token;
                            error.config.headers.Authorization = `Bearer ${response.access_token}`;
                            isRefreshingToken = false;

                            return axios(error.config); // recall the failed API with updated token
                        } else {
                            Storage.clear();
                            if (
                                !window.location.pathname.includes("login") &&
                                !window.location.pathname.includes("sign-up") &&
                                window.location.pathname !== "/"
                            )
                                window.location.reload();
                        }
                    } else {
                        /**
                         * Wait till token is refreshed
                         * and call the failed APIs again with updated token
                         *  **/
                        const checkTokenInterval = new Promise((resolve) => {
                            const tokenInterval = setInterval(() => {
                                // check if token is updated on a regular interval
                                if (isRefreshingToken === false) {
                                    clearInterval(tokenInterval);
                                    error.config.headers.Authorization = `Bearer ${accessToken}`;
                                    resolve(error.config);
                                }
                            }, 50);
                        });

                        return checkTokenInterval.then((errorConfig) =>
                            axios(errorConfig)
                        ); // call the failed api with updated token
                    }
                } catch (e) {
                    // if get error while refreshing token - logout the user
                    Storage.clear();
                    if (
                        !window.location.pathname.includes("login") &&
                        !window.location.pathname.includes("sign-up") &&
                        window.location.pathname !== "/"
                    )
                        window.location.reload();
                }
            } else if (
                errorMessage &&
                errorMessage.includes("Transaction Rejected") &&
                errorMessage.includes("400")
            ) {
                throw error;
            } else if (
                error.response.status !== 409 &&
                error.response.status !== 400 &&
                error.response.status === 500 &&
                error.response.config.url !==
                    "ehr-connect-service/ehr/upsert_user" &&
                !window.location.pathname.includes("/error")
            ) {
                /**
                 * 409 - error comes when you invite and existing user
                 * **/
                Storage.setItem(
                    "error",
                    JSON.stringify({
                        err: error.response.data,
                        loc: `${error.response.config.baseURL}/${error.response.config.url}`,
                    })
                );
                if (
                    !window.location.pathname.includes("login") &&
                    !window.location.pathname.includes("sign-up") &&
                    window.location.pathname !== "/" &&
                    !window.location.pathname.includes("change-password")
                )
                    history.push("/error");
            }

            throw error; // throw the error back to function, where is was getting called
        } else if (
            error.response &&
            error.response?.config.isResilient &&
            statusCode === 401 &&
            err === "invalid_token"
        ) {
            /** when we get access token expired **/
            if (statusCode === 401 && err === "invalid_token") {
                try {
                    /** check if refresh token api is already getting called **/
                    if (isRefreshingToken === false) {
                        /** if not - call the refresh token **/
                        isRefreshingToken = true;
                        const response = await getAuth0Token({
                            grant_type: "refresh_token",
                            refresh_token: get(
                                JSON.parse(
                                    Storage.getItem(storageKey.accessToken)
                                ),
                                "refresh_token",
                                ""
                            ),
                        }); // function to refresh the token

                        if (response.access_token && response.refresh_token) {
                            store.dispatch(updateUserToken(response)); // set the token to global storage to be able to use from everywhere else
                            accessToken = response.access_token;
                            error.config.headers.Authorization = `Bearer ${response.access_token}`;
                            isRefreshingToken = false;

                            return axios(error.config); // recall the failed API with updated token
                        } else {
                            Storage.clear();
                            if (
                                !window.location.pathname.includes("login") &&
                                !window.location.pathname.includes("sign-up") &&
                                window.location.pathname !== "/"
                            )
                                window.location.reload();
                        }
                    } else {
                        /**
                         * Wait till token is refreshed
                         * and call the failed APIs again with updated token
                         *  **/
                        const checkTokenInterval = new Promise((resolve) => {
                            const tokenInterval = setInterval(() => {
                                // check if token is updated on a regular interval
                                if (isRefreshingToken === false) {
                                    clearInterval(tokenInterval);
                                    error.config.headers.Authorization = `Bearer ${accessToken}`;
                                    resolve(error.config);
                                }
                            }, 50);
                        });

                        return checkTokenInterval.then((errorConfig) =>
                            axios(errorConfig)
                        ); // call the failed api with updated token
                    }
                } catch (e) {
                    // if get error while refreshing token - logout the user
                    Storage.clear();
                    if (
                        !window.location.pathname.includes("login") &&
                        !window.location.pathname.includes("sign-up") &&
                        window.location.pathname !== "/"
                    )
                        window.location.reload();
                }
            }
        } else if (
            !error.response &&
            window.location.origin.includes("localhost")
        ) {
            // for localhost when BE logs you out
            // clear all the storage
            localStorage.clear();
            sessionStorage.clear();
            store.dispatch(
                setFlashNotification({
                    message: "Session expired",
                })
            );
            // redirect
            window.location.pathname = "/login";
        } else {
            Logger.error(error);
            // throw error;
        }
    }
);

/**
 * Serialize javascript object for sending to api
 * @param {Object} data
 * @returns {String}
 */
export function serialize(data: Object) {
    return Object.keys(data)
        .map((keyName) => {
            return `${encodeURIComponent(keyName)}=${
                data[keyName] ? encodeURIComponent(data[keyName]) : ""
            }`;
        })
        .join("&");
}

/**
 * Method for making ajax calls to the site's api
 * @param {String} endpoint - the endpoint url
 * @param {String} method api method POST | GET | DELETE
 * @param {Object|String} [data] - key:value pairs of the data to be sent to server
 * @param {String} contentType - header contentType of request
 * @param {String} authType
 * @param {Boolean} isResilientAPI
 * @returns {Promise}
 */
export default async function makeApiRequest(
    endpoint: string,
    method?: string,
    data?: any,
    contentType?: string,
    authType?: string,
    isResilientAPI?: boolean
) {
    const token = JSON.parse(Storage.getItem(storageKey.accessToken));

    const request = {
        method,
        url: endpoint,
        data: data ? (endpoint.includes("oauth") ? serialize(data) : data) : "",
        "Access-Control-Max-Age": 600,
    };

    if (endpoint.includes("oauth")) {
        request.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${config.clientCredentialInternal}`,
        };
    } else {
        if (endpoint.includes("signup")) {
            const tenantId = sessionStorage.getItem("TENANT_ID");

            request.headers = {
                "practice-id": tenantId,
            };
        } else {
            request.headers = {
                Authorization: `Bearer ${
                    get(token, "access_token", "") ||
                    localStorage.getItem("t2fa")
                }`,
            };
        }

        if (contentType) {
            request.headers["Content-Type"] = contentType;
        } else if (endpoint.includes("convert-to-pdf")) {
            request.headers["Content-Type"] = "application/json";
            request.headers.Accept = "application/pdf";
            request.responseType = "blob";
        }
    }

    // if a authorisation header contains invalid token , delete the authorisation header
    if (request?.headers?.Authorization?.length < 15) {
        delete request.headers.Authorization;
    }

    if (authType === "basic_auth" || request.url === "token-service/token") {
        const authToken = config.clientCredentialInternal;
        // this is to pass client credential to token service apis

        request.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${authToken}`,
        };
    }

    request.isResilient = isResilientAPI || false;

    const response = await api(request);

    if (response && response.status === 200) {
        const location = response.request.responseURL; // redirection url return by previous API call.

        if (location.includes("login?code=")) {
            // if challenge code returned
            return location; // instead of redirecting we return request url handled in login.
        } else if (location.includes("login") && !location.includes("code=")) {
            // clear all the storage
            localStorage.clear();
            sessionStorage.clear();
            store.dispatch(
                setFlashNotification({
                    message: "Session expired",
                })
            );
            // redirect
            window.location.href = location; // redirect to the url
        } else {
            return response.data;
        }
    } else {
        const error = new Error(response?.statusText || "Unknow error occured");

        error.response = response;
        throw error;
    }
}

/**
 * Method for making ajax calls to the site's api
 * @param {String} apiUrl - the api url
 * @param {String} endpoint - the endpoint url
 * @param {Object|string} [data] - key:value pairs of the data to be sent to server
 * @returns {Promise}
 */
export async function makeExternalRequest(apiUrl, endpoint, data = null) {
    const url = `${apiUrl}${endpoint}${data ? `?${serialize(data)}` : ""}`;
    const response = await fetch(url);

    if (response.ok) {
        const data = await response.json();

        return data;
    } else {
        const error = new Error(response.statusText);

        error.response = response;
        throw error;
    }
}
