import "./photo.scss";
import Typography from "src/components/base/Typography/Typography";
import CustomTooltip from "src/components/base/Tooltip/Tooltip";

interface Props {
    description: string;
    userFullName: string;
}

export const DescriptionBox = ({ description, userFullName }: Props) => {
    return (
        <div className="photo-widget__info">
            <Typography color="color-2" variant="size-14" weight="regular">
                {description}
            </Typography>
            <CustomTooltip text={userFullName} placement="right" force={false}>
                <Typography
                    color="color-2"
                    variant="size-22"
                    weight="bold"
                    className="text-capitalize d-inline-block ellipsis"
                >
                    {userFullName}&nbsp;
                </Typography>
            </CustomTooltip>
        </div>
    );
};
