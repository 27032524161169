/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import makeApiRequest, { serialize } from "../api/makeApiCall";
import { config } from "../config/constants";

// new
const REST_API_V1 = `${config.userServiceRestApiV1}users`;
const TODO_API_v1 = `${config.userServiceRestApiV1}todos`;
const BOOKING_API_V1 = `${config.userServiceRestApiV1}`;
export type UserType = "client" | "therapist" | "practice-admin" | "scheduler";

// export const register = async (data:Object) => {
//     return await makeApiRequest(`${REST_API_V1}`, 'POST', data);
// };

export const verifyCrediential = async (data: Object) => {
    return await makeApiRequest(
        `${REST_API_V1}/_verify-credentials`,
        "POST",
        data
    );
};

export const getAuth0Token = async (data: Object) => {
    return await makeApiRequest(
        `${config.tokenService}token`,
        "POST",
        serialize(data),
        "application/x-www-form-urlencoded"
    );
};

// temporary for login
export const getTemporaryAuthToken = async (data: Object) => {
    return await makeApiRequest(
        `${config.tokenService}token`,
        "POST",
        serialize(data),
        "application/x-www-form-urlencoded",
        "basic_auth"
    );
};

export const getUserInfoBySignUpCode = async (data: Object) => {
    return await makeApiRequest(
        `${REST_API_V1}/${data?.verificationCode}/_verification-code`,
        "GET",
        data
    );
};

export const getUserMFAInfo = async (userId: string) => {
    return await makeApiRequest(
        `${REST_API_V1}/_mfa?user-id=${userId}`,
        "GET"
    );
};

export const changePasswordV1 = async (data: Object) => {
    return await makeApiRequest(
        `${REST_API_V1}/_change-password`,
        "POST",
        data
    );
};

export const invite = async (data: Object) => {
    return await makeApiRequest(`${REST_API_V1}/_invite`, "POST", data);
};

export const sendWelcomeEmail = async (email: String) => {
    return await makeApiRequest(`${REST_API_V1}/_send-email`, "POST", { email, "email_type": "WELCOME" });
};

export const updatePassword = async (data: Object) => {
    return await makeApiRequest(`${REST_API_V1}/_reset-password`, "POST", data);
};

export const resetPasswordSelf = async (data) => {
    return await makeApiRequest(
        `${REST_API_V1}/_forgot-password`,
        "POST",
        data
    );
};

export const onboardingClients = async (data: Object) => {
    // return await makeApiRequest(`${USER_ENDPOINT}/existing-client`, 'POST', data);
    return await makeApiRequest(`${REST_API_V1}/_invite`, "POST", data);
};

/**
 * This API we use to fetch loggedIn User Information
 * @returns
 */
export const fetchUserInfoV1 = async (section: String) => {
    let params = "";

    if (section === "PROFILE_DISPLAY") {
        params = "?is_profile_required=true";
    } else {
        params = "?is_profile_required=false";
    }

    return await makeApiRequest(`${REST_API_V1}${params}`, "GET");
};

/**
 * This API we use to signup the user
 * @param {*} userCredentials
 * @returns
 */
export const onUserSignUp = async (userCredentials: Object) => {
    return await makeApiRequest(`${REST_API_V1}`, "POST", userCredentials);
};

/**
 * This API we use to fetch user details using userId
 * @param {*} userId
 * @returns
 */
export const fetchUserInformationByIdV1 = async (userId: string, section: String) => {

    let params = "";

    if (section === "PROFILE_DISPLAY") {
        params = "?is_profile_required=true";
    } else {
        params = "?is_profile_required=false";
    }

    return await makeApiRequest(`${REST_API_V1}/${userId}/${params}`, "GET");
};

/**
 * This API we use to fetch user address using userId
 * @param {*} userId
 * @returns
 */
export const fetchUserAddressByIdV1 = async (userId: string) => {
    return await makeApiRequest(`${REST_API_V1}/${userId}/address`, "GET");
};

/**
 * This API we are using to update a specific data of a user
 * @param {*} userId
 * @param {*} userDetails
 * @returns
 */
export const updateUserInfoV1 = async (userId: any, userDetails: Object) => {
    return await makeApiRequest(
        `${REST_API_V1}/${userId}`,
        "PATCH",
        userDetails
    );
};

/**
 * This API we are using to fetch clients/therapist list
 * @param {*} params
 * @returns
 */
export const getPracticeAdminUsersV1 = async (params: object, section?: String) => {
    // const obj = params;

    // TODO: when multiselect is used
    // Object.keys(obj).forEach(key => {
    //     if (obj[key] === null) {
    //         delete obj[key];
    //     } else if(key === "user_assigned_to_ids"){
    //         obj[key] = obj[key].join(",");
    //     }
    // });
    let parameter = "";

    if (section === "PROFILE_DISPLAY") {
        parameter = "is_profile_required=true";
    } else {
        parameter = "is_profile_required=false";
    }

    // return await makeApiRequest(`${REST_API_V1}/_search?${new URLSearchParams(obj).toString().replace("%",",")}`, 'GET');
    const searchTerm = params?.keyword?.length > 0 ? encodeURIComponent(params.keyword) : "";

    return await makeApiRequest(
        `${REST_API_V1}/_search?${(params.user_assigned_to_ids &&
            "user_assigned_to_ids=" + params.user_assigned_to_ids + "&") ||
        ""
        }${(searchTerm && "keyword=" + searchTerm + "&") || ""}${(params.limit && "limit=" + params.limit + "&") || ""
        }${(params.offset && "offset=" + params.offset + "&") || ""}${(params.user_ids && "user_ids=" + params.user_ids + "&") || ""
        }${(params.user_name && "user_name=" + params.user_name + "&") || ""}${(params.user_type && "user_type=" + params.user_type + "&") || ""
        }${(params.user_status && "user_status=" + params.user_status + "&") ||
        ""
        }${(params.sort_by && "sort_by=" + params.sort_by + "&") || ""}${(params.user_assigned_to &&
            "user_assigned_to=" + params.user_assigned_to + "&") || ""
        }${parameter}`,
        "GET"
    );
};

/**
 * This API we are using to update user roles through Practice Admin
 * @param {*} userId
 * @param {*} userRoles
 * @returns
 */
export const updateUserRolesV1 = async (userId: string, userRoles: Object) => {
    return await makeApiRequest(
        `${REST_API_V1}/${userId}/_roles `,
        "PUT",
        userRoles
    );
};

/**
 * This API we are using to Log out user from App
 * @param {*} userId
 * @param {*} logOutType
 * @returns
 */
export const userLogOutV1 = async (userId: string, logOutType: String) => {
    return await makeApiRequest(`${REST_API_V1}/${userId}/_logout `, "POST", {
        action_type: logOutType,
    });
};

/**
 * This API we are using to get To Dos at scheduler dashboard
 * @param {*} data
 * @returns
 */
export const getToDosV1 = async (data) => {
    return await makeApiRequest(
        `${TODO_API_v1}?limit=${data.limit ? data.limit : 8}&offset=${data.offset ? data.offset : 0
        }${data.active && data.active === "false"
            ? "&active=" + data.active
            : ""
        }${data.fetch_all && data.fetch_all === true
            ? "&fetch_all=" + data.fetch_all
            : ""
        }${data.assigned_to ? "&assigned_to=" + data.assigned_to : ""}${data.sort_by ? "&sort_by=" + data.sort_by : ""
        }${data.search_by_user ? "&search_by_user=" + data.search_by_user : ""
        }&todo_type=${data.type}${data.client_user_id ? "&client_user_id=" + data.client_user_id : ""
        } `,
        "GET"
    );
};

/**
 * This API we are using to Retrieve the user's assigned scheduler
 * @param {*} clientId
 * @returns
 */
export const getUserAssignedScheduler = async (clientId) => {
    return await makeApiRequest(
        `${REST_API_V1}/${clientId}/_assign-scheduler `,
        "GET"
    );
};

/**
 * This API we are using to Retrive the user todos
 * @param {*} data
 * @returns
 */
export const getUserToDosV1 = async (data) => {
    return await makeApiRequest(
        `${TODO_API_v1}?limit=${data.limit ? data.limit : 8}&offset=${data.offset ? data.offset : 0
        }${data.client_user_id ? "&client_user_id=" + data.client_user_id : ""
        }&completed=${data.isCompleted} `,
        "GET"
    );
};

/**
 * This API we use to remember self view (patch request)
 * @param {*} metadata
 * @returns
 */
export const videoCallState = async (userId: string, metadata: Object) => {
    return await makeApiRequest(`${REST_API_V1}/${userId}`, "PATCH", metadata);
};

/**
 * PATCH User API
 * @param {*} metadata
 * @returns response
 */

export const patchUser = async (userId: string, metadata: Object) => {
    return await makeApiRequest(`${REST_API_V1}/${userId}`, "PATCH", metadata);
};


/**
 * This API we are using to Retrive the user todos
 * @param {*} data
 * @returns
 */
export const getUserSpecificToDosV1 = async (data) => {
    return await makeApiRequest(
        `${TODO_API_v1}/user/${data.client_user_id}?limit=${data.limit ? data.limit : 8}&offset=${data.offset ? data.offset : 0
        }&completed=${data.isCompleted} `,
        "GET"
    );
};


/**
 * This API we are using to Retrive the user todos
 * @param {*} data
 * @returns
 */
export const changeTodoStatusV1 = async (todoId, isActive, note) => {
    return await makeApiRequest(`${TODO_API_v1}/${todoId}/_change-state`, "PUT", { "active": isActive, note }
    );
};

// API to handle self-onboarding
export const selfOnboardingApi = async (data: Object) => {

    return await makeApiRequest(`${REST_API_V1}/_signup`, "POST", data);
};

// This API used to send Recovery code for 2FA
export const sendRecoveryCode = async (recoveryCode: Number, userName: String) => {
    return await makeApiRequest(`${REST_API_V1}/_reset-2fa`, "PUT", { 'recovery_code': recoveryCode, 'user_name': userName });
};


// This API used to GET onboarding summary
export const getOnboardingSummary = async (userType: String) => {
    return await makeApiRequest(`${REST_API_V1}/_onboarding_summary?user_type=${userType}`, 'GET');
};

// This API is used to Get the booking status
export const bookingStatus = async () => {
    return await makeApiRequest(`${BOOKING_API_V1}meeting-information`, 'GET');
};

// This API is used to update meeting information for the user
export const updateUserMeetingInformation = async () => {
    return await makeApiRequest(`${BOOKING_API_V1}meeting-information`, 'PUT', { meeting_status: 'CREATE_APPOINTMENT_SLOT' });
};

// This API is used to check if user have appointment or not

export const verifyAppointment = async (userId: String) => {
    return await makeApiRequest(
        `${REST_API_V1}/${userId}/_verify-appointment`,
        "POST"
    );
};

// This API is used to cancle onboarding

export const cancelOnboarding = async (userId: String,note?: String) => {
    const data ={};

    data.activity = note;

    return await makeApiRequest(
        `${REST_API_V1}/${userId}/_cancel-onboarding `,
        "POST",data
    );
};
