import { Modal } from "antd";
import Spinner from "react-bootstrap/Spinner";

interface Props {
    modalStatus?: any;
}

export const Loader = ({ modalStatus }: Props) => {
    function borderSpinner() {
        return <Spinner animation="border" />;
    }

    return (
        <>
            <Modal
                centered={true}
                open={modalStatus}
                closable={false}
                footer={null}
                zIndex={9999}
                width={100}
            >
                {borderSpinner()}
            </Modal>
        </>
    );
};
