// env config

const origin = window.location.origin;
let baseUrl = "";

if (origin.includes("localhost")) {
    baseUrl = process.env.REACT_APP_API_HOST;
} else {
    baseUrl = origin;
}

export const config = {
    apiUrl: baseUrl,
    // oAuthService: 'oauth2-service/',
    clientCredentialInternal: btoa(
        process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL
    ),
    oAuthService: "oauth2-service/",
    userService: "users-service/",
    userServiceRestApiV1: `users-service/v1/`,
    therapySessionService: "therapy-sessions-service/",
    questionnaireService: "questionnaires-service/",
    matchService: "matches-service/",
    loggingService: "logging-service/",
    ticketService: "ticket-service/",
    therapysessionservieces: "therapy-sessions-service",
    questionnaireResponses: "question-responses",
    therapySessions: "therapy-sessions/",
    tokenService: "token-service/",
    ehrConnectService: "ehr-connect-service/",
    calenderService: "calendar-service/",
    configurationService: "configuration-service/"
};

// keys for localstorage
export const storageKey = {
    accessToken: "i2i_accessToken",
    accessTokenClient: "i2i_accessToken_client",
    shouldAlwaysAsk: "shouldAlwaysAsk",
    teletherapyCurrentRoute: "teletherapyCurrentRoute",
};

// user roles
export const userRoles = {
    therapist: "therapist",
    client: "client",
    scheduler: "scheduler",
};

export const teletherapyStates = {
    waitingForTherapist: "WAITING_FOR_THERAPIST_INVITE",
    invitedByTherapist: "INVITED_BY_THERAPIST",
    inTherapySession: "IN_THERAPY_SESSION",
    sessionEnded: "SESSION_ENDED",
    rejoinedWaitingRoom: "REJOINED_WAITING_ROOM",
};

export const grantType = {
    CLIENT_CREDENTIALS: process.env.REACT_APP_CLIENT_CREDENTIALS,
};

export const practice = {
    id: process.env.REACT_APP_PRACTICE_ID,
};

export const userServiceSection = {
      profile_display : "PROFILE_DISPLAY",
};