// @flow
import {
    findChoiceAndUpdateMetadata,
    findQuestionAndUpdateValue,
    setQuestionnaireData,
    stringToObject,
    updateQuestionnaireConfiguration,
} from "../../utils/helpers";
import {
    FETCH_QUESTIONNAIRE_MEATDATA,
    CLEAR_QUESTIONNAIRE_METADATA,
    HIDE_HEADER,
    SET_PAGE_QUESTIONS,
    SET_QUESTIONNAIRE_METADATA,
    START_QUESTIONNAIRE_LOADING,
    STOP_QUESTIONNAIRE_LOADING,
    UPDATE_CONTINUE_BUTTON,
    UPDATE_QUESTIONNAIRE_CONFIG,
    UPDATE_QUESTION_VALUE,
    SET_IS_COMPLETED,
    SET_USERPROFILE_METADATA,
    CLEAR_USERPROFILE_METADATA,
    FROM_SETTINGS_PAGE,
    SET_CLIENTPROFILE_METADATA_FOR_HEADER,
    SET_CLIENT_USER_PROFILE,
    UPDATED_PROFILE_PIC,
    SET_QUESTIONNAIRE_DATA,
    CLEAR_THERAPIST_CLIENT_METADATA,
    UPDATE_SIGN_PAD_STATUS,
    UPDATE_CHOICE_METADATA,
    SET_SELF_USER_DOB,
    UPDATE_MEETING_INFO_ZOOM,
    UPDATE_COMBINED_FILTERS,
} from "../actions/questionnaireActions";

/** todo **/
const initialState = {
    fetchedQuestionnaireMetadata: false,
    initialMetadata: {}, // initialMetadata
    currentConfig: {
        // dynamic configuration
        loading: false,
        direction: "forward",
        isContinueButtonDisabled: true, // continue button - default disabled
        isCompleted: false, // update to true, if session completes
        isSignpadVisible: false,
        progress: {
            progress: 0,
            totalPages: 0,
            currentPageNumber: 0,
        }, // 0%
        questionnaireSessionId: "",
        header: {
            visible: true,
            content: [], // { id,name,isSelected }
        },
        currentCategory: {},
        currentPage: {},
        currentQuestions: [],
        combinedFilters: {}
    },
    currentUserProfileMetaData: {},
    clientMetaData: {}, // set client profile metadata
    clientProfileMetadataForHeader: {},
    questionnairePageData: {},
};

export default function questionnaireStore(
    state: Object = initialState,
    action: Object
): any {
    switch (action.type) {
        case FETCH_QUESTIONNAIRE_MEATDATA: {
            // TODO: Test this properly
            return { ...initialState};
        }

        case SET_QUESTIONNAIRE_METADATA: {
            const currentData = { initialMetadata: action.questionnaireData };

            return { ...state, ...currentData, fetchedQuestionnaireMetadata: true };
        }

        case SET_QUESTIONNAIRE_DATA: {
            const updatedState = setQuestionnaireData(action?.profileDetails);

            return {
                ...state,
                ...updatedState,
            };
        }

        case CLEAR_QUESTIONNAIRE_METADATA: {
            return {
                ...state,
                ...{ currentConfig: initialState.currentConfig },
            };
        }

        case UPDATE_QUESTIONNAIRE_CONFIG: {
            const _initialMetadata=  action.initialMetadata || state.initialMetadata;
            const _currentConfig = action?.resetCurrentPage ? Object.assign(state.currentConfig, {
                currentCategory: {},
                currentPage: {},
                currentQuestions: [],
            }) : state.currentConfig;
            const _state = Object.assign(state, {initialMetadata: _initialMetadata, currentConfig: _currentConfig});

            return {
                ...state,
                ...updateQuestionnaireConfiguration(
                    _state,
                    action.categoryId,
                    action.direction,
                    action.pageUniqueKey
                ),
            };
        }

        case SET_PAGE_QUESTIONS: {
            const currentPage = state.currentConfig.currentPage;
            const pageMetadata =
                currentPage?.metadata && stringToObject(currentPage?.metadata);
            const isPageOptional = pageMetadata?.isOptional === "true" || false;

            return {
                ...state,
                ...{
                    currentConfig: {
                        ...state.currentConfig,
                        currentQuestions: action.questions.map((q) => ({
                            ...q,
                            required: isPageOptional ? false : q.required,
                        })),
                    },
                },
            };
        }

        case START_QUESTIONNAIRE_LOADING: {
            return {
                ...state,
                ...{ currentConfig: { ...state.currentConfig, loading: true } },
            };
        }

        case STOP_QUESTIONNAIRE_LOADING: {
            return {
                ...state,
                ...{
                    currentConfig: { ...state.currentConfig, loading: false },
                },
            };
        }

        case UPDATE_QUESTION_VALUE: {
            const { questionId, choiceId, value, isValid } = action;

            return {
                ...state,
                ...{
                    currentConfig: {
                        ...state.currentConfig,
                        currentQuestions: findQuestionAndUpdateValue(
                            state.currentConfig.currentQuestions,
                            questionId,
                            choiceId,
                            value,
                            isValid
                        ),
                    },
                },
            };
        }

        case UPDATE_CONTINUE_BUTTON: {
            return {
                ...state,
                currentConfig: {
                    ...state.currentConfig,
                    isContinueButtonDisabled: action.buttonStatus !== "enabled",
                },
            };
        }

        case HIDE_HEADER: {
            return {
                ...state,
                ...{
                    currentConfig: {
                        ...state.currentConfig,
                        header: {
                            ...state.currentConfig.header,
                            visible: action.shouldHide,
                        },
                    },
                },
            };
        }

        case SET_IS_COMPLETED: {
            return {
                ...state,
                ...{
                    currentConfig: {
                        ...state.currentConfig,
                        isCompleted: action.isCompleted,
                    },
                },
            };
        }

        case SET_USERPROFILE_METADATA: {
            const currentUserProfileMetaData =
                action?.currentUserProfileMetaData;
            const questionnairePageData = action?.initialMetadata;

            return {
                ...state,
                currentUserProfileMetaData,
                questionnairePageData,
                ...{ currentConfig: { header: { visible: true } } },
            };
        }

        case SET_CLIENT_USER_PROFILE: {
            const clientMetaData = action?.clientMetaData;

            return {
                ...state,
                clientMetaData,
                currentConfig: { ...state.currentConfig, loading: false }, // to make sure questionnaire stops loading
            };
        }

        case CLEAR_USERPROFILE_METADATA: {
            return initialState;
        }

        case FROM_SETTINGS_PAGE: {
            return {
                ...state,
                ...{
                    questionnaireData: { ...state.questionnaireData },
                    fromSettingsPage: action.isFromSttingsPage,
                },
            };
        }

        case SET_CLIENTPROFILE_METADATA_FOR_HEADER: {
            return {
                ...state,
                clientProfileMetadataForHeader: action.data,
            };
        }

        case UPDATED_PROFILE_PIC: {
            return { ...state, updatedProfilePic: action.changedSrc };
        }

        case CLEAR_THERAPIST_CLIENT_METADATA: {
            return {
                ...state,
                currentUserProfileMetaData: {},
                clientMetaData: {},
            };
        }

        case UPDATE_SIGN_PAD_STATUS: {
            const status = action?.status;

            return {
                ...state,
                ...{
                    currentConfig: {
                        ...state.currentConfig,
                        isSignpadVisible: status || false,
                    },
                },
            };
        }

        case UPDATE_CHOICE_METADATA: {
            const { questionId, choiceId, data } = action;

            return {
                ...state,
                ...{
                    currentConfig: {
                        ...state.currentConfig,
                        currentQuestions: findChoiceAndUpdateMetadata(
                            state.currentConfig.currentQuestions,
                            questionId,
                            choiceId,
                            data
                        ),
                    },
                },
            };
        }

        case UPDATE_COMBINED_FILTERS: {
            const { combinedFilters } = action;

            return {
                ...state,
                ...{
                    currentConfig: { ...state.currentConfig, combinedFilters },
                },
            };
        }

        case SET_SELF_USER_DOB: {
            const dob = action?.dob;

            return {
                ...state,
                ...{
                    initialMetadata: {
                        ...state.initialMetadata,
                        user_dob: dob,
                    },
                },
            };
        }

        case UPDATE_MEETING_INFO_ZOOM: {
            const meetingInfo = action?.info;

            return {
                ...state,
                meetingInfo,
            };
        }

        default:
            return state;
    }
}
