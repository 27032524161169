// @flow
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Card from '../Card/Card';

type Props = {
    className?: string,
    children: any,
    isLoading?: boolean
}

export default function CardWrapperForModal({ className = '', children, isLoading }: Props): React$Element<any> {
    return (
        <Card className={`for-modal${isLoading ? ' with-loading' : ''} ${className}`} >
            {children}

            {isLoading && (
                <div className='for-modal__loading'>
                    <Spinner
                        animation='border'
                        role='status'
                        variant='dark'/>
                </div>
            )}
        </Card>
    );
}
