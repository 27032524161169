/* eslint-disable camelcase */

// CONSTANTS
export const SET_USER_TO_BE_ONBOARDED = "SET_USER_TO_BE_ONBOARDED";
export const UPDATE_USER_TO_BE_ONBOARDED = "UPDATE_USER_TO_BE_ONBOARDED";
export const RESET_ONBOARDING_STATE = "RESET_ONBOARDING_STATE";

// this is to set user details of the user whose onboarding in progress
export function setUserToBeOnboarded(userDetails: any): any {
    return { type: SET_USER_TO_BE_ONBOARDED, userDetails };
}

export function updateUserToBeOnboarded(profilePic: string): { data: Object } {
    return {
        type: UPDATE_USER_TO_BE_ONBOARDED,
        profilePic,
    };
}

export function resetOnboardingState(): any {
    return { type: RESET_ONBOARDING_STATE };
}
