import React, { useState } from "react";
import Button from "../../../../../components/base/Button/Button";
import { addNote } from "../../../../../redux/services/loggingServices";
import { useDispatch } from "react-redux";
import { clearModalContent } from "../../../../../redux/modules/modalEvent";
import { Logger } from "src/services";

type Props = {
    selectedUserId: string;
    noteUpdatedByUser: Function;
};

export function AddNoteModal({ selectedUserId, noteUpdatedByUser }: Props) {
    const dispatch = useDispatch();
    const [note, setnote] = useState<string>("");
    const [apiLoading, setapiLoading] = useState(false);

    const noteUpdated = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value !== " " || e.target.value !== undefined) {
            setnote(e.target.value);
        }
    };

    const addNoteToAuditLog = async () => {
        try {
            setapiLoading(true);
            note !== "" && (await addNote(selectedUserId, note));
            setapiLoading(false);
            setnote("");
            await noteUpdatedByUser();
            dispatch(clearModalContent());
        } catch (e) {
            Logger.error(e);
        }
    };

    return (
        <div className="add-note-text-area add-note-modal-text d-flex flex-column justify-content-center">
            <textarea
                rows={4}
                maxLength={500}
                placeholder="Add Note (500 characters max)"
                value={note}
                onChange={noteUpdated}
            />
            <Button
                className="m-5 confirmation-modal__no-button modal-btn"
                disabled={note.trim().length === 0}
                label="Save Changes"
                variant="primary"
                textWeight="bold"
                textSize="size-14"
                isProcessing={apiLoading}
                onClick={addNoteToAuditLog}
            />
        </div>
    );
}
