// @flow
import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import Typography from '../Typography/Typography';
import './card.scss';

type Props = {
    className?: string,
    children: any,
    withAvatar?: boolean,
    photoUrl?: string,
    percentageMatched?: number,
    initials?:string,
    linear?:string,
    onClick?:any
}

export default function Card({ className = '', children, withAvatar = false, photoUrl = '', percentageMatched, initials = '', linear = '',onClick }: Props): React$Element<any> {
    const shouldShowAvatar = (withAvatar && photoUrl) || initials;

    return (
        <div onClick={onClick} className={`card-wrapper${shouldShowAvatar ? ' with-avatar' : ''} ${className}`} >
            <div className='card-wrapper__overlap'>
                {shouldShowAvatar && (
                    // TODO check if clickable to change image
                    <div className='card-wrapper__overlap-avatar d-flex justify-content-center align-items-center' style={{ backgroundImage: photoUrl ? `url(${photoUrl})` : linear }}>
                        {photoUrl.length === 0 && <Typography variant='size-40' className='onboarding-list__table-value text-uppercase'
                            weight='bold'>
                            {initials}
                        </Typography>}
                    </div>
                )}
                {percentageMatched !== undefined && percentageMatched !== null && (
                    <div className='card-wrapper__overlap-percentage'>
                        <CircularProgressbarWithChildren
                            value={percentageMatched}
                            strokeWidth={9}
                            styles={buildStyles({
                                strokeLinecap: 'butt',
                                pathColor: `rgba(65, 201, 159, ${percentageMatched / 100})`,
                                trailColor: '#CEF7EA'
                            })}>
                            <Typography variant='size-40' weight='extra-bold' className='card-wrapper__overlap-percentage__figure'>
                                {`${percentageMatched}%`}
                            </Typography>
                            <Typography variant='size-12' weight='black' color='color-4'
                                className='card-wrapper__overlap-percentage__label'>
                                MATCH
                            </Typography>
                        </CircularProgressbarWithChildren>
                    </div>
                )}
            </div>

            {children}
        </div>
    );
}
