// @flow
import React from 'react';
import Typography from '../../base/Typography/Typography';
import './info.scss';

type Props = {
    className?: string,
    description: any,
    onHover: Function,
    onLeave: Function,
    isShow: boolean
}

export default function Info({ className = '', description, onHover, onLeave, isShow }: Props): React$Element<any> {
    return (
        <div className={`info ${className} d-flex`} >
            <div className='info__icon cursor-pointer' onClick={onHover} onMouseEnter={onHover}
                onMouseLeave={onLeave}/>
            <div className={`info__desc ${isShow ? 'info-show' : ''}`} onMouseEnter={onHover} onMouseLeave={onLeave}>
                <Typography variant='size-12' color='color-2' weight='regular'>{description}</Typography>
            </div>
        </div>
    );
}
