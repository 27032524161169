import { useEffect, useState, useRef } from "react";
import Typography from "src/components/base/Typography/Typography";
import "./photo.scss";
import { useSelector, useDispatch } from "react-redux";
import {
    getPreSignedUrl,
    patchUserProfilePic,
} from "src/redux/services/commonServices";
import { setModalContent } from "src/redux/modules/modalEvent";
import ConfirmationModalContainer from "src/containers/ConfirmationModal/ConfirmationModalContainer";
import { updatedProfilePic } from "src/redux/actions/questionnaireActions";
import { setFlashNotification } from "src/redux/modules/flashNotification";
import { updateLoggedInUser } from "src/redux/modules/authentication";
import { isMobileDevice } from "src/utils/helpers";

import { DescriptionBox } from "./DescriptionBox";
import { OptionDropdown } from "./OptionDropdown";
import { allowedFileTypes, invalidFileExtensions } from "./constant";
import { UploadErrorText } from "./UploadErrorText";
import { UploadImageContainer } from "./UploadImageContainer";
import { updateUserToBeOnboarded } from "src/redux/actions/onboardingActions";

type Props = {
    questionData?: any;
    onChange?: Function;
    setUploading?: Function;
    userDetails: any;
    isIntakeQuestionnaire?: boolean;
};

export const Photo = ({
    questionData,
    onChange = () => {},
    setUploading,
    userDetails,
    isIntakeQuestionnaire,
}: Props) => {
    const dispatch = useDispatch();
    const choice = questionData?.choices[0];
    const description = choice?.description;
    const [uploadError, setUploadError] = useState<string | null>(null);
    const { loggedInUser } = useSelector(
        ({ authentication }: any) => authentication
    );
    const [user, setUser] = useState<any>({});
    const [decodedImageSrc, setDecodedImageSrc] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [userFullName, setUserFullName] = useState<string>("");
    const profilePic: any = useRef(null);

    const [isLoggedinUserProfile, setIsLoggedinUserProfile] =
        useState<boolean>(false);
    const userToBeOnboarded = useSelector(
        (state: any) => state?.onboarding?.userToBeOnboarded
    );

    useEffect(() => {
        if (userDetails) {
            setUser(userDetails);
            _initData(userDetails);
            setUserFullName(userDetails?.full_name);
            setIsLoggedinUserProfile(userDetails?.id === loggedInUser?.id);
        }
    }, [userDetails]);

    const onProfileChangeClick = () => {
        // `current` points to the mounted file input element
        if (profilePic?.current) {
            profilePic.current.click();
        }
    };

    const toggleUploadingStatus = (status: boolean) => {
        setIsUploading(status);

        if (setUploading) {
            setUploading(status);
        }
    };

    const _initData = (currentUser: any = null) => {
        if (currentUser?.profile_pic) {
            (async () => {
                const srcPic = await decodeURIComponent(
                    currentUser.profile_pic
                );

                if (srcPic === "" || srcPic === null || srcPic === undefined) {
                    toggleUploadingStatus(false);
                    setDecodedImageSrc(null);
                } else {
                    toggleUploadingStatus(false);
                    setDecodedImageSrc(srcPic);

                    if (isIntakeQuestionnaire) {
                        onChange(srcPic, choice?.id);
                    }
                }
            })();
        }
    };

    const onSetValue = async (e: any) => {
        setUploadError(null);

        if (e.target.files[0] === undefined || !user?.id) {
            return;
        }

        if (e && e.target && e.target.files[0]) {
            const file = e.target.files[0];
            const { size, type } = file;
            const fileExtension = file?.name?.split(".").pop().toLowerCase();

            if (
                !allowedFileTypes.includes(type) ||
                invalidFileExtensions.includes(fileExtension)
            ) {
                setUploadError("Uploaded file is not a valid image file.");

                return;
            }

            if (size > 1e6 && !isMobileDevice()) {
                setUploadError("File size exceeds maximum limit (1MB).");

                return;
            }

            toggleUploadingStatus(true);

            // NEW DOCUMENT SERVICE API CALLS
            // ======================================================
            const preSignedUrl = await getPreSignedUrl(
                "PROFILE_PIC",
                e.target.files[0],
                user?.id,
                user?.id,
                !isIntakeQuestionnaire
            );

            // =====================================================
            if (preSignedUrl === "") {
                dispatch(
                    setFlashNotification({
                        message:
                            "There was an error uploading Profile picture please try again",
                        isError: true,
                        duration: 3000,
                    })
                );
            }

            toggleUploadingStatus(false);
            const srcPic = await decodeURIComponent(preSignedUrl);

            setDecodedImageSrc(srcPic);

            isLoggedinUserProfile &&
                dispatch(
                    updateLoggedInUser({
                        ...user,
                        profile_pic: preSignedUrl || "",
                    })
                );

            if (!window.location.pathname.includes("settings")) {
                onChange(preSignedUrl, choice?.id);
            }

            if (userToBeOnboarded?.id === userDetails?.id) {
                dispatch(updateUserToBeOnboarded(preSignedUrl || ""));
            }
        } else if (e) {
            setDecodedImageSrc("");
        }

        e.target.value = "";
    };

    const uploadEmptyPic = async () => {
        if (user?.id) {
            toggleUploadingStatus(true);
            dispatch(setModalContent({ modalContent: null }));
            await patchUserProfilePic("null", user?.id, user?.id);
            toggleUploadingStatus(false);
            setDecodedImageSrc("");
            dispatch(updatedProfilePic("removed"));

            if (userToBeOnboarded?.id === userDetails?.id) {
                dispatch(updateUserToBeOnboarded(""));
            }

            isLoggedinUserProfile &&
                dispatch(updateLoggedInUser({ ...user, profile_pic: "" }));
            dispatch(updatedProfilePic(Math.random().toString()));
            setUploadError(null);
        }
    };

    const removeImage = () => {
        if (window.location.pathname.includes("settings")) {
            dispatch(
                setModalContent({
                    modalContent: (
                        <div>
                            <ConfirmationModalContainer
                                clickedYes={uploadEmptyPic}
                                clickedOnNo={() =>
                                    dispatch(
                                        setModalContent({
                                            title: null,
                                            modalContent: null,
                                        })
                                    )
                                }
                                dynamicText={
                                    "Are you sure you want to remove your profile picture ?"
                                }
                            />
                        </div>
                    ),
                    size: "lg",
                    isWithCancelButton: false,
                    isWithCloseButton: true,
                    isGeneralPopup: true,
                    title: "Delete your photo?",
                    className: "confirmation-modal",
                })
            );
        } else {
            onChange("", choice?.id);
            uploadEmptyPic();
        }
    };

    return (
        <div className="photo-widget">
            {uploadError && <UploadErrorText errorMessage={uploadError} />}
            {decodedImageSrc && (
                <OptionDropdown
                    onProfileChangeClick={onProfileChangeClick}
                    removeImage={removeImage}
                />
            )}
            <label className={`photo-widget__label ${isUploading && "border"}`}>
                <input
                    onChange={onSetValue}
                    type="file"
                    accept="image/png, image/jpeg"
                    className="d-none"
                    id="profile-pic-file"
                    ref={profilePic}
                />
                {decodedImageSrc ? (
                    <img
                        id="img"
                        alt="User"
                        className="profile-img mx-auto d-block"
                        src={decodedImageSrc}
                    />
                ) : (
                    <div>
                        {!isUploading && <UploadImageContainer />}
                        <Typography
                            color="color-28"
                            variant="size-16"
                            weight="semi-bold"
                        >
                            {isUploading && "Uploading ..."}
                        </Typography>
                    </div>
                )}
            </label>
            {isIntakeQuestionnaire && (
                <DescriptionBox
                    description={description}
                    userFullName={userFullName}
                />
            )}
        </div>
    );
};
