export const publicRoutes =
    /\/change-password|\/sign-up|\/providers|\/initquestions|\/forgot-password|\/reset-password|\/support|\/redirect|\/verify-onboarding|\/error|\/termsofservice|\/register|\/login|\/privacypolicy|/.test(
        window.location.pathname
    );

export const shouldLoggoutRoutes =
    /\/login|\/sign-up|\/forgot-password|\/reset-password|\/verify-onboarding|\/privacypolicy|\/support|\/redirect|\/support|\/error/.test(
        window.location.pathname
    ); // Add other unauthenticated routes here

export const privateRoutes =
    /\/therapy|\/error|\/scheduler|\/settings|\/profile|\/intake-report|\/session-ended|\request-rematch|\/activity-log|\/meeting-info|\/register|\/verify-onboarding|\/termsofservice|\/privacypolicy|/.test(
        window.location.pathname
    );

export const shouldLoggoutAndRedirectRoutes = /\/providers|\/sign-up/.test(
    window.location.pathname
);
