import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getPracticeAdminUsersV1 } from "../../../../../api/user";
import AppButton from "../../../../../components/base/Button/Button";
import { assignScheduler } from "../../../../../redux/services/loggingServices";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModalContainer from "../../../../ConfirmationModal/ConfirmationModalContainer";
import { clearModalContent } from "../../../../../redux/modules/modalEvent";
import { Logger } from "src/services";

type Props = {
    selectedUserId: string;
    reassigned: Function;
};

export function ReassignModal({ selectedUserId, reassigned }: Props) {
    const [schedulaers, setSchedulers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedScheduler, setSelectedScheduler] = useState<any>();
    const { loggedInUser } = useSelector((state: any) => state?.authentication);
    const dispatch = useDispatch();

    const reassignScheduler = async () => {
        setLoading(true);

        try {
            const resp = await assignScheduler(
                selectedUserId,
                selectedScheduler?.id
            );

            reassigned(resp);

            dispatch(clearModalContent());
        } catch (e) {
            Logger.log("error assigning", e);
        }

        setLoading(false);
    };

    useEffect(() => {
        loggedInUser?.user_type?.includes("PRACTICE_ADMIN") &&
            fetchSchedulers();
    }, []);

    const onSelectedScheduler = (value: any) => {
        setSelectedScheduler(value);
    };

    const fetchSchedulers = async (value?: string) => {
        const requestPayload = {
            limit: 10,
            offset: 0,
            user_type: "SCHEDULER",
            keyword: value || null,
        };

        setLoading(true);
        const { users } = await getPracticeAdminUsersV1(requestPayload);

        setSchedulers(users || []);
        setLoading(false);
    };

    const assignToMySelf = async () => {
        setLoading(true);

        try {
            const resp = await assignScheduler(
                selectedUserId,
                loggedInUser?.id
            );

            reassigned(resp);
            dispatch(clearModalContent());
        } catch (e) {
            Logger.log("error assigning", e);
        }

        setLoading(false);
    };

    return (
        <div className="add-note-text-area add-note-modal-text d-flex flex-column justify-content-center reassign-modal-container">
            {loggedInUser?.user_type?.includes("PRACTICE_ADMIN") ? (
                <div>
                    <div className="reassign-title">
                        Reassigning this client will transfer all current and
                        future TO-DOs to a new scheduler.
                    </div>
                    <Autocomplete
                        disablePortal={true}
                        options={schedulaers}
                        loading={loading}
                        onChange={(e, value) => onSelectedScheduler(value)}
                        onInputChange={(event, newInputValue) =>
                            fetchSchedulers(newInputValue)
                        }
                        getOptionLabel={(option: any) =>
                            option?.full_name || option?.email
                        }
                        renderOption={(props, option: any) => {
                            return (
                                <li {...props}>
                                    <Grid
                                        container={true}
                                        justifyContent={"flex-start"}
                                    >
                                        <Box component="span">
                                            {option.full_name || option.email}
                                        </Box>
                                    </Grid>
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                className="select-scheduler-input"
                                placeholder="Select scheduler"
                            />
                        )}
                    />
                    <AppButton
                        className="m-5 modal-btn"
                        disabled={selectedScheduler === null}
                        label="Confirm"
                        variant="primary"
                        textWeight="bold"
                        textSize="size-14"
                        isProcessing={loading}
                        onClick={() => {
                            reassignScheduler();
                        }}
                    />
                </div>
            ) : (
                <ConfirmationModalContainer
                    clickedYes={assignToMySelf}
                    clickedOnNo={() => dispatch(clearModalContent())}
                    dynamicText={
                        "Reassigning this client to yourself will transfer all current and future TO-DOs to you."
                    }
                    variant={"size-16"}
                    className="p-5"
                />
            )}
        </div>
    );
}
