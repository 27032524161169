/* eslint-disable camelcase */

// CONSTANTS
export const TOGGLE_POPUP_LOADER = "TOGGLE_POPUP_LOADER";
export const TOGGLE_APP_CONFIG_LOADER = "TOGGLE_APP_CONFIG_LOADER";
export const SET_APP_CONFIG = "SET_APP_CONFIG";
export const SET_SUPPORT_CONFIG = "SET_SUPPORT_CONFIG";

export function togglePopupLoader(status: boolean) {
    return {
        type: TOGGLE_POPUP_LOADER,
        status,
    };
}

export function toggleAppConfigLoader(status: boolean) {
    return {
        type: TOGGLE_APP_CONFIG_LOADER,
        status,
    };
}

export function setAppConfig(appConfig: any) {
    return {
        type: SET_APP_CONFIG,
        appConfig,
    };
}

export function setSupportConfig(config: {
    practiceSupport: any;
    technicalSupport: any;
    questionnaireService: any;
}) {
    return {
        type: SET_SUPPORT_CONFIG,
        config,
    };
}
