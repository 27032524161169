
import Storage from '../../utils/Storage';

const ADD_CLIENT_RESPONSE = 'ADD_CLIENT_RESPONSE';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const UPDATE_RESPONSE = 'UPDATE_RESPONSE';

export function addClientResponse(data) {
    return (dispatch) => {
        let currentResponses = Storage.getItem('clientsResponses') || [];

        const index = currentResponses.findIndex(item => item.clientId === data.clientId);

        if (index > -1) {
            currentResponses.splice(index, 1);
        }

        currentResponses = [...currentResponses, data];
        dispatch({
            type: ADD_CLIENT_RESPONSE,
            clientsResponses: currentResponses
        });
    };
}

export function clearClientResponse(): any {
    return {
        type: CLEAR_RESPONSE
    };
}

export function updateClientResponse(data) {
    return {
        type: UPDATE_RESPONSE,
        clientsResponses: data
    };
}

const initialState = {
    clientsResponses: Storage.getItem('clientsResponses') || []
};

export default function questionnaireResponse(state: Object = initialState, action: Object): any {
    switch (action.type) {
        case ADD_CLIENT_RESPONSE:
            Storage.setItem('clientsResponses', action.clientsResponses || []);

            return {
                ...state,
                clientsResponses: [...action.clientsResponses]
            };
        case CLEAR_RESPONSE:
            return {
                ...state,
                clientsResponses: [...(Storage.getItem('clientsResponses') || [])]
            };
        case UPDATE_RESPONSE:
            Storage.setItem('clientsResponses', action.clientsResponses || []);

            return {
                ...state,
                clientsResponses: [...action.clientsResponses]
            };
        default:
            return state;
    }
}
