// @flow
import React, { useRef, useState,useEffect} from 'react';
import { getFullNameInitials, randomLinear } from '../../../utils/helpers';
import Storage from '../../../utils/Storage';
import Typography from '../Typography/Typography';
import './avatar.scss';

// const API_URL = `${config.apiUrl}`;

type Props = {
    className?: string,
    size?: string,
    photoUrl?: string,
    fullName?: string,
    self?:boolean,
    linear?:string,
    userId?:string,
    hideImage?:boolean,
    initialsColor?:string,
    imageSize?: string,
    timeStamp?:string
}


// Need to Hide initalals when there is bg
export default function Avatar({ userId = '', className = '', self = false, size = '36px', fullName = '', hideImage = false, initialsColor = '#354354', imageSize = 'S', photoUrl,  timeStamp}: Props): React$Element<any> {
    const [defaultBackground,setDefaultBackground] = useState({ backgroundImage:  Storage.getItem('bgLinear') && self ? Storage.getItem('bgLinear') : randomLinear(255,fullName) });
    const initials = getFullNameInitials(fullName);
    const [imageMode,setImageMode] = useState(false);
    const image = useRef();    // const trigger = profilePicture;
    const [src, setsrc] = useState('');
    const noUserBackground = {background:"#E7E9EF"};

    useEffect(() => {
        if (photoUrl) {
            setsrc(decodeURIComponent(photoUrl));
            setImageMode(true);
        } else if (photoUrl === null || !photoUrl) {
            setDefaultBackground({ backgroundImage: Storage.getItem('bgLinear') && self ? Storage.getItem('bgLinear') : randomLinear(255, fullName) });
            setImageMode(false);
        }
    }, [photoUrl]);


    const onError = () => {
        ///        image.current.style.display = 'none';
        setDefaultBackground({backgroundImage:Storage.getItem('bgLinear') && self ? Storage.getItem('bgLinear') : randomLinear(255,fullName)});

        setImageMode(false);
    };


    return (
        <div
            className={`avatar ${className} d-flex justify-content-center align-items-center`}
            style={{ width: `${size}`, height: `${size}`, ...(fullName === 'Match In Progress...' ? noUserBackground : defaultBackground) }} >
            <Typography style={{
                fontSize:parseInt(size.replace('px'))/3+'px',color:initialsColor
            }} className='onboarding-list__table-value text-uppercase'
            weight='bold'>
                {!imageMode && initials}
            </Typography>
            {imageMode && photoUrl && <img ref={image} style={{
                height:fullName === 'Match In Progress...' ?'74px': size,
                width:fullName === 'Match In Progress...' ?'60px':size,
                display: src!==null?'block':'none'
            }} className='image'
            onError={onError}
            // onLoad={onLoad}
            src={src}
            alt=''/>}
        </div>
    );
}
